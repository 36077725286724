import { useFetchUserData } from '@hooks';

type WithInitialDataProps = {
  children: JSX.Element;
};

const WithInitialData = ({ children }: WithInitialDataProps): JSX.Element => {
  useFetchUserData();

  return children;
};

export default WithInitialData;
