import React from 'react';
import { Select, Button, Checkbox } from 'antd';

import { getOptions, filterOption, getKZPSortedRows } from '@utils';

const KZPPhase2SectionRowColumnNames = [
  'Kontrolovaná vlastnost',
  'Předpis',
  'Jednotky',
  'Požadovaná četnost',
];

const cellStyles = {
  borderBottom: '1px solid #ddd',
  borderLeft: '1px solid #ddd',
  lineHeight: '135%',
};

const headerCellStyles = {
  ...cellStyles,
  backgroundColor: '#eee',
  borderTop: '1px solid #ddd',
  color: '#222',
  fontWeight: '400',
  padding: 8,
  textAlign: 'left',
};

const rowCellStyles = {
  ...cellStyles,
  borderRight: '1px solid #ddd',
  padding: 8,
};

// type ComponentProps = {|
//   id: number,
//   selectedSections: Array<number>,
//   series: Array<Object>,
//   sections: Array<Object>,
//   constructionTests: Array<Object>,
//   getOrCreateSectionRow: Function,
//   onSubmit: Function,
//   onGoBack: Function,
//   readOnly: boolean,
// |};

// type ComponentState = {|
//   selectedSeries: number,
//   selectedSections: Array<number>,
// |};

class KZPPhase2Form extends React.Component {
  constructor(props) {
    super(props);

    let selectedSeries = null;
    let selectedSections = [];
    const unselectedRows = [];

    const selectedRows = this.props.kzp.section_row_data
      .map((sectionRowData) => {
        return sectionRowData.section_row.id;
      });


    const constructionUnitSeries = props?.constructionUnit.name.slice(1,4)
    if (constructionUnitSeries.length === 3 && !isNaN(constructionUnitSeries)) {
      const targetSeries = this.props.series.filter((series) => {
        return series.number.toString() === constructionUnitSeries;
      })[0];
      if (targetSeries) {
        selectedSeries = targetSeries.id;
      }
    }

    this.props.selectedSections.forEach((id, index) => {
      const section = this.getSection(id)
      if (index === 0) selectedSeries = section.series;
      selectedSections = this.addSectionTo(selectedSections, id);
      section.rows.forEach(({ id: rowId }) => {
        if (selectedRows.indexOf(rowId) === -1) {
          unselectedRows.push(rowId);
        }
      });
    });

    this.state = {
      selectedSeries,
      selectedSections,
      unselectedRows,
      extraSectionRows: [],
    };
  }

  isSelected = (sectionId) => {
    return (
      this.state.selectedSections.filter((_) => _.toString() === sectionId.toString()).length > 0
    );
  };

  isSelectedRow = (rowId) => {
    return (
      this.state.unselectedRows.filter((_) => _.toString() === rowId.toString()).length === 0
    );
  };

  getSection = (id) => {
    const section = this.props.sections.filter((_) => _.id === id)[0];
    if (section) {
      return section;
    } else {
      return null;
    }
  };

  getConstructionTest = (id) => {
    const constructionTest = this.props.constructionTests.filter((_) => _.id === id)[0];
    if (constructionTest) {
      return constructionTest;
    } else {
      return null;
    }
  };

  getAllSectionRows = (section) => {
    // const sectionRowIds = section.rows.map(_ => _.id);

    const extraUnsavedSectionRows = this.state.extraSectionRows
      .filter((extraSectionRow) => {
        return extraSectionRow.section.id === section.id;
      });
      // .filter((extraSectionRow) => {
      //   return sectionRowIds.indexOf(extraSectionRow.id) === -1;
      // });

    const extraSavedSectionRows = this.props.kzp.section_row_data
      .filter(({ section_row }) => {
        return section_row.extra && section_row.section.id === section.id;
      })
      // .filter(({ section_row }) => {
      //   return sectionRowIds.indexOf(section_row.id) === -1;
      // })
      .map(({ section_row }) => section_row);

    return [
      ...section.rows,
      ...extraUnsavedSectionRows,
      ...extraSavedSectionRows,
    ];
  }

  filterSectionBasedOnConstructionUnit = (section) => {
    return section.construction_units.indexOf(this.props.constructionUnit.id) > -1;
  };

  // function to add section and it's parent (if it isn't already added)
  addSectionTo = (selectedSections, id) => {
    const section = this.getSection(id);
    const parent = section.parent_section;

    selectedSections.push(id);
    if (parent) {
      this.addSectionTo(selectedSections, parent);
    }

    return selectedSections;
  };

  // function to unselect section and possible nested sections
  removeSectionFrom = (selectedSections, id) => {
    if (!this.isSelected(id)) {
      return selectedSections;
    }

    const section = this.getSection(id);
    const nestedSections = section.sections;

    selectedSections.splice(selectedSections.indexOf(id), 1);
    if (nestedSections.length > 0) {
      nestedSections.forEach((nestedSectionId) =>
        this.removeSectionFrom(selectedSections, nestedSectionId)
      );
    }

    return selectedSections;
  };

  onSelectSection = ({ target: { checked } }, id) => {
    // making use of event just for "safety"
    const { selectedSections } = this.state;

    if (checked && !this.isSelected(id)) {
      selectedSections.push(id);
      this.setState({ selectedSections });
    } else if (!checked && this.isSelected(id)) {
      const newSelectedSections = this.removeSectionFrom(selectedSections, id);
      this.setState({ selectedSections: newSelectedSections });
    }
  };

  onSelectRow = ({ target: { checked } }, id) => {
    const { unselectedRows } = this.state;

    if (checked && !this.isSelectedRow(id)) {
      this.setState({ unselectedRows: [...unselectedRows].filter(_ => _ !== id) });
    } else if (!checked && this.isSelectedRow(id)) {
      this.setState({ unselectedRows: [...unselectedRows, id] });
    }
  };

  addExtraRow = (section, row) => {
    this.props.getOrCreateSectionRow(section, row).then((sectionRow) => {
      this.setState({
        extraSectionRows: [
          ...this.state.extraSectionRows,
          sectionRow,
        ],
      });
    });
  }

  onSubmit = () => {
    const sectionRowData = [];

    // only sections "at the very end" count (non-parent sections)
    // parent sections don't have any section rows assigned to them
    this.state.selectedSections.forEach((sectionId) => {
      const section = this.getSection(sectionId);
      const { rows, sections } = section;
      const allRows = this.getAllSectionRows(section);

      if (sections.length === 0 && rows.length > 0) {
        allRows
          .filter(({ id: sectionRowId }) => this.isSelectedRow(sectionRowId))
          .forEach(({ id: sectionRowId }) => {
            sectionRowData.push({
              kzp: this.props.id,
              section_row: sectionRowId,
            });
          });
      }
    });

    this.props.onSubmit(sectionRowData);
  };

  renderSection = (section) => {
    if (!section) {
      return null;
    }

    const { name, id, sections = [] } = section;
    const isSelected = this.isSelected(id);

    const allRows = this.getAllSectionRows(section);
    const sectionTests = allRows.map((row) => row.test.id);

    return (
      <div key={id}>
        <Checkbox
          disabled={this.props.readOnly}
          checked={isSelected}
          onChange={(event) => this.onSelectSection(event, id)}
          color="primary"
          style={{ margin: '6px 0' }}
        >
          {name}
        </Checkbox>
        {isSelected && sections.length > 0 && (
          <div style={{ marginLeft: 10 }}>
            {
              sections
                .map((id) => {
                  const section = this.getSection(id);
                  if (this.filterSectionBasedOnConstructionUnit(section)) {
                    return this.renderSection(section);
                  } else {
                    return null;
                  }
                })
            }
          </div>
        )}
        {isSelected &&
          sections.length === 0 &&
          (allRows.length === 0 ? (
            <div style={{ marginLeft: 30 }}>
              <p>K dané oblasti nejsou přiřazeny žádné zkoušky</p>
            </div>
          ) : (
            <div style={{ marginLeft: 25 }}>
              <table>
                <tbody>
                  <tr>
                    <th style={headerCellStyles}>{''}</th>
                    <th style={headerCellStyles}>{''}</th>
                    {KZPPhase2SectionRowColumnNames.map((name, index) => {
                      const isLast = index + 1 === KZPPhase2SectionRowColumnNames.length;
                      return (
                        <th
                          key={name}
                          style={{
                            ...headerCellStyles,
                            borderRight: isLast && '1px solid #ddd',
                          }}
                        >
                          {name}
                        </th>
                      );
                    })}
                  </tr>
                  {getKZPSortedRows(allRows).map(
                    (
                      {
                        id: rowId,
                        test: { id, name, description, test_type, test_unit_specifications },
                        extra,
                      },
                      index
                    ) => (
                      <tr key={rowId}>
                        <td style={{ ...rowCellStyles, minWidth: '20px' }}>
                          <Checkbox
                            disabled={this.props.readOnly}
                            checked={this.isSelectedRow(rowId)}
                            onChange={(event) => this.onSelectRow(event, rowId)}
                            color="primary"
                          />
                        </td>
                        <td style={{ ...rowCellStyles, minWidth: '20px' }}>{index + 1}.{extra ? '*' : ''}</td>
                        <td style={{ ...rowCellStyles, minWidth: '150px' }}>{name}</td>
                        <td style={{ ...rowCellStyles, minWidth: '100px' }}>{description}</td>
                        <td style={{ ...rowCellStyles, minWidth: '100px' }}>
                          {test_unit_specifications.map((_) => _.unit.name).join(', ')}
                        </td>
                        <td style={{ ...rowCellStyles, minWidth: '120px' }}>
                          {test_unit_specifications.map((_) => _.amount_per_test).join(', ')}
                        </td>
                      </tr>
                    )
                  )}
                </tbody>
              </table>
              <div style={{ alignItems: 'center', display: 'flex', marginTop: 12 }}>
                Přidat další zkoušku:
                  <Select
                    key={allRows.length}
                    placeholder="Typ zkoušky"
                    options={getOptions(this.props.constructionTests.filter(
                      (constructionTest) => sectionTests.indexOf(constructionTest.id) === -1
                    ))}
                    value={[]}
                    onChange={(value) => this.addExtraRow(section.id, value)}
                    showSearch
                    filterOption={filterOption}
                    style={{ width: 500, marginLeft: 10 }}
                  />
              </div>
            </div>
          ))}
      </div>
    );
  };

  renderSectionSelect() {
    const { series, sections } = this.props;
    const { selectedSeries } = this.state;
    const majorSections = sections.filter((_) => !_.parent_section);

    return (
      <div>
        <Select
          placeholder="Vyberte řadu"
          value={selectedSeries}
          options={getOptions(series, 'number').map((option) => ({
            ...option,
            label: 'Řada ' + option.label,
          }))}
          disabled
        />
        <div style={{ marginTop: 10 }}>
          {selectedSeries &&
            majorSections
              .filter((_) => _.series === selectedSeries)
              .filter(this.filterSectionBasedOnConstructionUnit)
              .map((_) => this.renderSection(_))}
        </div>
      </div>
    );
  }

  render() {
    return (
      <div style={{ width: "100%", padding: "0 0 1rem 0" }}>
        {this.renderSectionSelect()}
        <div style={{ display: 'flex', justifyContent: 'space-between', margin: '1.5rem 0 0 0' }}>
          <Button
            type="primary"
            shape="round"
            onClick={this.props.onGoBack}
          >
            Zpět
          </Button>
          <div style={{ margin: '0 7px' }} />
          <Button
            className="green-button"
            type="primary"
            shape="round"
            onClick={this.onSubmit}
          >
            {!this.props.readOnly ? 'Uložit a pokračovat' : 'Pokračovat'}
          </Button>
        </div>
      </div>
    );
  }
}

export default KZPPhase2Form;
