import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { ConstructionSelect, MailRecordsTable } from '@components';

import { useParamIdOrRedirect, useUser, useConstructions } from '@hooks';

import { readCookie, setCookie } from '@utils';

const MailRecordsPage = () => {
  const id = useParamIdOrRedirect();
  const navigate = useNavigate();
  const user = useUser();

  const { isSuccess, constructions } = useConstructions();

  useEffect(() => {
    if (!id) {
      const initialId = (() => {
        const lastConstruction = readCookie('construction');
        if (lastConstruction) {
          return lastConstruction;
        }

        if (isSuccess) {
          if (constructions[0]) {
            return constructions[0].id;
          }
        }
      })();

      if (initialId) {
        navigate(`/view/mailrecords/${initialId}`);
      }
    }
  });

  const onConstructionChange = (id) => {
    navigate(`/view/mailrecords/${id}`);
    setCookie('construction', id);
  }

  return (
    <div>
      {
        isSuccess && (
          constructions.length > 0 ? (
            <ConstructionSelect
              value={id}
              options={constructions}
              onChange={onConstructionChange}
            />
          ) : (
            <div style={{
              alignItems: 'center',
              display: 'flex',
              height: '100%',
              justifyContent: 'center',
              marginTop: 75,
              width: '100%',
            }}>
              Nemáte přidělené žádné stavby.
            </div>
          )
        )
      }
      {id && (
        <MailRecordsTable
          constructionId={id}
          isAdmin={user.is_superuser}
        />
      )}
    </div>
  );
};

export default MailRecordsPage;
