import IMAGE_EXTENSIONS from 'image-extensions';
import {
  FileOutlined,
  FileExcelOutlined,
  FileGifOutlined,
  FileMarkdownOutlined,
  FilePdfOutlined,
  FilePptOutlined,
  FileTextOutlined,
  FileWordOutlined,
  FileZipOutlined,
  PlayCircleOutlined,
} from '@ant-design/icons';

// contains
const c = (f: string, ext: string) => f.toLowerCase().indexOf(ext) > -1;

export const isImage = (filename: string) => {
  let result = false;

  for (let i = 0; i < IMAGE_EXTENSIONS.length; i++) {
    if (result) break;
    result = c(filename, `.${IMAGE_EXTENSIONS[i]}`);
  }

  return result;
};

export const isPDF = (filename: string) => {
  return c(filename, '.pdf');
};

export const getFileIcon = (f: string) => {
  if (
    c(f, '.xhtml') ||
    c(f, '.xlsx') ||
    c(f, '.xlsm') ||
    c(f, '.xlsb') ||
    c(f, '.xltx') ||
    c(f, '.xltm') ||
    c(f, '.xls') ||
    c(f, '.xlt') ||
    c(f, '.xml')
  ) {
    return FileExcelOutlined;
  }

  if (c(f, '.mp4') || c(f, '.ogg') || c(f, '.ogm') || c(f, '.webm')) {
    return PlayCircleOutlined;
  }

  if (c(f, '.gif')) {
    return FileGifOutlined;
  }

  if (c(f, '.md') || c(f, '.markdown')) {
    return FileMarkdownOutlined;
  }

  if (c(f, '.pdf') || c(f, '.ps') || c(f, '.eps')) {
    return FilePdfOutlined;
  }

  if (
    c(f, '.key') ||
    c(f, '.pptx') ||
    c(f, '.pptm') ||
    c(f, '.ppt') ||
    c(f, '.pps') ||
    c(f, '.odp')
  ) {
    return FilePptOutlined;
  }

  if (c(f, '.txt') || c(f, '.tex') || c(f, '.rtf')) {
    return FileTextOutlined;
  }

  if (
    c(f, '.doc') ||
    c(f, '.docx') ||
    c(f, '.odt') ||
    c(f, '.wpd')
  ) {
    return FileWordOutlined;
  }

  if (
    c(f, '.tar') ||
    c(f, '.dat') ||
    c(f, '.rar') ||
    c(f, '.pkg') ||
    c(f, '.zip')
  ) {
    return FileZipOutlined;
  }

  return FileOutlined;
};
