export const reverseString = (string: string) => {
  const characterArray = string.split('');
  const reversedCharacterArray = characterArray.reverse();
  const reversedString = reversedCharacterArray.join('');

  return reversedString;
};

export const truncateIfNeeded = (string: string, length: number = 80) => {
  if (string && string.length > length) {
    return string.substring(0, length) + "...";
  } else {
    return string;
  }
};
