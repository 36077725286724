import React, { Component } from 'react';
import { Select, Button } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';

import { CloseButton } from '@components';

import { getOptions, filterOption } from '@utils';

class CreateTestsFromGroup extends Component {
  constructor(props) {
    super(props);

    const { activeTests } = props;
    let rows = [];
    activeTests.forEach(({ id, test_type, test }) => {
      rows.push({
        test_type: test_type,
        test: id || test,
      });
    });

    this.state = {
      error: null,
      rows,
    };
  }

  addNewRow = () => {
    const { rows } = this.state;
    rows.push({ test_type: null, test: null });
    this.setState({ rows });
  };

  onSubmit = () => {
    const { rows } = this.state;
    const validRows = rows.filter(({ test_type, test }) => test);

    if (validRows.length === 0) {
      this.setState({ error: 'Vyplňte alespoň jeden řádek' });
    } else if (validRows.length === rows.length) {
      const { onSubmit } = this.props;
      onSubmit(validRows);
    } else {
      this.setState({ error: 'Zkontrolujte, zda jsou všechny řádky vyplněny správně' });
    }
  };

  renderRows() {
    const { test_types, construction_tests } = this.props;
    const { rows } = this.state;

    return rows.map(({ test_type, test }, index) => (
      <div key={index} style={{
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        marginBottom: 10,
      }}>
        <Select
          style={{ width: '20%' }}
          placeholder="Druh zkoušky"
          value={test_type}
          options={getOptions(test_types)}
          onChange={(test_type) => {
            const { rows } = this.state;
            rows[index] = { test_type, test: null };
            this.setState({ rows });
          }}
          showSearch
          filterOption={filterOption}
        />
        <div style={{ width: '80%', paddingLeft: 10 }}>
          <Select
            style={{ width: '90%', marginRight: 10 }}
            placeholder="Typ zkoušky"
            value={test}

            options={getOptions(!test_type ? construction_tests : (
              construction_tests.filter((_) => _.test_type === test_type)
            ))}
            onChange={(test) => {
              const { rows } = this.state;
              rows[index].test = test;
              this.setState({ rows });
            }}
            showSearch
            filterOption={filterOption}
          />
          <CloseButton
            onClick={() => {
              const { rows } = this.state;
              rows.splice(index, 1);
              this.setState({ rows });
            }}
          />
        </div>
      </div>
    ));
  }

  renderAddRowButton() {
    return (
      <Button
        type="text"
        shape="round"
        onClick={this.addNewRow}
        icon={<PlusCircleOutlined style={{ fontSize: 16 }} />}
      >
        Přidat zkoušku
      </Button>
    );
  }

  renderSubmitButton() {
    const { error } = this.state;
    return (
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        width: '100%',
        alignItems: 'center',
      }}>
        {error && (
          <p
            style={{
              backgroundColor: 'rgba(244, 67, 54, .15)',
              color: 'rgb(244, 67, 54)',
              padding: '8px',
              textAlign: 'center',
              width: '100%',
            }}
          >
            {error}
          </p>
        )}
        <Button
          type="primary"
          shape="round"
          onClick={this.onSubmit}
          style={{ marginTop: 10 }}
        >
          Potvrdit
        </Button>
      </div>
    );
  }

  render() {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-around',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            maxHeight: 500,
            overflowY: 'auto',
            width: '100%',
          }}
        >
          {this.renderRows()}
        </div>
        {this.renderAddRowButton()}
        {this.renderSubmitButton()}
      </div>
    );
  }
}

export default CreateTestsFromGroup;
