import { useState } from 'react';
import moment from 'moment';
import { message, Form, Button } from 'antd';

import { FormField, RoadTestsFileList } from '@components';

import { getOptions, requiredRule } from '@utils';

const AddMailRecordForm = ({
  lastMailrecordFields,
  onFormSubmit,
  tableOptions,
}: any) => {
  const [form] = Form.useForm();
  const [attachments, setAttachments] = useState([]);

  const onFinish = async () => {
    try {
      await form.validateFields();

      if (attachments.length === 0) {
        return message.error('Je nutné nahrát alespoň jednu přílohu');
      }

      const fieldValues = form.getFieldsValue();

      // Merge deadline date and time values
      const deadline_date = moment(fieldValues.deadline_date);
      const deadline = moment(fieldValues.deadline);

      deadline_date.set({
        hour: deadline.hour(),
        minute: deadline.minute(),
        second: deadline.second(),
      });

      if (deadline_date.isBefore(moment())) {
        return message.error('Termín nesmí být v minulosti');
      }

      const payload = {
        ...fieldValues,
        deadline_date: deadline_date,
        deadline: deadline_date,
        add_attachment_ids: attachments.map((file: RoadTestsFile) => file.id),
      };

      onFormSubmit(payload);
    } catch (error) {
      console.error(error);
      message.error('Opravte prosím chyby ve formuláři');
    }
  }

  const onLoadLastValues = () => {
    form.setFieldsValue(lastMailrecordFields);
  }

  return (
    <div style={{ width: 600 }}>
      <Form
        form={form}
        autoComplete="off"
        // onValuesChange={(changedFields) => {}}
        onFinish={() => onFinish()}
      >
        <Form.Item
          className="form-item"
          name="subject"
          rules={[requiredRule]}
        >
          <FormField
            type="text"
            placeholder="Předmět"
          />
        </Form.Item>
        <Form.Item
          className="form-item"
          name="recipients"
          rules={[requiredRule]}
        >
          <FormField
            type="select"
            mode="multiple"
            placeholder="Příjemci"
            options={getOptions(tableOptions.recipients)}
          />
        </Form.Item>
        <Form.Item
          className="form-item"
          name="content"
          rules={[requiredRule]}
        >
          <FormField
            type="textarea"
            placeholder="Obsah"
            minRows={6}
            maxRows={6}
          />
        </Form.Item>
        <div className="row2">
          <Form.Item
            className="form-item"
            name="deadline_date"
            rules={[requiredRule]}
          >
            <FormField
              type="date"
              placeholder="Datum termínu"
            />
          </Form.Item>
          <Form.Item
            className="form-item"
            name="deadline"
            rules={[requiredRule]}
          >
            <FormField
              type="time"
              placeholder="Čas termínu"
            />
          </Form.Item>
        </div>
        <RoadTestsFileList
          label="Přílohy"
          files={attachments}
          onChange={(files: RoadTestsFile[]) => setAttachments(files)}
        />
        <div className="form-buttons">
          <Button
            shape="round"
            disabled={!lastMailrecordFields}
            onClick={() => onLoadLastValues()}
          >
            Poslední
          </Button>
          <Button
            type="primary"
            shape="round"
            onClick={() => onFinish()}
          >
            Vytvořit
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default AddMailRecordForm;
