import React from 'react';
import idx from 'idx';
import memoize from 'memoize-one';
import { Modal } from 'antd';

import { RoadTestsTable } from '@components';

import { columnsConfigMap, columnsDefaultOrder } from './tableconfig';
import { saveTableConfig, fetchInitialData } from './requests';

import ConfigureTableView from './ConfigureTableView';

const idxDefault = (val, fn, defaultVal) => {
  const idxRes = idx(val, fn);
  if (idxRes === null || idxRes === undefined) {
    return defaultVal;
  }
  return idxRes;
};

const columnsConfig = columnsDefaultOrder.map(
  (accessor) => columnsConfigMap[accessor]
);

function coerceColumn(colItem) {
  // $FlowFixMe
  return {
    ...colItem,
    isVisible: typeof colItem.isVisible === 'boolean' ? colItem.isVisible : true,
  };
}

class RoadTestsTableContainer extends React.Component {
  constructor() {
    super();

    const columnsInitial = columnsDefaultOrder
      .map((accessor) => columnsConfigMap[accessor])
      .map(coerceColumn);

    this.state = {
      columns: columnsInitial,
      isConfiguring: false,
      tableConfChanged: false,
      closeConfMode: 'inactive',
      saveConfigState: 'idle',
      fetchTableConf: 'idle',
    };
  }

  componentDidMount() {
    this.fetchColumnConfig();
  }

  fetchColumnConfig() {
    fetchInitialData().then(({ columns_order, columns_config }) => {
      const { columns } = this.state;

      const columnsMap = columns.reduce((result, col) => {
        return { ...result, [col.accessor]: col };
      }, {});

      for (const key of Object.keys(columns_config)) {
        const val = columns_config[key];
        const isVisible = val.is_visible;
        columnsMap[key] = { ...columnsMap[key], isVisible };
      }

      const newColumns = columns_order.map((colName) => columnsMap[colName]);
      this.setState({ columns: newColumns });
    });
  }

  _changeColumnOrder = (column: string, dropIdx: number) => {
    const { columns } = this.state;
    const columnsOrder = columns.map((_) => _.accessor);
    const curIdx = columnsOrder.indexOf(column);

    if (curIdx === -1) {
      return;
    }

    this.setState({
      columns: (() => {
        const actualDropIdx = (() => {
          const sortableColumns = this.getSortableColumns();
          const startIdx = columns.length - sortableColumns.length;
          return startIdx + dropIdx;
        })();

        const newColumns = [...columns];
        const [item] = newColumns.splice(curIdx, 1);
        newColumns.splice(actualDropIdx, 0, item);
        return newColumns;
      })(),
      tableConfChanged: true,
      saveConfigState: 'idle',
    });
  };

  _toggleColumnVisibility = (columnName: string) => {
    const { columns } = this.state;
    const itemIdx = columns.findIndex((_) => _.accessor === columnName);
    if (itemIdx === -1) {
      return;
    }

    const item = columns[itemIdx];
    item.isVisible = !item.isVisible;

    this.setState({
      columns: [...columns.slice(0, itemIdx), item, ...columns.slice(itemIdx + 1)],
      tableConfChanged: true,
      saveConfigState: 'idle',
    });
  };

  onConfigModalClose = () => {
    if (this.state.tableConfChanged) {
      this.setState({ closeConfMode: 'asking' });
    } else {
      this.closeConfigModal();
    }
  };

  onSaveConfig = () => {
    const { columns } = this.state;

    const columns_config = columns.reduce(
      (result, val) => ({
        ...result,
        [val.accessor]: { is_visible: idxDefault(val, (_) => _.isVisible, true) },
      }),
      {}
    );

    const data = {
      columns_order: columns.map((c) => c.accessor),
      columns_config,
    };

    this.setState({ saveConfigState: 'loading' });
    saveTableConfig(data)
      .then(() => {
        this.setState({ saveConfigState: 'loaded', tableConfChanged: false });
      })
      .catch((error) => {
        console.error(error);
        this.setState({ saveConfigState: 'failed' });
      });
  };

  closeConfigModal() {
    this.setState({
      isConfiguring: false,
      tableConfChanged: false,
      closeConfMode: 'inactive',
    });
  }

  filterSortableColumns = memoize((columns) => {
    const canBeSorted = (c) => c.show_fixed !== true;
    const sortableColumns = columns.filter(canBeSorted);
    return sortableColumns;
  });

  getSortableColumns = () => {
    return this.filterSortableColumns(this.state.columns);
  };

  render() {
    const { id, freeMode, allowProtocolCirculation } = this.props;

    const sortableColumns = this.getSortableColumns();
    const activeColumns = this.state.columns.filter((c) => c.isVisible).map((c) => c.accessor);

    return (
      <div>
        <Modal
          title="Změny neuloženy"
          open={this.state.closeConfMode === 'asking'}
          cancelText="Zrušit změny"
          okText="Ano, vrátit se"
          onCancel={() => this.closeConfigModal()}
          onOk={() => this.setState({ closeConfMode: 'inactive' })}
          zIndex={1500}
          destroyOnClose
        >
          Chcete se vrátit a uložit změny?
        </Modal>

        <Modal
          open={this.state.isConfiguring}
          title={null}
          footer={null}
          onCancel={() => this.onConfigModalClose()}
          destroyOnClose
          width={750}
        >
          <ConfigureTableView
            columns={sortableColumns}
            changeColumnOrder={this._changeColumnOrder}
            toggleColumnVisibility={this._toggleColumnVisibility}
            hasChanged={this.state.tableConfChanged}
            onSaveConfig={this.onSaveConfig}
            didSaveOk={this.state.saveConfigState === 'loaded'}
          />
        </Modal>
        {!this.state.isConfiguring && (
          <RoadTestsTable
            ID={id}
            freeMode={freeMode}
            allowProtocolCirculation={allowProtocolCirculation}
            isAdmin={this.props.isAdmin}
            activeColumns={activeColumns}
            onActivateConfigMode={() => this.setState({ isConfiguring: true })}
          />
        )}
      </div>
    );
  }
}

export default RoadTestsTableContainer;
