import { ConfigProvider } from 'antd';
import locale from 'antd/lib/locale/cs_CZ';
import 'moment/locale/cs';

type WithLocaleProps = {
  children: JSX.Element;
};

const WithLocale = ({ children }: WithLocaleProps): JSX.Element => {
  return (
    <ConfigProvider locale={locale}>
      {children}
    </ConfigProvider>
  );
};

export default WithLocale;
