import Waypoint from 'react-waypoint';
import moment from 'moment';
import { Button } from 'antd';
import {
  EditFilled,
  CalendarOutlined,
} from '@ant-design/icons';

import { SearchFilter, DateRangeFilter } from '@components';

import { dayMonthYearHourMinute, getFilterOptions } from '@utils';

const getTableColumns = ({
  isFiltering,
  moreRowsUrl,
  moreFilterRowsUrl,
  tableOptions,
  permittedUserActions,
  // isAdmin,
  tableData,
  filteredData,
  loadMoreData,
  setEditingMailRecord,
  openAttachmentModal,
  openFinalDocumentModal,
}: any) => {
  return [
    {
      title: '',
      width: 115,
      fixed: 'left',
      render: (mailrecord: any) => {
        const canEditMailRecord = !mailrecord.closed && permittedUserActions.can_edit_mailrecord;
        const canReadHistory = permittedUserActions.can_read_history;

        return (
          <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
            {canEditMailRecord && (
              <Button
                type="text"
                shape="circle"
                size="small"
                icon={<EditFilled style={{ fontSize: 18 }} className="theme-color" />}
                onClick={() => setEditingMailRecord(mailrecord)}
                title="Editovat"
              />
            )}
            {!canEditMailRecord && canReadHistory && (
              <Button
                type="text"
                shape="circle"
                size="small"
                icon={<CalendarOutlined style={{ fontSize: 18 }} className="theme-color" />}
                onClick={() => setEditingMailRecord(mailrecord)}
                title="Historie záznamu"
              />
            )}
          </div>
        );
      },
    },
    {
      fixed: 'left',
      width: 0,
      render: (_: any, __: any, index: number) => {
        if (
          !isFiltering &&
          moreRowsUrl && (
            (index + 40) === tableData.length ||
            (index + 1) === tableData.length
          )
        ) {
          return <Waypoint onEnter={() => loadMoreData()} />;
        }

        if (
          isFiltering &&
          moreFilterRowsUrl && (
            (index + 40) === filteredData.length ||
            (index + 1) === filteredData.length
          )
        ) {
          return <Waypoint onEnter={() => loadMoreData(true)} />;
        }
      },
    },
    {
      title: '#',
      dataIndex: 'test_number',
      key: 'test_number',
      width: 50,
      fixed: 'left',
    },
    {
      title: 'Datum vytvoření',
      dataIndex: 'created_at',
      key: 'created_at',
      width: 165,
      fixed: 'left',
      render: (created_at: string) => dayMonthYearHourMinute(created_at),
      filterDropdown: (props: any) => <DateRangeFilter {...props} />,
    },
    {
      title: 'Autor',
      dataIndex: 'created_by',
      key: 'created_by',
      width: 150,
      fixed: 'left',
    },
    {
      title: 'Předmět',
      dataIndex: 'subject',
      key: 'subject',
      width: 300,
      render: (subject: string) => (
        <div className="ellipsis" title={subject}>
          {subject || '-'}
        </div>
      ),
      filterDropdown: (props: any) => <SearchFilter {...props} />,
    },
    {
      title: 'Příjemci',
      dataIndex: 'recipients',
      key: 'recipients',
      width: 300,
      render: (recipients: any) => {
        const value = recipients.map(
          (recipient: any) => recipient.name.trim()
        ).join(', ');
        return (
          <div className="ellipsis" title={value}>
            {value || '-'}
          </div>
        );
      },
      filters: getFilterOptions(tableOptions.recipients),
      filterSearch: true,
    },
    {
      title: 'Obsah',
      dataIndex: 'content',
      key: 'content',
      width: 200,
      render: (content: string) => (
        <div className="ellipsis" title={content}>
          {content || '-'}
        </div>
      ),
      filterDropdown: (props: any) => <SearchFilter {...props} />,
    },
    {
      title: 'Termín',
      dataIndex: 'deadline',
      key: 'deadline',
      width: 180,
      render: (deadline: string) => dayMonthYearHourMinute(deadline),
      filterDropdown: (props: any) => <DateRangeFilter {...props} />,
    },
    {
      title: 'Přílohy',
      dataIndex: 'attachments',
      key: 'attachments',
      width: 120,
      render: (attachments: any, mailrecord: any) => (
        <Button
          className="no-highlight"
          type="text"
          shape="round"
          size="small"
          disabled={attachments.length === 0}
          onClick={() => openAttachmentModal(mailrecord)}
          style={{ fontWeight: 600 }}
        >
          <span className="no-highlight">
            {attachments.length + 'x Příloha'}
          </span>
        </Button>
      ),
    },
    {
      title: 'Čas uzavření',
      dataIndex: 'closure_date',
      key: 'closure_date',
      width: 165,
      render: (closure_date: string, mailrecord: any) => {
        const pastDeadline = moment(closure_date).isAfter(mailrecord.deadline);
        return (
          <span style={{ color: pastDeadline ? '#fe433e' : '' }}>
            {dayMonthYearHourMinute(closure_date)}
          </span>
        );
      },
      filterDropdown: (props: any) => <DateRangeFilter {...props} />,
    },
    {
      title: 'Finální dokument',
      dataIndex: 'final_document',
      key: 'final_document',
      width: 160,
      render: (final_document: any, mailrecord: any) => (
        <Button
          className="no-highlight"
          type="text"
          shape="round"
          size="small"
          disabled={!final_document}
          onClick={() => openFinalDocumentModal(mailrecord)}
          style={{ fontWeight: 600 }}
        >
          <span className="no-highlight">
            {(!final_document ? '0' : '1') + 'x Příloha'}
          </span>
        </Button>
      ),
    },
  ];
}

export default getTableColumns;
