import React, { Component } from 'react';
import { Select, Button } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';

import { CloseButton } from '@components';

import { getOptions, filterOption } from '@utils';

class CreateTestsFromSample extends Component {
  constructor(props) {
    super(props);
    this.state = { error: null, rows: [] };
  }

  addNewRow = () => {
    const { rows } = this.state;
    rows.push({ group: false, test_type: null, test: null });
    this.setState({ rows });
  };

  addNewGroupRow = () => {
    const { rows } = this.state;
    rows.push({ group: true, construction_test_group: null, tests: [] });
    this.setState({ rows });
  };

  onSubmit = () => {
    const { rows } = this.state;
    const validRows = rows.filter(({ group, construction_test_group, tests, test_type, test }) => {
      if (group) {
        return construction_test_group;
      } else {
        return test;
      }
    });

    if (validRows.length === 0) {
      this.setState({ error: 'Vyplňte alespoň jeden řádek' });
    } else if (validRows.length === rows.length) {
      const {
        sample: { id },
        onSubmit,
      } = this.props;
      onSubmit(id, validRows);
    } else {
      this.setState({ error: 'Zkontrolujte, zda jsou všechny řádky vyplněny správně' });
    }
  };

  renderRows() {
    const { test_types, construction_test_groups, construction_tests } = this.props;
    const { rows } = this.state;

    return rows.map(({ group, construction_test_group, tests, test_type, test }, index) => (
      group ? (
        <div key={index} style={{
          alignItems: 'baseline',
          display: 'flex',
          overflow: 'hidden',
          width: '100%',
          marginBottom: 10,
        }}>
          <Select
            style={{ width: '20%' }}
            placeholder="Skupina zkoušek"
            value={construction_test_group}
            options={getOptions(construction_test_groups)}
            onChange={(construction_test_group) => {
              const { rows } = this.state;
              rows[index].construction_test_group = construction_test_group;
              const targetOption = construction_test_groups.filter(_ => _.id === construction_test_group)[0];
              const tests = (targetOption && targetOption.tests) || [];
              rows[index].tests = tests;
              this.setState({ rows });
            }}
            showSearch
            filterOption={filterOption}
          />
          <div style={{ width: '80%', paddingLeft: 10 }}>
            {
              tests.map(({ id, name, test_type }) => (
                <div key={id} style={{ display: 'flex', marginBottom: 10, width: '100%' }}>
                  <Select
                    style={{ width: '90%', marginRight: 10 }}
                    readOnly
                    open={false}
                    value={id}
                    options={getOptions(tests)}
                  />
                  <CloseButton
                    onClick={() => {
                      const { rows } = this.state;
                      rows[index].tests = rows[index].tests.filter(_ => _.id !== id);
                      if (rows[index].tests.length === 0) {
                        rows.splice(index, 1);
                      }
                      this.setState({ rows });
                    }}
                  />
                </div>
              ))
            }
          </div>
        </div>
      ) : (
        <div key={index} style={{
          alignItems: 'center',
          display: 'flex',
          marginBottom: 10,
          overflow: 'hidden',
          width: '100%',
        }}>
          <Select
            style={{ width: '20%' }}
            placeholder="Druh zkoušky"
            value={test_type}
            options={getOptions(test_types)}
            onChange={(test_type) => {
              const { rows } = this.state;
              rows[index].test_type = test_type;
              rows[index].test = null;
              this.setState({ rows });
            }}
            showSearch
            filterOption={filterOption}
          />
          <div style={{ width: '80%', paddingLeft: 10 }}>
            <Select
              style={{ width: '90%', marginRight: 10 }}
              placeholder="Typ zkoušky"
              value={test}
              options={getOptions(!test_type ? construction_tests : (
                construction_tests.filter((_) => _.test_type === test_type)
              ))}
              onChange={(test) => {
                const { rows } = this.state;
                rows[index].test = test;
                this.setState({ rows });
              }}
              showSearch
              filterOption={filterOption}
            />
            <CloseButton
              onClick={() => {
                const { rows } = this.state;
                rows.splice(index, 1);
                this.setState({ rows });
              }}
            />
          </div>
        </div>
      )
    ));
  }

  renderAddRowButtons() {
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Button
          type="text"
          shape="round"
          onClick={this.addNewRow}
          icon={<PlusCircleOutlined style={{ fontSize: 16 }} />}
        >
          Přidat zkoušku
        </Button>
        <Button
          type="text"
          shape="round"
          onClick={this.addNewGroupRow}
          icon={<PlusCircleOutlined style={{ fontSize: 16 }} />}
        >
          Přidat skupinu zkoušek
        </Button>
      </div>
    );
  }

  renderSubmitButton() {
    const { error } = this.state;
    return (
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        width: '100%',
        alignItems: 'center',
      }}>
        {error && (
          <p
            style={{
              backgroundColor: 'rgba(244, 67, 54, .15)',
              color: 'rgb(244, 67, 54)',
              padding: '8px',
              textAlign: 'center',
              width: '100%',
            }}
          >
            {error}
          </p>
        )}
        <Button
          type="primary"
          shape="round"
          onClick={this.onSubmit}
          style={{ marginTop: 10 }}
        >
          Potvrdit
        </Button>
      </div>
    );
  }

  render() {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-around',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            maxHeight: 500,
            overflowY: 'auto',
            width: '100%',
          }}
        >
          {this.renderRows()}
        </div>
        {this.renderAddRowButtons()}
        {this.renderSubmitButton()}
      </div>
    );
  }
}

export default CreateTestsFromSample;
