import { Routes, Route } from 'react-router-dom';

import {
  AuthPageContainer,
  // NoAuthPageContainer,
  PageContainer,
} from '@components';

import {
  HelpPage,
  IndexPage,
  KZPPage,
  MailRecordsPage,
  OrdersPage,
  QRImportPage,
  RoadTestsPage,
} from '@pages';

const AppRoutes = () => {
  return (
    <Routes>
      {/* Need to be logged in */}
      {["/view/roads", "/view/roads/:id"].map((path, idx) => (
        <Route key={idx} path={path} element={(
          <AuthPageContainer hasAccess={(user: User) => user.has_roadtests_access}>
            <RoadTestsPage />
          </AuthPageContainer>
        )} />
      ))}
      {["/view/kzp", "/view/kzp/:id"].map((path, idx) => (
        <Route key={idx} path={path} element={(
          <AuthPageContainer hasAccess={(user: User) => user.has_kzp_access && user.is_superuser}>
            <KZPPage />
          </AuthPageContainer>
        )} />
      ))}
      {["/view/orders", "/view/orders/:id"].map((path, idx) => (
        <Route key={idx} path={path} element={(
          <AuthPageContainer hasAccess={(user: User) => user.has_orders_access}>
            <OrdersPage />
          </AuthPageContainer>
        )} />
      ))}
      {["/view/mailrecords", "/view/mailrecords/:id"].map((path, idx) => (
        <Route key={idx} path={path} element={(
          <AuthPageContainer hasAccess={(user: User) => user.has_mailrecords_access}>
            <MailRecordsPage />
          </AuthPageContainer>
        )} />
      ))}
      <Route path="/view/help" element={(
        <AuthPageContainer>
          <HelpPage />
        </AuthPageContainer>
      )} />
      <Route path="/view/qr" element={(
        <PageContainer noHeader>
          <QRImportPage />
        </PageContainer>
      )} />
      {/* Just functions / redirects */}
      <Route path="*" element={<IndexPage />} />
    </Routes>
  );
};

export default AppRoutes;
