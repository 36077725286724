import { useState, Fragment } from 'react';
import { Button } from 'antd';

type ExportFileProps = {
  onExport: Function;
  buttonText: string;
  defaultExportLink?: string;
};

const ExportFile = ({
  onExport,
  buttonText,
  defaultExportLink,
}: ExportFileProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [exportLink, setExportLink] = useState(defaultExportLink);

  const onButtonClick = () => {
    setIsLoading(true);
    setError(false);

    onExport().then(({ link }: any) => {
      setIsLoading(false);
      setExportLink(link);
    }).catch(() => {
      setExportLink(null);
      setIsLoading(false);
      setError(true);
    })
  }

  return (
    <div style={{ alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
      <Button
        type="primary"
        shape="round"
        loading={isLoading}
        onClick={() => onButtonClick()}
      >
        {buttonText}
      </Button>
      {exportLink && (
        <Fragment>
          <div style={{ margin: '10px 0 2px 0' }}>Soubor byl vytvořen.</div>
          <a href={exportLink} download>
            Stáhnout soubor
          </a>
        </Fragment>
      )}
      {error && (
        <div style={{ marginTop: 10 }}>Při exportu došlo k chybě</div>
      )}
    </div>
  );
}

export default ExportFile;
