import { request } from '@utils';

export const getKZPData = (constructionId: string) =>
  request(`/api/kzp/${constructionId}/data`).then((res: any) => res.data);

export const getMoreKZPData = (url: string) =>
  request(url).then((res: any) => res.data);

export const getFilteredKZPData = (constructionId: string, query: string) =>
  request(`/api/kzp/${constructionId}/data?${query}`).then((res: any) => res.data);

export const exportKZPData = (constructionId: string, query: string): Promise<{ link: string }> => {
  const url = `/api/kzp/${constructionId}/data/export/?${query}`;
  return request(url, {
    method: 'post',
  }).then((res: any) => res.data);
};

export const fetchKZPSectionRowData = (id: string) =>
  request(`/api/kzp/${id}/section-row-data`).then((res: any) => res.data);

export const fetchOlderKZPVersion = (kzp_id: string, version_number: number) =>
  request(`/api/kzp/${kzp_id}/version/${version_number}`).then((res: any) => res.data);

export const addKZP = (data: any) => {
  return request('/api/kzp/add', {
    data,
    method: 'post',
  }).then((res: any) => res.data);
};

export const editKZP = (id: string, data: any, state: string) => {
  if (!id) {
    console.warn('KZP id has to be specified');
  }

  if (!state) {
    console.warn('state has to be specified');
  }

  return request(`/api/kzp/${id}/edit?state=${state}`, {
    data,
    method: 'post',
  }).then((res: any) => res.data);
};

export const getOrCreateSectionRow = (section: any, row: any) => {
  return request('/api/kzp/get-or-create-section-row/', {
    data: { section, row },
    method: 'post',
  }).then((res: any) => res.data);
};

export const confirmKZP = (id: string) => {
  if (!id) {
    console.warn('KZP id has to be specified');
  }

  return request(`/api/kzp/${id}/confirm`, {
    method: 'post',
  }).then((res: any) => res.data);
};

export const closeKZP = (id: string) => {
  if (!id) {
    console.warn('KZP id has to be specified');
  }

  return request(`/api/kzp/${id}/close`, {
    method: 'post',
  }).then((res: any) => res.data);
};

export const exportKZP = (id: string) => {
  if (!id) {
    console.warn('KZP id has to be specified');
  }

  return request(`/api/kzp/${id}/export`).then((res: any) => res.data);
};

export const exportKZPRecords = (id: string) => {
  if (!id) {
    console.warn('KZP id has to be specified');
  }

  return request(`/api/kzp/${id}/recordexport`).then((res: any) => res.data);
};

export const addSectionRowRecord = (id: string, data: any) => {
  return request(`/api/kzp/${id}/addrecord`, {
    data,
    method: 'post',
  }).then((res: any) => res.data);
};

export const getKZPOptions = (constructionId: string) =>
  request(`/api/kzp/${constructionId}/tableopts`).then((res: any) => res.data);
