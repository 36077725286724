import axios from 'axios';

import { readCookie } from '@utils';

export const getRequestHeaders = () => {
  const headers: { [key: string]: string } = {
    'Accept': 'application/json; charset=UTF-8',
  };

  // Add CSRF token header
  const clientCSRFtoken = readCookie('csrftoken', '');
  if (clientCSRFtoken) {
    headers['X-CSRFToken'] = clientCSRFtoken;
  }

  return headers;
};

export async function request(
  url: string,
  {
    data = {},
    method = 'get',
    headers = {},
    params = {},
  }: any = {},
) {
  // for development purposes
  url = url.replace('http://localhost:8000', '');

  return axios({
    headers: {
      ...getRequestHeaders(),
      ...headers,
    },
    url,
    data,
    params,
    method,
  });
}
