import fastDeepEqual from 'fast-deep-equal';

export const deepEqual = fastDeepEqual;

export const isValidId = (id?: string|number) => {
  if (!id) {
    return false
  } else if (typeof id === "number") {
    return true;
  } else {
    const invalid = isNaN(parseInt(id)) || typeof parseInt(id) !== 'number';
    return !invalid;
  }
}
