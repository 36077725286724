import Waypoint from 'react-waypoint';
import { Button } from 'antd';
import {
  EditFilled,
  CalendarFilled,
  FilePdfFilled,
  CheckCircleFilled,
} from '@ant-design/icons';

import { SearchFilter, DateRangeFilter } from '@components';

import { dayMonthYear, getFilterOptions } from '@utils';

const getTableColumns = ({
  isFiltering,
  moreRowsUrl,
  moreFilterRowsUrl,
  tableOptions,
  permittedUserActions,
  // isAdmin,
  tableData,
  filteredData,
  loadMoreData,
  setEditingKZP,
  setModeToAddRecords,
  openPrintModal,
}: any) => {
  return [
    {
      title: '',
      width: 95,
      fixed: 'left',
      render: (kzp: any) => {
        const { can_add_records, can_print_kzp } = permittedUserActions;
        const { confirmed } = kzp;

        return (
          <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
            <Button
              type="text"
              shape="circle"
              size="small"
              icon={<EditFilled style={{ fontSize: 18 }} className="theme-color" />}
              onClick={() => setEditingKZP(kzp)}
              title="Editovat"
            />
            {confirmed && can_add_records && (
              <Button
                type="text"
                shape="circle"
                size="small"
                icon={<CalendarFilled style={{ fontSize: 18 }} className="theme-color" />}
                onClick={() => setModeToAddRecords(kzp)}
                title="Kontrola plnění plánu"
              />
            )}
            {confirmed && can_print_kzp && (
              <Button
                type="text"
                shape="circle"
                size="small"
                icon={<FilePdfFilled style={{ fontSize: 18 }} className="theme-color" />}
                onClick={() => openPrintModal(kzp)}
                title="Vytisknout"
              />
            )}
          </div>
        );
      },
    },
    {
      fixed: 'left',
      width: 0,
      render: (_: any, __: any, index: number) => {
        if (
          !isFiltering &&
          moreRowsUrl && (
            (index + 40) === tableData.length ||
            (index + 1) === tableData.length
          )
        ) {
          return <Waypoint onEnter={() => loadMoreData()} />;
        }

        if (
          isFiltering &&
          moreFilterRowsUrl && (
            (index + 40) === filteredData.length ||
            (index + 1) === filteredData.length
          )
        ) {
          return <Waypoint onEnter={() => loadMoreData(true)} />;
        }
      },
    },
    {
      title: '',
      dataIndex: 'confirmed',
      key: 'confirmed',
      width: 30,
      fixed: 'left',
      render: (confirmed: boolean) => confirmed ? (
        <CheckCircleFilled style={{ color: '#43a047', fontSize: 16 }} />
      ) : null,
    },
    {
      title: '#',
      dataIndex: 'kzp_id',
      key: 'kzp_id',
      width: 50,
      fixed: 'left',
    },
    {
      title: 'Datum vytvoření',
      dataIndex: 'time_create',
      key: 'time_create',
      width: 145,
      fixed: 'left',
      render: (created_at: string) => dayMonthYear(created_at),
      filterDropdown: (props: any) => <DateRangeFilter {...props} />,
    },
    {
      title: 'Stavební objekt',
      dataIndex: ['object_label', 'name'],
      key: 'object_label',
      width: 150,
      fixed: 'left',
      render: (object_label_name: string, order: any) => (
        <div className="ellipsis" title={order.object_label?.description || '-'}>
          {object_label_name || '-'}
        </div>
      ),
      filters: getFilterOptions(tableOptions.object_label),
      filterSearch: true,
    },
    {
      title: 'Staničení (km)',
      dataIndex: 'stationing',
      key: 'stationing',
      width: 150,
      filterDropdown: (props: any) => <SearchFilter {...props} />,
    },
    {
      title: 'Konstrukční celek',
      dataIndex: ['construction_unit', 'name'],
      key: 'construction_unit',
      width: 240,
      render: (construction_unit_name: string) => (
        <div className="ellipsis" title={construction_unit_name || '-'}>
          {construction_unit_name || '-'}
        </div>
      ),
      filters: getFilterOptions(tableOptions.construction_unit),
      filterSearch: true,
    },
    {
      title: 'Datum poslední změny',
      dataIndex: 'time_modify',
      key: 'time_modify',
      width: 185,
      render: (time_modify: string) => dayMonthYear(time_modify),
      filterDropdown: (props: any) => <DateRangeFilter {...props} />,
    },
    {
      title: 'Datum potvrzení',
      dataIndex: 'confirmation_date',
      key: 'confirmation_date',
      width: 185,
      render: (confirmation_date: string) => dayMonthYear(confirmation_date),
      filterDropdown: (props: any) => <DateRangeFilter {...props} />,
    },
    {
      title: 'Datum vyhodnocení',
      dataIndex: 'closure_date',
      key: 'closure_date',
      width: 185,
      render: (closure_date: string) => dayMonthYear(closure_date),
      filterDropdown: (props: any) => <DateRangeFilter {...props} />,
    },
    {
      title: 'Vytvořil',
      dataIndex: 'author',
      key: 'author',
      width: 150,
      render: (author: any) => (
        <div className="ellipsis" title={author?.name || author?.username || '-'}>
          {author?.name || author?.username || '-'}
        </div>
      ),
    },
  ];
}

export default getTableColumns;
