// https://stackoverflow.com/a/16148273/8699608
const thousandSeparatorRegex = /\B(?=(\d{3})+(?!\d))/g;

export const integerFormatter = (postfix?: string) => {
  return (
     postfix ? (
      (value: any) => (!value && typeof value === 'string' ? (
        ''
      ) : (
        `${value.replace(thousandSeparatorRegex, ' ')}${postfix}`
      ))
    ) : (
      (value: any) => value.replace(thousandSeparatorRegex, ' ')
    )
  );
}

export const integerParser = () => {
  return (value: any) => value.replace(/\D/g, '');
}

export const floatFormatter = (postfix?: string) => {
  return postfix ? (
    (value: any) => (!value && typeof value === 'string' ? (
      ''
    ) : (
      `${value}${postfix}`
    ))
  ) : (
    (value: any) => value
  );
}

export const floatParser = (postfix?: string) => {
  return postfix ? (
    (value: any) => value.replace(postfix, '')
  ) : (
    (value: any) => value
  );
}

export const roundDecimalOrReturnOriginal = (inputString?: string) => {
  if (!inputString || typeof inputString !== 'string') {
    return '';
  }

  const parsedNumber = parseFloat(inputString.replace(',', '.'));

  if (!isNaN(parsedNumber)) {
    const roundedNumber = Math.round(parsedNumber * 100) / 100;
    return roundedNumber.toString();
  } else {
    return inputString;
  }
}
