import { useState } from 'react';
import moment from 'moment';
import { message, Form } from 'antd';

import { FormField, RoadTestsFileList } from '@components';

import { useDebouncedCallback, defaultDebounceTime } from '@hooks';

import { getOptions, requiredRule } from '@utils';

const EditMailRecordForm = ({
  initialValues,
  onFormSubmit,
  tableOptions,
  canDeleteFiles,
}: any) => {
  const [form] = Form.useForm();
  const [attachments, setAttachments] = useState(initialValues.attachments || []);
  const [finalDocument, setFinalDocument] = useState(initialValues.final_document);

  const performUpdate = async () => {
    try {
      await form.validateFields();

      const fieldValues = form.getFieldsValue();

      // Merge deadline date and time values
      const deadline_date = moment(fieldValues.deadline_date);
      const deadline = moment(fieldValues.deadline);

      deadline_date.set({
        hour: deadline.hour(),
        minute: deadline.minute(),
        second: deadline.second(),
      });

      if (deadline_date.isBefore(moment())) {
        return message.error('Termín nesmí být v minulosti');
      }

      const payload = {
        ...fieldValues,
        deadline_date: deadline_date,
        deadline: deadline_date,
        add_attachment_ids: attachments.map((file: RoadTestsFile) => file.id),
        final_document_id: finalDocument?.id,
      };

      onFormSubmit(payload);
    } catch (error) {
      console.error(error);
      message.error('Opravte prosím chyby ve formuláři');
    }
  }

  const performDebouncedUpdate = useDebouncedCallback(() => {
    performUpdate();
  }, defaultDebounceTime);

  return (
    <Form
      form={form}
      autoComplete="off"
      initialValues={initialValues}
      onValuesChange={() => performDebouncedUpdate()}
      style={{ width: '100%' }}
    >
      <Form.Item
        className="form-item"
        name="subject"
        rules={[requiredRule]}
      >
        <FormField
          type="text"
          placeholder="Předmět"
        />
      </Form.Item>
      <Form.Item
        className="form-item"
        name="recipients"
        rules={[requiredRule]}
      >
        <FormField
          type="select"
          mode="multiple"
          placeholder="Příjemci"
          options={getOptions(tableOptions.recipients)}
        />
      </Form.Item>
      <Form.Item
        className="form-item"
        name="content"
        rules={[requiredRule]}
      >
        <FormField
          type="textarea"
          placeholder="Obsah"
          minRows={6}
          maxRows={6}
        />
      </Form.Item>
      <div className="row2">
        <Form.Item
          className="form-item"
          name="deadline_date"
          rules={[requiredRule]}
        >
          <FormField
            type="date"
            placeholder="Datum termínu"
          />
        </Form.Item>
        <Form.Item
          className="form-item"
          name="deadline"
          rules={[requiredRule]}
        >
          <FormField
            type="time"
            placeholder="Čas termínu"
          />
        </Form.Item>
      </div>
      <div className="row2">
        <div>
          <RoadTestsFileList
            label="Přílohy"
            files={attachments}
            onChange={(files: RoadTestsFile[]) => {
              setAttachments(files);
              performDebouncedUpdate();
            }}
            {...(!canDeleteFiles ? {
              onRemove: null,
            } : {})}
          />
        </div>
        <div>
          <RoadTestsFileList
            label="Finální dokument"
            files={finalDocument ? [finalDocument] : []}
            onChange={(files: RoadTestsFile[]) => {
              setFinalDocument(files[files.length - 1]);
              performDebouncedUpdate();
            }}
            {...(!canDeleteFiles ? {
              onRemove: null,
            } : {})}
          />
        </div>
      </div>
    </Form>
  );
};

export default EditMailRecordForm;
