import { request } from '@utils';

export const getOrders = (constructionId: string) =>
  request(`/api/orders/${constructionId}/data`).then((res) => res.data);

export const getMoreOrders = (url: string) => request(url).then((res) => res.data);

export const getFilteredOrders = (constructionId: string, query: string) =>
  request(`/api/orders/${constructionId}/data?${query}`).then((res) => res.data);

export const exportOrderData = (constructionId: string, query: string) => {
  const url = `/api/orders/${constructionId}/data/export/?${query}`;
  return request(url, {
    method: 'post',
  }).then((res) => res.data);
};

export const addOrder = (constructionId: string, data: any) => {
  return request(`/api/orders/${constructionId}/add/`, {
    data,
    method: 'post',
  }).then((res) => res.data);
};

export const addSimpleOrder = (constructionId: string, data: any) => {
  return request(`/api/orders/${constructionId}/add-simple/`, {
    data,
    method: 'post',
  }).then((res) => res.data);
};

export const editOrder = (constructionId: string, orderId: string, data: any) => {
  return request(`/api/orders/${constructionId}/edit/${orderId}`, {
    data,
    method: 'post',
  }).then((res) => res.data);
};

export const confirmOrder = (constructionId: string, orderId: string) => {
  return request(`/api/orders/${constructionId}/confirm/${orderId}`, {
    method: 'post',
  }).then((res) => res.data);
};

export const denyOrder = (constructionId: string, orderId: string, denialReason: string) => {
  const data = { denial_reason: denialReason };
  return request(`/api/orders/${constructionId}/deny/${orderId}`, {
    data,
    method: 'post',
  }).then((res) => res.data);
};

export const closeOrder = (constructionId: string, orderId: string) => {
  return request(`/api/orders/${constructionId}/close/${orderId}`, {
    method: 'post',
  }).then((res) => res.data);
};

export const stornoOrder = (constructionId: string, orderId: string, stornoReason: string) => {
  const data = { storno_reason: stornoReason };
  return request(`/api/orders/${constructionId}/storno/${orderId}`, {
    data,
    method: 'post',
  }).then((res) => res.data);
};

export const getOrderOptions = (constructionId: string) =>
  request(`/api/orders/${constructionId}/tableoptions/`).then((res) => res.data);
