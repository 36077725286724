import Waypoint from 'react-waypoint';
import { Button } from 'antd';
import {
  EditFilled,
  CheckCircleFilled,
  CloseCircleFilled,
  DeleteFilled,
  ReloadOutlined,
  ToolFilled,
} from '@ant-design/icons';

import { SearchFilter, DateRangeFilter } from '@components';

import { dayMonthYearHourMinute, getFilterOptions } from '@utils';

const getTableColumns = ({
  isFiltering,
  moreRowsUrl,
  moreFilterRowsUrl,
  tableOptions,
  permittedUserActions,
  isAdmin,
  tableData,
  filteredData,
  loadMoreData,
  setEditingOrder,
  setOrderToConfirm,
  setOrderToDeny,
  setOrderToClose,
  setOrderToStorno,
  onOrderRecreate,
}: any) => {
  return [
    {
      title: '',
      width: 130,
      fixed: 'left',
      render: (order: any) => {
        const { id, confirmed, denied, closed, stornoed, storno_reason } = order;

        const canEditOrder =
          permittedUserActions.can_edit_order &&
          (!confirmed || permittedUserActions.can_edit_confirmed_order) &&
          (!closed || permittedUserActions.can_edit_closed_order) &&
          !stornoed;
        const canConfirmOrder =
          permittedUserActions.can_confirm_order && !confirmed && !denied && !stornoed;
        const canDenyOrder =
          permittedUserActions.can_deny_order && !confirmed && !denied && !stornoed;
        const canCloseOrder =
          isAdmin &&
          permittedUserActions.can_close_order &&
          confirmed &&
          !denied &&
          !closed &&
          !stornoed;
        const canStornoOrder = permittedUserActions.can_storno_order && !closed && !stornoed;
        const canRecreateOrder = permittedUserActions.can_create_order;
        const isEdditingConfirmedOrder =
          confirmed && permittedUserActions.can_edit_confirmed_order;

        return (
          <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
            {stornoed && (
              <span
                style={{
                  color: '#444',
                  cursor: 'default',
                  fontSize: 11,
                  fontWeight: 600,
                  lineHeight: '24px',
                  marginRight: 4,
                  transform: 'translateY(1px)',
                }}
                title={storno_reason}
              >
                STORNOVÁNO
              </span>
            )}
            {canEditOrder && (
              <Button
                type="text"
                shape="circle"
                size="small"
                icon={<EditFilled style={{ fontSize: 18 }} className="theme-color" />}
                style={{ opacity: isEdditingConfirmedOrder ? 0.4 : 1 }}
                onClick={() => setEditingOrder(order)}
                title="Editovat"
              />
            )}
            {canConfirmOrder && (
              <Button
                type="text"
                shape="circle"
                size="small"
                icon={<CheckCircleFilled style={{ fontSize: 20 }} className="theme-color" />}
                onClick={() => setOrderToConfirm(id)}
                title="Potvrdit"
              />
            )}
            {canDenyOrder && (
              <Button
                type="text"
                shape="circle"
                size="small"
                icon={<CloseCircleFilled style={{ fontSize: 20 }} className="theme-color" />}
                onClick={() => setOrderToDeny(id)}
                title="Zamítnout"
              />
            )}
            {canCloseOrder && (
              <Button
                type="text"
                shape="circle"
                size="small"
                icon={<ToolFilled style={{ fontSize: 20 }} className="theme-color" />}
                onClick={() => setOrderToClose(order)}
                title="Přebrat"
              />
            )}
            {canStornoOrder && (
              <Button
                type="text"
                shape="circle"
                size="small"
                icon={<DeleteFilled style={{ fontSize: 18 }} className="theme-color" />}
                onClick={() => setOrderToStorno(id)}
                title="Stornovat"
              />
            )}
            {canRecreateOrder && (
              <Button
                type="text"
                shape="circle"
                size="small"
                icon={<ReloadOutlined style={{ fontSize: 18 }} className="theme-color" />}
                onClick={() => onOrderRecreate(order)}
                style={{ opacity: confirmed ? 0.4 : 1 }}
                title="Zkopírovat (předvyplnit formulář)"
              />
            )}
          </div>
        );
      },
    },
    {
      fixed: 'left',
      width: 0,
      render: (_: any, __: any, index: number) => {
        if (
          !isFiltering &&
          moreRowsUrl && (
            (index + 40) === tableData.length ||
            (index + 1) === tableData.length
          )
        ) {
          return <Waypoint onEnter={() => loadMoreData()} />;
        }

        if (
          isFiltering &&
          moreFilterRowsUrl && (
            (index + 40) === filteredData.length ||
            (index + 1) === filteredData.length
          )
        ) {
          return <Waypoint onEnter={() => loadMoreData(true)} />;
        }
      },
    },
    {
      title: '#',
      width: 50,
      fixed: 'left',
      dataIndex: 'test_number',
      key: 'test_number',
    },
    {
      title: 'Datum vytvoření',
      width: 165,
      fixed: 'left',
      dataIndex: 'time_create',
      key: 'time_create',
      render: (time_create: string) => dayMonthYearHourMinute(time_create),
      filterDropdown: (props: any) => <DateRangeFilter {...props} />,
    },
    {
      title: 'Přidělená laboratoř',
      width: 210,
      fixed: 'left',
      dataIndex: ['laboratory', 'name'],
      key: 'laboratory',
      filters: getFilterOptions(tableOptions.laboratory),
      filterSearch: true,
    },
    {
      title: 'Čas provedení',
      dataIndex: 'realization_time',
      key: 'realization_time',
      width: 180,
      render: (realization_time: string) => dayMonthYearHourMinute(realization_time),
      filterDropdown: (props: any) => <DateRangeFilter {...props} />,
    },
    {
      title: 'Druh činnosti',
      dataIndex: 'entry_type',
      key: 'entry_type',
      width: 150,
      render: (entry_type: string) => {
        if (entry_type === 'zkouska_v_terenu') {
          return 'Zkouška v terénu';
        } else if (entry_type === 'odber_vzorku') {
          return 'Odběr vzorku';
        } else {
          return '-';
        }
      },
      filters: [
        { text: 'Zkouška v terénu', value: 'zkouska_v_terenu' },
        { text: 'Odběr vzorku', value: 'odber_vzorku' }
      ],
      filterMultiple: false,
    },
    {
      title: 'Stavební objekt',
      dataIndex: ['object_label', 'name'],
      key: 'object_label',
      width: 150,
      render: (object_label_name: string, order: any) => (
        <div className="ellipsis" title={order.object_label?.description || '-'}>
          {object_label_name || '-'}
        </div>
      ),
      filters: getFilterOptions(tableOptions.object_label),
      filterSearch: true,
    },
    {
      title: 'Fáze výstavby',
      dataIndex: 'col_construction_phase',
      key: 'col_construction_phase',
      width: 180,
      render: (col_construction_phase: string) => (
        <div className="ellipsis" title={col_construction_phase}>
          {col_construction_phase || '-'}
        </div>
      ),
    },
    {
      title: 'Konstrukční celek',
      dataIndex: 'col_construction_part',
      key: 'col_construction_part',
      width: 180,
      render: (col_construction_part: string) => (
        <div className="ellipsis" title={col_construction_part}>
          {col_construction_part || '-'}
        </div>
      ),
    },
    {
      title: 'Technologický celek',
      dataIndex: 'construction_part',
      key: 'construction_part',
      width: 180,
      render: (construction_part: any) => {
        let value = construction_part;
        if (typeof value !== 'string') {
          value = construction_part?.short_name || construction_part?.name;
        }
        return (
          <div className="ellipsis" title={value || '-'}>
            {value || '-'}
          </div>
        );
      },
      filters: getFilterOptions(tableOptions.construction_part, 'select_name'),
      filterSearch: true,
    },
    {
      title: 'Část technologického celku',
      dataIndex: ['technology_part', 'name'],
      key: 'technology_part',
      width: 220,
      render: (technology_part: string) => (
        <div className="ellipsis" title={technology_part}>
          {technology_part || '-'}
        </div>
      ),
      filters: getFilterOptions(tableOptions.technology_part),
      filterSearch: true,
    },
    {
      title: 'Staničení (km)',
      dataIndex: 'stationing',
      key: 'stationing',
      width: 150,
      filterDropdown: (props: any) => <SearchFilter {...props} />,
    },
    {
      title: 'Typ zkoušky',
      dataIndex: ['construction_test', 'name'],
      key: 'construction_test',
      width: 380,
      render: (construction_test_name: string, order: any) => (
        <div className="ellipsis" title={order.construction_test?.description || '-'}>
          {construction_test_name || '-'}
        </div>
      ),
      filters: getFilterOptions(tableOptions.construction_test),
      filterSearch: true,
    },
    {
      title: 'Počet',
      dataIndex: 'count',
      key: 'count',
      width: 100,
      filterDropdown: (props: any) => <SearchFilter {...props} />,
    },
    ...(permittedUserActions.can_read_acreage ? [
      {
        title: 'Výměra',
        dataIndex: 'acreage_value',
        key: 'acreage_value',
        width: 160,
        filterDropdown: (props: any) => <SearchFilter {...props} />,
      },
      {
        title: 'Jednotky',
        dataIndex: 'acreage_unit',
        key: 'acreage_unit',
        width: 160,
        filters: [
          { text: 'm²', value: 'm2' },
          { text: 'm³', value: 'm3' },
          { text: 'bm', value: 'bm' },
          { text: 't', value: 't' },
        ],
        filterMultiple: false,
      },
    ] : []),
    {
      title: 'Poznámka',
      dataIndex: 'note',
      key: 'note',
      width: 400,
      render: (note: string) => (
        <div className="ellipsis" title={note}>
          {note || '-'}
        </div>
      ),
      filterDropdown: (props: any) => <SearchFilter {...props} />,
    },
    {
      title: 'Datum poslední změny',
      dataIndex: 'time_modify',
      key: 'time_modify',
      width: 200,
      render: (time_modify: string) => dayMonthYearHourMinute(time_modify),
      filterDropdown: (props: any) => <DateRangeFilter {...props} />,
    },
    {
      title: 'Datum potvrzení',
      dataIndex: 'confirmation_time',
      key: 'confirmation_time',
      width: 170,
      render: (confirmation_time: string) => dayMonthYearHourMinute(confirmation_time),
      filterDropdown: (props: any) => <DateRangeFilter {...props} />,
    },
    {
      title: 'Vytvořil',
      dataIndex: 'author',
      key: 'author',
      width: 180,
    },
    {
      title: 'Editováno uživateli',
      dataIndex: 'edited_by',
      key: 'edited_by',
      width: 220,
      render: (edited_by: string) => (
        <div className="ellipsis" title={edited_by}>
          {edited_by || '-'}
        </div>
      ),
    },
  ];
};

export default getTableColumns;
