import React, { Fragment, useContext, useEffect } from 'react';

import { Header } from '@components';

import { useRequireAuth, useRequireNoAuth } from '@hooks';

import { UserContext } from '@reducers';

type PageContainerProps = {
  noHeader?: boolean;
  children: React.ReactNode;
};

export const PageContainer = ({ noHeader = false, children }: PageContainerProps) => {
  const {
    state: { hasFetched, loggedIn },
  } = useContext(UserContext);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // This is here because this can be used as a standalone
  // container for pages for both authenticated and public users
  if (!hasFetched) {
    return null;
  }

  return (
    <Fragment>
      {
        !noHeader && loggedIn && (
          <Header />
        )
      }
      {children}
    </Fragment>
  );
};

type AuthPageContainerProps = {
  noHeader?: boolean;
  hasAccess?: Function;
  children: React.ReactNode;
};

export const AuthPageContainer = ({ noHeader = false, hasAccess, children }: AuthPageContainerProps) => {
  const valid = useRequireAuth(hasAccess);

  if (!valid) {
    return null;
  }

  return (
    <PageContainer noHeader={noHeader}>
      {children}
    </PageContainer>
  );
};

type NoAuthPageContainerProps = {
  noHeader?: boolean;
  children: React.ReactNode;
};

export const NoAuthPageContainer = ({ noHeader = false, children }: NoAuthPageContainerProps) => {
  const valid = useRequireNoAuth();

  if (!valid) {
    return null;
  }

  return (
    <PageContainer noHeader={noHeader}>
      {children}
    </PageContainer>
  );
};
