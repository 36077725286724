import React from 'react';
import { message, Button, Tooltip } from 'antd';
import {
  PlusOutlined,
  CheckOutlined,
  CloseOutlined,
} from '@ant-design/icons';

import { dayMonthYear } from '@utils';

const KZPAddRecordFormColumnNames: Array<string> = [
  'Oblast',
  'Kontrolovaná vlastnost',
  'Jednotky a požadovaná četnost',
  'Zpřísňující parametr',
  'Počet měrných jednotek',
  'Počet požadovaných zkoušek',
  // 'Datum posledního záznamu',
  'Zpracované množství',
];

const KZPAddRecordFormColumnNamesNotClosed: Array<string> = [
  'Přidat množství',
  // 'Zbývající množství',
  'Počet chybějících zkoušek',
];

const KZPAddRecordFormColumnNamesClosed: Array<string> = [
  'Počet provedených zkoušek',
  'Odchylka zkoušek',
  'Závěr V / N',
];

const cellStyles = {
  borderBottom: '1px solid #ddd',
  borderLeft: '1px solid #ddd',
  maxHeight: 25,
  height: 25,
  lineHeight: '135%',
};

const headerCellStyles = {
  ...cellStyles,
  backgroundColor: '#eee',
  borderTop: '1px solid #ddd',
  color: '#222',
  fontWeight: '400',
  padding: 8,
  textAlign: 'left',
};

const rowCellStyles = {
  ...cellStyles,
  padding: 8,
};

const NUMBER_REG = new RegExp(/^\d+$/);
const calculateNumberOfRequiredConstructionTests = (value, amountPerTest) =>
  Math.ceil(parseInt(value, 10) / parseInt(amountPerTest, 10));
// const calculateNumberOfCompletedConstructionTests = (value, amountPerTest) =>
//   Math.floor(parseInt(value, 10) / parseInt(amountPerTest, 10));
const calculateNumberOfTestsThatShouldBeCompleted = (value, amountPerTest) =>
  Math.ceil(parseInt(value, 10) / parseInt(amountPerTest, 10));

// type ComponentProps = {|
//   sectionsData: Object,
//   can_close_kzp: boolean,
//   can_print_kzp: boolean,
//   onSubmit: Function,
//   onClose: Function,
//   onPrintKZPRecords: Function,
//   isClosed: boolean,
// |};

// type ComponentState = {|
//   recordBeingAdded: string,
//   newRecordValue: string,
// |};

class KZPAddRecordForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      recordBeingAdded: null,
      newRecordValue: '',
    };
  }

  startAddingRecord = (id) => {
    // recordBeingAdded is id of the sectionRowData
    this.setState({
      recordBeingAdded: id,
    });
  };

  onNewRecordAmountChange = (event) => {
    const { value: amount } = event.target;

    if (!amount) {
      this.setState({ newRecordValue: '' });
    } else if (NUMBER_REG.test(amount)) {
      this.setState({ newRecordValue: amount });
    }
  };

  onAddAmount = (row) => {
    const { recordBeingAdded, newRecordValue } = this.state;

    const amount = row.records.reduce((currentAmount, record) => {
      return currentAmount + record.current_amount;
    }, 0);

    if (amount + parseInt(newRecordValue, 10) > row.amount) {
      return message.error('Nelze překročit počet měrných jednotek');
    }

    if (recordBeingAdded && newRecordValue) {
      // recordBeingAdded is id of the sectionRowData
      this.props.onSubmit(recordBeingAdded, newRecordValue).then(this.onCancel);
    }
  };

  onCancel = () => {
    this.setState({ recordBeingAdded: null, newRecordValue: '' });
  };

  renderNewRecordInput(row) {
    const { recordBeingAdded, newRecordValue } = this.state;

    if (recordBeingAdded !== row.id) {
      return (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button
            type="text"
            shape="circle"
            icon={<PlusOutlined />}
            onClick={() => this.startAddingRecord(row.id)}
          />
        </div>
      );
    } else {
      return (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button
            type="text"
            shape="circle"
            icon={<CheckOutlined />}
            onClick={() => this.onAddAmount(row)}
          />

          <input
            className="kzp-amount-input"
            value={newRecordValue}
            onChange={this.onNewRecordAmountChange}
            style={{ width: '80px', margin: '0 10px' }}
          />

          <Button
            type="text"
            shape="circle"
            icon={<CloseOutlined />}
            onClick={() => this.onCancel()}
          />
        </div>
      );
    }
  }

  renderSectionRowDataRow(row, addingSectionColumn, numberOfAllRows) {
    const {
      id: sectionRowDataId,
      section_row: { test, section },
      selected_unit_specification,
      amount_restriction,
      amount,
      records,
      number_of_completed_tests,
    } = row;
    const { name: testName } = test;
    const { name: sectionName } = section;

    const unitSpecification = test.test_unit_specifications.filter(
      (_) => _.id === selected_unit_specification
    )[0];
    const unitName = (unitSpecification && unitSpecification.unit.name) ||
      test.test_unit_specifications[0].unit.name;
    const amountPerTest =
      (unitSpecification && unitSpecification.amount_per_test) ||
      test.test_unit_specifications[0].amount_per_test;

    // This actually isn't used in calculation (for now?)
    const numberOfRequiredConstructionTests = calculateNumberOfRequiredConstructionTests(amount, amount_restriction || amountPerTest);

    const requiredAmount = records.reduce((amount, { current_amount }) => amount + current_amount, 0);
    const numberOfTestsThatShouldBeCompleted = calculateNumberOfTestsThatShouldBeCompleted(
      requiredAmount,
      amount_restriction || amountPerTest,
    );
    const numberOfCompletedConstructionTests = number_of_completed_tests;
    const numberOfRemainingConstructionTests = Math.max(
      numberOfTestsThatShouldBeCompleted - numberOfCompletedConstructionTests,
      0
    );

    const valid = numberOfRemainingConstructionTests === 0;
    const evaluation = valid ? 'Vyhovuje' : 'Nevyhovuje';

    const tooltipTitle = (
      <div>
        {records.map(({ id, date, current_amount }) => (
          <p key={id} style={{ color: '#fff', margin: 0 }}>
            {dayMonthYear(date)}: <b>{current_amount}</b>
          </p>
        ))}
      </div>
    );

    const disabled = records.length === 0;
    const tableRowStyle = {};

    if (this.props.isClosed) {
      if (valid) {
        tableRowStyle['backgroundColor'] = '#e1ffe9';
      } else {
        tableRowStyle['backgroundColor'] = '#ffe1e1';
      }
    }

    return (
      <tr key={sectionRowDataId} style={tableRowStyle}>
        {addingSectionColumn && (
          <td rowSpan={numberOfAllRows} style={{ ...rowCellStyles, backgroundColor: '#fff' }}>
            {sectionName}
          </td>
        )}
        <td style={rowCellStyles}>{testName}</td>
        <td style={rowCellStyles}>{unitName}, {amountPerTest}</td>
        <td style={rowCellStyles}>{amount_restriction}</td>
        <td style={rowCellStyles}>{amount}</td>
        <td style={rowCellStyles}>{numberOfRequiredConstructionTests}</td>
        <td style={rowCellStyles}>
          <Tooltip title={tooltipTitle} placement="bottom">
            <p style={{ margin: 0 }}>{requiredAmount}</p>
          </Tooltip>
        </td>
        {!this.props.isClosed && (
          <React.Fragment>
            <td style={{ ...rowCellStyles, minWidth: 195 }}>
              {this.renderNewRecordInput(row)}
            </td>
            <td style={{ ...rowCellStyles, borderRight: '1px solid #ddd' }}>
              {numberOfRemainingConstructionTests}
            </td>
          </React.Fragment>
        )}
        {this.props.isClosed && (
          <React.Fragment>
            <td style={rowCellStyles}>{numberOfCompletedConstructionTests}</td>
            <td style={rowCellStyles}>{numberOfRemainingConstructionTests}</td>
            <td style={{ ...rowCellStyles, borderRight: '1px solid #ddd' }}>{evaluation}</td>
          </React.Fragment>
        )}
      </tr>
    );
  }

  renderSectionRows() {
    const { sectionsData } = this.props;
    const rowsToReturn = [];

    for (let sectionId in sectionsData) {
      const rows = sectionsData[sectionId];

      let addSectionColumn = true;
      rows.forEach((row, index) => {
        rowsToReturn.push(this.renderSectionRowDataRow(row, addSectionColumn, rows.length));
        // any other rows related to this section won't have
        // the section table cell rendered
        addSectionColumn = false;
      });
    }

    return rowsToReturn;
  }

  renderRecordsTable() {
    let columns = KZPAddRecordFormColumnNames;
    if (this.props.isClosed) {
      columns = [...columns, ...KZPAddRecordFormColumnNamesClosed];
    } else {
      columns = [...columns, ...KZPAddRecordFormColumnNamesNotClosed];
    }

    return (
      <div className="edit-kzp-table" style={{ marginTop: 20 }}>
        <table>
          <tbody>
            <tr>
              {columns.map((name, index) => {
                const isLast = index + 1 === columns.length;
                return (
                  <th
                    key={name}
                    style={{
                      ...headerCellStyles,
                      borderRight: isLast && '1px solid #ddd',
                    }}
                  >
                    {name === 'Přidat množství' ? <b>{name}</b> : name}
                  </th>
                );
              })}
            </tr>
            {this.renderSectionRows()}
          </tbody>
        </table>
      </div>
    );
  }

  render() {
    const { can_close_kzp, can_print_kzp, isClosed, onClose, onPrintKZPRecords } = this.props;

    return (
      <div style={{ width: "100%", padding: "0 0 1rem 0" }}>
        {this.renderRecordsTable()}

        <div style={{ display: 'flex', justifyContent: 'center', margin: '50px 0 0 0' }}>
          {can_close_kzp && !isClosed && (
            <Button className="green-button" type="primary" shape="round" onClick={onClose}>
              Vyhodnotit
            </Button>
          )}
          <div style={{ margin: '0 7px' }} />
          {can_print_kzp && (
            <Button className="green-button" type="primary" shape="round" onClick={onPrintKZPRecords}>
              Vytisknout záznamy
            </Button>
          )}
        </div>
      </div>
    );
  }
}

export default KZPAddRecordForm;
