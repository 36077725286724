import { Component } from 'react';
import Waypoint from 'react-waypoint';
import moment from 'moment';
import { message, Button, Modal, Input, Card } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import { CloseButton, FileList, ResizableVirtualTable } from '@components';

import { useModal } from '@hooks';

import {
  mapOptionsToMailRecords,
  getFilterOptions,
  updateById,
  dayMonthYearHourMinute,
  getFilterQuery,
  highlightTableRow,
} from '@utils';

import getTableColumns from './getTableColumns';
import {
  getMailRecords,
  getMoreMailRecords,
  getFilteredMailRecords,
  addMailRecord,
  editMailRecord,
  getMailRecordOptions,
} from './requests';

import AddMailRecordForm from './AddMailRecordForm.tsx';
import EditMailRecordForm from './EditMailRecordForm';
import MailRecordsTimeline from './MailRecordsTimeline';

class MailRecordsTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tableData: [],
      tableOptions: {},

      loadingTable: false,

      isFiltering: false,
      filteredData: [],

      isAddMode: false,
      isEditMode: false,

      tableKey: 1,
    };

    this.moreRowsUrl = '';
    this.moreFilterRowsUrl = '';
    this.permittedUserActions = {};
    this.filters = {};

    this.attachmentFilesToUpload = [];
    this.finalDocumentToUpload = null;
  }

  componentDidMount() {
    this.requestTableData(this.props.constructionId);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.constructionId !== this.props.constructionId) {
      this.setFiltersToNull();
      this.setModeToNull();
      this.requestTableData();
    }
  }

  requestTableData() {
    const { constructionId } = this.props;
    this.setState({ loadingTable: true });

    return Promise.all([
      getMailRecords(constructionId),
      getMailRecordOptions(constructionId),
    ]).then((responses) => {
      const [tableData, tableOptions] = responses;
      const { results, next, permitted_actions } = tableData;

      this.permittedUserActions = permitted_actions;
      this.moreRowsUrl = next;

      this.setState({
        tableData: mapOptionsToMailRecords(results, tableOptions),
        tableOptions,
        loadingTable: false,
      });
    });
  }

  loadMoreData = (isFiltered = false) => {
    if (
      (!isFiltered && !this.moreRowsUrl) ||
      (isFiltered && !this.moreFilterRowsUrl) ||
      this.isLoadingMoreData
    ) {
      return;
    }

    this.isLoadingMoreData = true;

    const onOk = ({ next, results }) => {
      this.isLoadingMoreData = false;
      if (!isFiltered) {
        this.moreRowsUrl = next;
        this.setState({
          tableData: this.state.tableData.concat(
            mapOptionsToMailRecords(results, this.state.tableOptions)
          ),
        });
      } else {
        this.moreFilterRowsUrl = next;
        this.setState({
          filteredData: this.state.filteredData.concat(
            mapOptionsToMailRecords(results, this.state.tableOptions)
          ),
        });
      }
    };

    const onErr = () => {
      this.isLoadingMoreData = false;
      message.error('Při načítání záznamů došlo k chybě');
    };

    if (!isFiltered) {
      getMoreMailRecords(this.moreRowsUrl).then(onOk, onErr);
    } else {
      getMoreMailRecords(this.moreFilterRowsUrl).then(onOk, onErr);
    }
  };

  fetchFilterData = () => {
    const query = getFilterQuery(this.filters);
    getFilteredMailRecords(this.props.constructionId, query).then(({ next, results }) => {
      const newFilteredData = mapOptionsToMailRecords(results, this.state.tableOptions);
      this.moreFilterRowsUrl = next;
      this.setState({
        filteredData: newFilteredData,
        isFiltering: true,
      });
    });
  };

  setModeToNull = (callback = () => {}) => {
    this.editingMailRecord = {};

    this.setState({
      isAddMode: false,
      isEditMode: false,
    }, callback);
  };

  setFiltersToNull = () => {
    this.filters = {};
    this.setState({
      ...this.state,
      tableKey: this.state.tableKey + 1,
      filteredData: [],
      isFiltering: false,
    });
  };

  setEditingMailRecord = (mailrecord) => {
    this.setModeToNull(() => {
      this.editingMailRecord = mailrecord;
      this.setState({ isEditMode: true });
    });
  };

  openAttachmentModal = (mailrecord) => {
    this.props.openAttachmentModal('Přílohy', {
      files: mailrecord.attachments.map((file) => ({
        ...file,
        url: file.file,
      })),
      readOnly: true,
    });
  };

  openFinalDocumentModal = (mailrecord) => {
    this.props.openAttachmentModal('Finální dokument', {
      files: [{
        ...mailrecord.final_document,
        url: mailrecord.final_document.file,
      }],
      readOnly: true,
    });
  };

  updateMailRecordData = (data, updateEditingMailrecord = true) => {
    const updatedMailRecordData = mapOptionsToMailRecords(
      [data],
      this.state.tableOptions,
    )[0];
    const tableData = updateById(
      this.state.tableData,
      updatedMailRecordData.id,
      updatedMailRecordData
    );
    const filteredData = updateById(
      this.state.filteredData,
      updatedMailRecordData.id,
      updatedMailRecordData,
    );
    if (updateEditingMailrecord) {
      this.editingMailRecord = updatedMailRecordData;
    }
    this.setState({ tableData, filteredData });
    return updatedMailRecordData;
  }

  onAddFormSubmit = (fields) => {
    const { constructionId } = this.props;

    return addMailRecord(constructionId, fields).then(({ data }) => {
      delete fields['add_attachment_ids'];
      delete fields['final_document_id'];

      localStorage.setItem('lastMailrecordFields', JSON.stringify(fields));
      this.requestTableData(constructionId).then(() => {
        this.setModeToNull();
        message.success('Záznam v Knize pošty byl vytvořen');
      });
    }).catch(() => {
      message.error('Při vytváření záznamu došlo k chybě');
    });
  };

  onEditFormSubmit = (fields) => {
    return editMailRecord(
      this.props.constructionId,
      this.editingMailRecord.id,
      fields,
    ).then(({ data }) => {
      const updatedMailRecordData = this.updateMailRecordData(data);
      message.success('Změny byly uloženy');
    }).catch(() => {
      message.error('Při editaci záznaku došlo k chybě');
    });
  };

  onCommentMailRecord = (data) => {
    const updatedMailRecordData = this.updateMailRecordData(data);
    message.success('Komentář byl přidán');
  };

  onHandoverMailRecord = (data) => {
    const updatedMailRecordData = this.updateMailRecordData(data);
    message.success('Záznam byl předán');
  };

  onCloseMailRecord = (data) => {
    const updatedMailRecordData = this.updateMailRecordData(data);
    message.success('Záznam byl uzavřen');
  };

  renderAddMailRecordForm() {
    const { tableOptions } = this.state;

    return (
      <div style={{ padding: "1rem 0", margin: "0 auto", width: "90%" }}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div></div>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <div>
              <p style={{ paddingTop: 5 }}>
                Právě vytváříte <b>nový záznam v Knize pošty</b>
              </p>
              <Card>
                <AddMailRecordForm
                  tableOptions={tableOptions}
                  onFormSubmit={this.onAddFormSubmit}
                  lastMailrecordFields={JSON.parse(
                    localStorage.getItem('lastMailrecordFields')
                  )}
                />
              </Card>
            </div>
          </div>
          <CloseButton
            tooltip="Zrušit"
            onClick={() => this.setModeToNull()}
          />
        </div>
      </div>
    );
  }

  renderMailRecordTimeline() {
    return (
      <MailRecordsTimeline
        constructionId={this.props.constructionId}
        mailrecord={this.editingMailRecord}
        permittedUserActions={this.permittedUserActions}
        tableOptions={this.state.tableOptions}
        onCommentMailRecord={this.onCommentMailRecord}
        onHandoverMailRecord={this.onHandoverMailRecord}
        onCloseMailRecord={this.onCloseMailRecord}
      />
    );
  }

  renderEditMailRecordForm() {
    if (!this.editingMailRecord) {
      return this.setModeToNull();
    }

    const { test_number, closed } = this.editingMailRecord;
    const canEdit = this.permittedUserActions.can_edit_mailrecord;
    const closeTooltip = canEdit ? 'Zavřít editační okno' : 'Zavřít okno';

    return (
      <div style={{ padding: "1rem 0", margin: "0 auto", width: "90%", maxWidth: 1000 }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          {canEdit && !closed ? (
            <p style={{ paddingTop: 10 }}>
              Právě editujete <b>záznam {test_number}</b>
            </p>
          ) : (
            <p style={{ paddingTop: 10 }}>
              <b>Historie záznamu {test_number}</b>
            </p>
          )}

          <CloseButton
            tooltip={closeTooltip}
            onClick={() => this.setModeToNull()}
          />
        </div>

        {canEdit && !closed && (
          <Card>
            <div style={{
              display: 'flex',
              justifyContent: 'flex-start',
              flexWrap: 'wrap',
              flexDirection: 'row',
              height: '100%',
              width: '100%',
            }}>
              <EditMailRecordForm
                tableOptions={this.state.tableOptions}
                onFormSubmit={this.onEditFormSubmit}
                initialValues={{
                  ...this.editingMailRecord,
                  deadline_date: this.editingMailRecord.deadline,
                  recipients: this.editingMailRecord.recipients.map(_ => _.id),
                  attachments: this.editingMailRecord.attachments,
                  final_document: this.editingMailRecord.final_document,
                }}
                canDeleteFiles={this.permittedUserActions.can_delete_attachments}
              />
            </div>
          </Card>
        )}
        {canEdit && !closed && <div style={{ margin: '14px 0' }} />}
        {this.renderMailRecordTimeline()}
      </div>
    );
  }

  render() {
    const { isAddMode, isEditMode, tableData, loadingTable } = this.state;

    return (
      <div>
        <div style={{ width: '100%', overflow: 'hidden' }}>
          <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', width: '100%' }}>
            {isAddMode && this.renderAddMailRecordForm()}
            {isEditMode && this.renderEditMailRecordForm()}
          </div>

          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            {this.state.isFiltering ? (
              <Button
                type="primary"
                shape="round"
                style={{ marginLeft: 20 }}
                onClick={() => this.setFiltersToNull()}
              >
                Zrušit filtrování
              </Button>
            ) : <div />}
            <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%', minHeight: '60px' }}>
              {(
                this.permittedUserActions.can_create_mailrecord &&
                !this.state.isAddMode &&
                !this.state.isEditMode
              ) && (
                <Button
                  type="primary"
                  shape="circle"
                  size="large"
                  icon={<PlusOutlined />}
                  style={{ margin: '10px 20px 10px 10px', height: 54, width: 54 }}
                  onClick={() => this.setState({ isAddMode: true })}
                  title="Přidat"
                />
              )}
            </div>
          </div>

          {!loadingTable && (
            <ResizableVirtualTable
              key={this.state.tableKey}
              bordered
              pagination={false}
              scroll={{
                x: '100vw',
                y: 'calc(100vh - 204px)',
              }}
              dataSource={(
                this.state.isFiltering ? this.state.filteredData : this.state.tableData
              ).map((_ => ({ ..._, key: _.id })))}
              onChange={(_, filters: any) => {
                this.filters = filters;
                this.fetchFilterData();
              }}
              rowClassName={(row) => {
                const { id, closed } = row;

                let className = `table-row-${id}`;
                if (closed) className += ' table-row-green';

                return className;
              }}
              onRow={({ id }) => ({ onClick: event => highlightTableRow(event, id) })}
              columns={getTableColumns({
                isFiltering: this.state.isFiltering,
                moreRowsUrl: this.moreRowsUrl,
                moreFilterRowsUrl: this.moreFilterRowsUrl,
                tableOptions: this.state.tableOptions,
                permittedUserActions: this.permittedUserActions,
                isAdmin: this.props.isAdmin,
                tableData: this.state.tableData,
                filteredData: this.state.filteredData,
                loadMoreData: this.loadMoreData,
                setEditingMailRecord: this.setEditingMailRecord,
                openAttachmentModal: this.openAttachmentModal,
                openFinalDocumentModal: this.openFinalDocumentModal,
              })}
            />
          )}
        </div>

        {this.props.renderAttachmentModal()}
      </div>
    );
  }
}

export default (props) => {
  const [
    openAttachmentModal,
    closeAttachmentModal,
    renderAttachmentModal,
  ] = useModal(
    FileList,
    { width: 600 },
  );

  return <MailRecordsTable
    {...props}

    openAttachmentModal={openAttachmentModal}
    closeAttachmentModal={closeAttachmentModal}
    renderAttachmentModal={renderAttachmentModal}
  />;
}
