import { useRef, useEffect } from 'react';

import { useDebounce, useDebouncedCallback } from 'use-debounce';

const defaultDebounceTime = 1000;

export {
  // https://github.com/xnimorz/use-debounce
  useDebounce,
  useDebouncedCallback,
  defaultDebounceTime,
};

// https://usehooks.com/useEventListener/
export const useEventListener = (eventName: string, handler: any, element: any = window) => {
  const savedHandler: any = useRef();

  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(() => {
    const isSupported = element && element.addEventListener;
    if (!isSupported) {
      return;
    }

    const eventListener = (event: any) => savedHandler.current(event);
    element.addEventListener(eventName, eventListener);
    return () => {
      element.removeEventListener(eventName, eventListener);
    };
  }, [eventName, element]);
}
