import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import {
  AppRoutes,
  WithErrorSuppression,
  WithInitialData,
  WithLocale,
  WithProviders,
  WithQueryClient,
} from '@app';

import '@styles/antd.less';
import '@styles/styles.scss';
import '@styles/kzp.scss';
import '@styles/mailrecords.scss';
import '@styles/react-resizable.scss';

ReactDOM.render(
  <WithErrorSuppression>
    <WithQueryClient>
      <WithProviders>
        <WithInitialData>
          <BrowserRouter>
            <WithLocale>
              <AppRoutes />
            </WithLocale>
          </BrowserRouter>
        </WithInitialData>
      </WithProviders>
    </WithQueryClient>
  </WithErrorSuppression>,
  document.getElementById('root')
);
