import React from 'react';
import { Button } from 'antd';
import { EyeFilled, EyeInvisibleFilled } from '@ant-design/icons';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const grid = 2.5;
const columnsListWidth = 320;

const doNothing = () => {};

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  color: isDragging ? '#E0E5FF' : '#003063',
  fontWeight: 'bold',
  background: isDragging ? '#3F51B5' : '#DBEEDA',

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? 'lightblue' : 'white',
  padding: grid,
  // width: columnsListWidth,
});

function ToggleVisibilityButton(props) {
  const label = props.isVisible ? 'Schovat' : 'Zobrazit';
  return (
    <Button
      type="text"
      size="small"
      onClick={props.onToggle}
      icon={(
        props.isVisible ? (
          <EyeFilled style={{ color: '#003063', fontSize: 18 }} />
        ) : (
          <EyeInvisibleFilled style={{ color: '#ccc', fontSize: 18 }} />
        )
      )}
      title={label}
    />
  );
}

class ConfigureTableColumns extends React.Component {
  onVisibleToggle = (item) => () => {
    this.props.toggleColumnVisibility(item.id);
  };

  // onExportToggle = item => () => {};

  onDragEnd = (result) => {
    // cancel or no droppable destination, respectively
    if (result.reason !== 'DROP' || !result.destination) {
      return;
    }
    const dropIdx = result.destination.index;
    if (!dropIdx && dropIdx !== 0) {
      return;
    }
    const itemId = result.draggableId;
    this.props.changeColumnOrder(itemId, dropIdx);
  };

  render() {
    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
              {this.props.columns.map((item, index) => {
                const id = item.id;
                const isVisible = item.isVisible;
                return (
                  <Draggable key={id} draggableId={id} index={index}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                      >
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <div style={{ display: 'flex', width: '8%' }}>
                            <ToggleVisibilityButton
                              isVisible={isVisible}
                              onToggle={this.onVisibleToggle(item)}
                            />
                            <div style={{ marginRight: 2 }} />
                          </div>
                          <div style={{ margin: '0 4px' }} />
                          <div style={{ width: '85%' }}>{item.content}</div>
                        </div>
                      </div>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  }
}

// type LocalProps = {|
//   // NotePrototype(simon): add types
//   columns: Array<any>,
//   changeColumnOrder: (colName: string, idx: number) => any,
//   toggleColumnVisibility: (colName: string) => any,
//   hasChanged: boolean,

//   onSaveConfig: *,
//   didSaveOk: boolean,
// |};

// type LocalState = {| columns: * |};

class ConfigureTableView extends React.Component {
  state = { columns: [] };

  static getDerivedStateFromProps(props) {
    const columns = props.columns.map((c) => ({
      id: c.accessor,
      content: c.Header,
      isVisible: !!c.isVisible,
    }));
    return { columns };
  }

  renderRightColumn() {
    const { hasChanged, didSaveOk } = this.props;
    return (
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
        <div>
          <div>
            Změňte <strong>pořadí sloupců</strong> táhnutím myši.
          </div>

          <div>
            <div style={{ margin: '14px 0' }} />
            <div>
              <strong>Zobrazení sloupce</strong>
            </div>
            <div>
              Zobrazovaný sloupec schováte kliknutím na{' '}
              <ToggleVisibilityButton isVisible={true} onToggle={doNothing} />.
            </div>
            <div>
              Schovaný sloupec zobrazíte kliknutím na{' '}
              <ToggleVisibilityButton isVisible={false} onToggle={doNothing} />.
            </div>
          </div>
        </div>
        <div>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <p>
              {didSaveOk
                ? 'Změny byly uloženy.'
                : hasChanged
                ? 'Provedli jste změny. Uložte je kliknutím na tlačítko:'
                : 'Neproběhly žádné změny.'}
            </p>
            <Button
              type="primary"
              shape="round"
              disabled={!hasChanged}
              onClick={this.props.onSaveConfig}
            >
              Uložit změny
            </Button>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div style={{ padding: '10px' }}>
        <h2>Nastavení tabulky</h2>
        <div>
          <div style={{ display: 'flex' }}>
            <div style={{ width: columnsListWidth, maxHeight: "80vh", overflowY: "scroll" }}>
              <ConfigureTableColumns
                columns={this.state.columns}
                changeColumnOrder={this.props.changeColumnOrder}
                toggleColumnVisibility={this.props.toggleColumnVisibility}
              />
            </div>
            <div style={{ margin: '0 14px' }} />

            {this.renderRightColumn()}
          </div>
        </div>
      </div>
    );
  }
}

export default ConfigureTableView;
