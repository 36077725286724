import { Fragment, useState, useEffect } from 'react';
import { message, Form, Button, Card } from 'antd';

import { FormField, CloseButton } from '@components';

import { useModal } from '@hooks';

import { getOptions, getById, requiredRule, maxRule, regexRule } from '@utils';

import CreateTestsFromGroup from './CreateTestsFromGroup';

const getConstructionTestOptions = (
  tableOptions: any,
  entry_type?: string,
  // test_type?: number,
) => {
  let options = [];
  if (entry_type === 'zkouska_v_terenu') {
    options = tableOptions.construction_test;
    // if (test_type) {
    //   options = options.filter((option: any) => {
    //     return option.test_type === test_type;
    //   });
    // }
  } else if (entry_type === 'odber_vzorku') {
    options = tableOptions.sampling_type;
  }
  return getOptions(options);
}

const getConstructionPartOptions = (tableOptions: any, construction_test?: string) => {
  let options = tableOptions.construction_part;
  if (construction_test) {
    options = options.filter((option: any) => {
      return (
        !option.construction_tests ||
        option.construction_tests.length === 0 ||
        option.construction_tests.indexOf(
          parseInt(construction_test, 10)
        ) > -1
      );
    });
  }
  options = options.map((option: any) => ({
    ...option,
    name: option.select_name || option.name,
  }));
  return getOptions(options);
}

const getTechnologyPartOptions = (tableOptions: any, construction_part?: number) => {
  let options = [];
  if (construction_part) {
    options = tableOptions.technology_part.filter((option: any) => {
      return option.parent === construction_part;
    });
  }
  return getOptions(options);
}

const AddRoadTestForm = ({
  freeMode,
  lastRoadTestFields,
  onFormSubmit,
  tableOptions,
  setModeToNull,
}: any) => {
  const [form] = Form.useForm();

  const [stationingFormat, setStationingFormat] = useState(null);
  const [constructionTestOptions, setConstructionTestOptions] = useState([]);
  const [constructionPartOptions, setConstructionPartOptions] = useState([]);
  const [technologyPartOptions, setTechnologyPartOptions] = useState([]);
  const [isCreatingFromGroup, setIsCreatingFromGroup] = useState(false);
  const [adjustedTests, setAdjustedTests] = useState(null);
  const [hasConstructionTestGroup, setHasConstructionTestGroup] = useState(false);

  const [
    openGroupTestsModal,
    closeGroupTestsModal,
    renderGroupTestsModal,
  ] = useModal(
    CreateTestsFromGroup,
    { width: 1000 },
  );

  const onFinish = async () => {
    try {
      await form.validateFields();

      let fieldValues = form.getFieldsValue();

      if (isCreatingFromGroup) {
        const targetGroup = getById(
          tableOptions.construction_test_group,
          fieldValues.construction_test_group,
        );
        fieldValues = {
          ...fieldValues,
          is_creating_from_group: true,
          construction_tests: adjustedTests ? (
            adjustedTests.map((_: any) => _.test)
          ) : (
            targetGroup.tests.map((_: any) => _.id)
          ),
        };
      }

      onFormSubmit(fieldValues);
    } catch (error) {
      console.error(error);
      message.error('Opravte prosím chyby ve formuláři');
    }
  }

  const calculateConstructionTestOptions = () => {
    setConstructionTestOptions(
      getConstructionTestOptions(
        tableOptions,
        form.getFieldValue('entry_type'),
        // form.getFieldValue('test_type'),
      )
    );
  }

  const calculateConstructionPartOptions = () => {
    setConstructionPartOptions(
      getConstructionPartOptions(
        tableOptions,
        form.getFieldValue('construction_test'),
      )
    );
  }

  const calculateTechnologyPartOptions = () => {
    setTechnologyPartOptions(
      getTechnologyPartOptions(
        tableOptions,
        form.getFieldValue('construction_part')
      )
    );
  }

  const calculateAllOptions = () => {
    calculateConstructionTestOptions();
    calculateConstructionPartOptions();
    calculateTechnologyPartOptions();
  }

  useEffect(() => {
    calculateAllOptions();
  }, []);

  const onLoadLastValues = () => {
    form.setFieldsValue(lastRoadTestFields);
    calculateAllOptions();
    if (lastRoadTestFields.is_creating_from_group) {
      setIsCreatingFromGroup(true);
      setHasConstructionTestGroup(true);
    }
    setStationingFormat(lastRoadTestFields.stationing_format);
  }

  const onCreateTestsFromGroupSubmit = (tests: any) => {
    closeGroupTestsModal();
    setAdjustedTests(tests);
  }

  const stationingFormatOption = getById(
    tableOptions.stationing_format,
    form.getFieldValue('stationing_format'),
  );

  return (
    <div style={{ padding: "1rem 0", margin: "0 auto", width: "90%" }}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div></div>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <p style={{ paddingTop: 5 }}>
                Právě vytváříte <b>nový záznam zkoušky</b>
              </p>
              <p
                onClick={() => {
                  setIsCreatingFromGroup(!isCreatingFromGroup);
                  setHasConstructionTestGroup(false);
                  setAdjustedTests(null);
                  form.resetFields();
                  calculateAllOptions();
                }}
                style={{ cursor: 'pointer', textDecoration: 'underline' }}
              >
                {isCreatingFromGroup
                  ? 'Vytvořit pouze jednu zkoušku'
                  : 'Vytvořit ze skupiny zkoušek'}
              </p>
            </div>
            <Card>
              <div style={{ width: 600 }}>
                <Form
                  form={form}
                  autoComplete="off"
                  onValuesChange={(changedFields) => {
                    if ('entry_type' in changedFields) {
                      form.setFieldsValue({
                        construction_test: null,
                        // test_type: null,
                      });

                      calculateConstructionTestOptions();
                    }

                    // if ('test_type' in changedFields) {
                    //   form.setFieldsValue({ construction_test: null });
                    //   calculateConstructionTestOptions();
                    // }

                    if ('construction_test' in changedFields) {
                      form.setFieldsValue({ construction_part: null });
                      calculateConstructionPartOptions();
                    }

                    if ('construction_part' in changedFields) {
                      form.setFieldsValue({ technology_part: null });
                      calculateTechnologyPartOptions();
                    }

                    if ('stationing_format' in changedFields) {
                      setStationingFormat(changedFields['stationing_format']);
                      if (changedFields['stationing_format'] === 'no_value') {
                        form.setFieldsValue({ stationing: null });
                      }
                    }

                    if ('construction_test_group' in changedFields) {
                      if (changedFields['construction_test_group']) {
                        setHasConstructionTestGroup(true);
                      } else {
                        setHasConstructionTestGroup(false);
                      }
                    }
                  }}
                  onFinish={() => onFinish()}
                >
                  <div className="row2">
                    {!isCreatingFromGroup ? (
                      <Form.Item
                        className="form-item"
                        name="entry_type"
                        rules={[requiredRule]}
                      >
                        <FormField
                          type="select"
                          placeholder="Druh činnosti"
                          options={[{
                            label: 'Zkouška v terénu',
                            value: 'zkouska_v_terenu',
                          }, {
                            label: 'Odběr vzorku',
                            value: 'odber_vzorku',
                          }]}
                        />
                      </Form.Item>
                    ) : <div/>}
                    <Form.Item
                      className="form-item"
                      name="stationing_format"
                      rules={[requiredRule]}
                    >
                      <FormField
                        type="select"
                        placeholder="Formát staničení"
                        options={getOptions(tableOptions.stationing_format)}
                      />
                    </Form.Item>
                  </div>
                  <div className="row2">
                    {stationingFormat !== 'no_value' ? (
                      <Form.Item
                        className="form-item"
                        name="stationing"
                        rules={[
                          requiredRule,
                          maxRule(24),
                          ...(stationingFormatOption ? [
                            regexRule(
                              stationingFormatOption.regex,
                              stationingFormatOption.example,
                            )
                          ] : []),
                        ]}
                      >
                        <FormField
                          type="text"
                          placeholder="Staničení"
                        />
                      </Form.Item>
                    ) : <div/>}
                    <Form.Item
                      className="form-item"
                      name="object_label"
                      rules={[requiredRule]}
                    >
                      <FormField
                        type="select"
                        placeholder="Stavební objekt"
                        dropdownMatchSelectWidth={false}
                        options={getOptions(tableOptions.object_label)}
                      />
                    </Form.Item>
                  </div>
                  <div className="row2">
                    {!isCreatingFromGroup ? (
                      <Form.Item
                        className="form-item"
                        name="construction_test"
                        rules={[requiredRule]}
                      >
                        <FormField
                          type="select"
                          placeholder="Typ zkoušky"
                          options={constructionTestOptions}
                        />
                      </Form.Item>
                    ) : (
                      <Fragment>
                        <Form.Item
                          className="form-item"
                          name="construction_test_group"
                          rules={[requiredRule]}
                        >
                          <FormField
                            type="select"
                            placeholder="Skupina zkoušek"
                            options={getOptions(tableOptions.construction_test_group.filter(
                              (option: any) => option.zkouska_v_terenu
                            ))}
                          />
                        </Form.Item>
                        {hasConstructionTestGroup && (
                          <Button
                            type="primary"
                            shape="round"
                            onClick={() => {
                              const {
                                test_type,
                                construction_test,
                                construction_test_group,
                              } = tableOptions;
                              const targetGroup = construction_test_group.filter(
                                (_: any) => _.id === form.getFieldValue('construction_test_group')
                              )[0];
                              if (targetGroup) {
                                const label = 'Vytvořit zkoušky ze skupiny zkoušek ' + targetGroup.name;
                                openGroupTestsModal(label, {
                                  activeTests: adjustedTests || targetGroup.tests,
                                  test_types: test_type,
                                  construction_tests: construction_test,
                                  onSubmit: onCreateTestsFromGroupSubmit,
                                });
                              }
                            }}
                            style={{
                              margin: '25px 650px 0 0',
                              overflow: 'hidden',
                              position: 'absolute',
                              right: 0,
                            }}
                          >
                            Upravit skupinu zkoušek
                          </Button>
                        )}
                      </Fragment>
                    )}
                    <Form.Item
                      className="form-item"
                      name="construction_part"
                      rules={[requiredRule]}
                    >
                      <FormField
                        type="select"
                        placeholder="Technologický celek"
                        // dropdownMatchSelectWidth={true}
                        options={constructionPartOptions}
                      />
                    </Form.Item>
                  </div>
                  <div className="row2">
                    <Form.Item
                      className="form-item"
                      name="technology_part"
                    >
                      <FormField
                        type="select"
                        placeholder="Část technologického celku"
                        options={technologyPartOptions}
                      />
                    </Form.Item>
                  </div>
                  {freeMode && (
                    <div className="row2">
                      <Form.Item
                        className="form-item"
                        name="laboratory"
                        rules={[requiredRule]}
                      >
                        <FormField
                          type="select"
                          placeholder="Přidělená laboratoř"
                          options={getOptions(tableOptions.laboratory)}
                        />
                      </Form.Item>
                      <Form.Item
                        className="form-item"
                        name="date"
                        rules={[requiredRule]}
                      >
                        <FormField
                          type="date"
                          placeholder="Datum zkoušky / odběru"
                        />
                      </Form.Item>
                    </div>
                  )}
                  <div className="form-buttons">
                    <Button
                      shape="round"
                      disabled={!lastRoadTestFields}
                      onClick={() => onLoadLastValues()}
                    >
                      Poslední
                    </Button>
                    <Button
                      type="primary"
                      shape="round"
                      onClick={() => onFinish()}
                    >
                      Vytvořit
                    </Button>
                  </div>
                </Form>
              </div>
            </Card>
          </div>
        </div>
        <CloseButton
          tooltip="Zrušit"
          onClick={() => setModeToNull()}
        />
        {renderGroupTestsModal()}
      </div>
    </div>
  );
};

export default AddRoadTestForm;
