import React from 'react';
import { Button } from 'antd';

import {
  reverseString,
  getKZPSortedRows,
  getKZPAmountRestriction,
} from '@utils';

const KZPPhase3SectionRowColumnNames: Array<string> = [
  {
    name: 'Kontrolovaná vlastnost',
    bold: false,
  },
  {
    name: 'Předpis',
    bold: false,
  },
  {
    name: 'Požadovaný parametr',
    bold: true,
  },
  {
    name: 'Jednotky a požadovaná četnost',
    bold: false,
  },
  // {
  //   name: 'Požadovaná četnost',
  //   bold: false,
  // },
  {
    name: 'Zpřísňující parametr',
    bold: false,
  },
  {
    name: 'Počet měrných jednotek',
    bold: true,
  },
  {
    name: 'Počet požadovaných zkoušek',
    editable: false,
  },
  {
    name: 'Kontrolu provede',
    editable: true,
  },
];

const cellStyles = {
  borderBottom: '1px solid #ddd',
  borderLeft: '1px solid #ddd',
  lineHeight: '135%',
};

const headerCellStyles = {
  ...cellStyles,
  backgroundColor: '#eee',
  borderTop: '1px solid #ddd',
  color: '#222',
  fontWeight: '400',
  padding: 8,
  textAlign: 'left',
};

const rowCellStyles = {
  ...cellStyles,
  padding: 8,
};

const NUMBER_REG = new RegExp(/^\d+$/);
const calculateNumberOfConstructionTests = (amount, amountPerTest) =>
  Math.ceil(parseInt(amount, 10) / parseInt(amountPerTest, 10));

// type ComponentProps = {|
//   can_confirm_kzp: boolean,
//   kzp: Object,
//   selectedSections: Array<number>,
//   sections: Array<Object>,
//   authors: Array<Object>,
//   onSubmit: Function,
//   onConfirm: Function,
//   onGoBack: Function,
//   showConfirmButton: boolean,
//   readOnly: boolean,
// |};

// type ComponentState = {|
//   sectionsData: Object,
// |};

class KZPPhase3Form extends React.Component {
  constructor(props) {
    super(props);
    this.setupSectionsData(props.kzp, props.selectedSections, true);
  }

  setupSectionsData(kzp, selectedSections, constructor = false) {
    const sectionsData = {};
    selectedSections.forEach((sectionId) => {
      sectionsData[sectionId] = {};
    });

    kzp.section_row_data.forEach(
      ({
        id,
        section_row: { id: rowId, section, test },
        selected_unit_specification,
        amount_restrictions,
        amount,
        required_parameter,
        by,
      }) => {
        sectionsData[section.id][rowId] = {
          id,
          selectedUnitSpecification: selected_unit_specification || '',
          amount_restrictions,
          amount_restriction: getKZPAmountRestriction(amount_restrictions, selected_unit_specification),
          amount: amount || '',
          requiredParameter: required_parameter || '',
          by: by || '',
        };
      }
    );

    if (constructor) {
      this.state = { sectionsData };
    } else {
      this.setState({ sectionsData });
    }
  }

  // gets section title with parent titles displayed before
  // making use of reverseString because we are going up the tree
  getFullSectionTitle(id, reversedExistingTitle = '') {
    const { name, parent_section } = this.props.sections.filter((_) => _.id === id)[0];
    let currentTitle = reversedExistingTitle ? reversedExistingTitle + '\xa0\xa0>\xa0\xa0' : '';

    currentTitle += reverseString(name);
    if (parent_section) {
      return this.getFullSectionTitle(parent_section, currentTitle);
    } else {
      return reverseString(currentTitle);
    }
  }

  getSection(id) {
    const section = this.props.sections.filter((_) => _.id === id)[0];
    if (section) {
      return section;
    } else {
      return null;
    }
  }

  getPayload() {
    const { sectionsData } = this.state;
    let payload = [];

    // create array of objects (sectionRowDatas) that contain id and amount
    Object.values(sectionsData).forEach((section) => {
      payload = [...payload, ...Object.values(section)];
    });

    return payload.map(({ id, selectedUnitSpecification, amount_restriction, amount, requiredParameter, by }) => ({
      id,
      selected_unit_specification: selectedUnitSpecification || null,
      amount_restriction: amount_restriction || null,
      amount: amount || null,
      required_parameter: requiredParameter || null,
      by: by || null,
    }));
  }

  onSubmit = (show_status = true) => {
    const payload = this.getPayload();

    const result = this.props.onSubmit(payload, show_status);
    if (result) {
      result.then((data) => {
        this.setupSectionsData(data, this.props.selectedSections);
      });
    }
  };

  onConfirm = () => {
    const payload = this.getPayload();

    this.props.onConfirm(payload);
  };

  onSelectedUnitSpecificationChange(event, sectionId, rowId) {
    let { value: selectedUnitSpecification } = event.target;
    selectedUnitSpecification = parseInt(selectedUnitSpecification, 10);
    const { sectionsData } = this.state;
    const sectionRowData = sectionsData[sectionId][rowId];
    sectionRowData.selectedUnitSpecification = selectedUnitSpecification;
    sectionRowData.amount_restriction = getKZPAmountRestriction(
      sectionRowData.amount_restrictions,
      selectedUnitSpecification,
    );

    this.setState({ sectionsData });
  }

  onAmountRestrictionChange(event, sectionId, rowId) {
    const { value: amount_restriction } = event.target;
    const { sectionsData } = this.state;
    const sectionRowData = sectionsData[sectionId][rowId];

    if (!amount_restriction) {
      sectionRowData.amount_restriction = amount_restriction;
    } else if (NUMBER_REG.test(amount_restriction)) {
      sectionRowData.amount_restriction = amount_restriction;
    }

    this.setState({ sectionsData });
  }

  // onAmountChange(event, sectionId, rowId) {
  onAmountChange(event, sectionId) {
    const { value: amount } = event.target;
    const { sectionsData } = this.state;
    // const sectionRowData = sectionsData[sectionId][rowId];

    if (!amount || NUMBER_REG.test(amount)) {
      for (let rowId in sectionsData[sectionId]) {
        sectionsData[sectionId][rowId].amount = amount;
      }
      // sectionRowData.amount = amount;
    }

    this.setState({ sectionsData });
  }

  onRequiredParameterChange(event, sectionId, rowId) {
    const { value: requiredParameter } = event.target;
    const { sectionsData } = this.state;

    sectionsData[sectionId][rowId].requiredParameter = requiredParameter;
    this.setState({ sectionsData });
  }

  onByChange(event, sectionId, rowId) {
    const { value: by } = event.target;
    const { sectionsData } = this.state;

    sectionsData[sectionId][rowId].by = by;
    this.setState({ sectionsData });
  }

  renderSelectedUnitSpecificationInput(sectionId, rowId, options) {
    const {
      readOnly,
      kzp: { confirmed, version_number },
    } = this.props;
    const disabled = readOnly || confirmed || version_number > 1;

    return (
      <select
        className="kzp-amount-input"
        value={this.state.sectionsData[sectionId][rowId].selectedUnitSpecification}
        onChange={(event) => this.onSelectedUnitSpecificationChange(event, sectionId, rowId)}
        disabled={disabled}
      >
        {options.map(({ id, unit: { name }, amount_per_test }) => (
          <option value={id} key={id}>
            {name}, {amount_per_test}
          </option>
        ))}
      </select>
    );
  }

  renderAmountRestriction(sectionId, rowId) {
    return (
      <input
        className="kzp-amount-input"
        value={this.state.sectionsData[sectionId][rowId].amount_restriction}
        onChange={(event) => this.onAmountRestrictionChange(event, sectionId, rowId)}
        readOnly={this.props.readOnly}
      />
    );
  }

  renderAmountInput(sectionId, rowId) {
    return (
      <input
        className="kzp-amount-input"
        value={this.state.sectionsData[sectionId][rowId].amount}
        // onChange={(event) => this.onAmountChange(event, sectionId, rowId)}
        onChange={(event) => this.onAmountChange(event, sectionId)}
        readOnly={this.props.readOnly}
      />
    );
  }

  renderRequiredParameterInput(sectionId, rowId) {
    return (
      <input
        className="kzp-amount-input"
        value={this.state.sectionsData[sectionId][rowId].requiredParameter}
        onChange={(event) => this.onRequiredParameterChange(event, sectionId, rowId)}
        style={{ width: '110px' }}
        readOnly={this.props.readOnly}
      />
    );
  }

  renderByInput(sectionId, rowId) {
    return (
      <select
        className="kzp-amount-input"
        value={this.state.sectionsData[sectionId][rowId].by}
        onChange={(event) => this.onByChange(event, sectionId, rowId)}
        style={{ width: '110px' }}
        disabled={this.props.readOnly}
      >
        <option key={null} value={''}>
          -
        </option>
        {this.props.authors.map(({ id, username }) => (
          <option key={id} value={id}>
            {username}
          </option>
        ))}
      </select>
    );
  }

  renderSection(isFirst, section) {
    if (!section || section.sections.length !== 0) {
      return null;
    }

    const relevantRows = this.props.kzp.section_row_data
      .filter(({ section_row }) => section_row.section.id === section.id)
      .map(({ section_row }) => section_row);

    const { id: sectionId } = section;

    return (
      <div key={sectionId}>
        <h3 style={{ marginTop: isFirst ? 0 : 30, marginBottom: 7 }}>
          {this.getFullSectionTitle(sectionId)}
        </h3>
        {relevantRows.length === 0 ? (
          <p>K dané oblasti nejsou přiřazeny žádné zkoušky</p>
        ) : (
          <div className="edit-kzp-table">
            <table width="100%">
              <tbody>
                <tr>
                  <th style={headerCellStyles}>{''}</th>
                  {KZPPhase3SectionRowColumnNames.map(({ name, bold }, index) => {
                    const isLast = index + 1 === KZPPhase3SectionRowColumnNames.length;
                    return (
                      <th
                        key={name}
                        style={{
                          ...headerCellStyles,
                          borderRight: isLast && '1px solid #ddd',
                        }}
                      >
                        {!this.props.readOnly && bold ? <b>{name}</b> : name}
                      </th>
                    );
                  })}
                </tr>
                {getKZPSortedRows(relevantRows).map(
                  (
                    {
                      id: rowId,
                      test: { name, description, test_type, test_unit_specifications },
                      extra,
                    },
                    index
                  ) => {
                    const { amount_restriction, amount, selectedUnitSpecification } = this.state.sectionsData[
                      sectionId
                    ][rowId];
                    const unitSpecification = test_unit_specifications.filter(
                      (_) => _.id === selectedUnitSpecification
                    )[0];
                    const amountPerTest =
                      (unitSpecification && unitSpecification.amount_per_test) ||
                      test_unit_specifications[0].amount_per_test;
                    const numberOfConstructionTests = amount
                      ? calculateNumberOfConstructionTests(amount, amount_restriction || amountPerTest)
                      : '';

                    return (
                      <tr key={rowId}>
                        <td style={{ ...rowCellStyles, width: '2.5%' }}>{index + 1}.{extra ? '*' : ''}</td>
                        <td style={{ ...rowCellStyles, width: '17.5%' }}>{name}</td>
                        <td style={{ ...rowCellStyles, width: '20%' }}>{description}</td>
                        <td style={{ ...rowCellStyles, width: '10%' }}>
                          {this.renderRequiredParameterInput(sectionId, rowId)}
                        </td>
                        <td style={{ ...rowCellStyles, width: '10%' }}>
                          {this.renderSelectedUnitSpecificationInput(
                            sectionId,
                            rowId,
                            test_unit_specifications
                          )}
                        </td>
                        {/* <td style={{ ...rowCellStyles, width: '10%' }}>
                          {amountPerTest}
                        </td> */}
                        <td style={{ ...rowCellStyles, width: '5%' }}>
                          {this.renderAmountRestriction(sectionId, rowId)}
                        </td>
                        <td style={{ ...rowCellStyles, width: '5%' }}>
                          {this.renderAmountInput(sectionId, rowId)}
                        </td>
                        <td style={{ ...rowCellStyles, width: '10%' }}>
                          {numberOfConstructionTests}
                        </td>
                        <td
                          style={{
                            ...rowCellStyles,
                            width: '10%',
                            borderRight: '1px solid #ddd',
                          }}
                        >
                          {this.renderByInput(sectionId, rowId)}
                        </td>
                      </tr>
                    );
                  }
                )}
              </tbody>
            </table>
          </div>
        )}
      </div>
    );
  }

  renderSections() {
    const { selectedSections } = this.props;

    return (
      <div>
        {selectedSections.map((_, index) => {
          const isFirst = index === 0;
          return this.renderSection(isFirst, this.getSection(_));
        })}
      </div>
    );
  }

  render() {
    return (
      <div style={{ width: "100%" }}>
        {this.renderSections()}
        <div style={{ display: 'flex', justifyContent: 'space-between', margin: '1.5rem 0 0 0' }}>
          <Button
            type="primary"
            shape="round"
            onClick={this.props.onGoBack}
          >
            Zpět
          </Button>
          {!this.props.readOnly && (
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Button
                className="green-button"
                type="primary"
                shape="round"
                onClick={this.onSubmit}
              >
                Uložit
              </Button>
              <div style={{ margin: '0 7px' }} />
              {this.props.can_confirm_kzp && this.props.showConfirmButton && (
                <Button
                  className="green-button"
                  type="primary"
                  shape="round"
                  onClick={this.onConfirm}
                >
                  Potvrdit
                </Button>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default KZPPhase3Form;
