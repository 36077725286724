import { useLocation, useNavigate } from 'react-router-dom';
import { Tabs, Button } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';

import { useUser } from '@hooks';

import styles from './Header.module.scss';

const ROUTES = [
  '/view/roads',
  '/view/kzp',
  '/view/orders',
  '/view/mailrecords',
];

const Header = () => {
  const user = useUser();
  const location = useLocation();
  const navigate = useNavigate();

  let activeKey = null;
  ROUTES.forEach((ROUTE: string, index: number) => {
    if (location.pathname.indexOf(ROUTE) > -1) {
      activeKey = index.toString();
    }
  });

  const tabItems = [];
  if (user.has_roadtests_access) tabItems.push({ label: 'Kniha zkoušek', key: '0' });
  if (user.has_kzp_access && user.is_superuser) tabItems.push({ label: 'KZP', key: '1' });
  if (user.has_orders_access) tabItems.push({ label: 'Objednávky zkoušek', key: '2' });
  if (user.has_mailrecords_access) tabItems.push({ label: 'Kniha pošty', key: '3' });

  return (
    <div>
      <Tabs
        className="header-tabs"
        activeKey={activeKey}
        items={tabItems}
        onChange={(activeKey) => {
          navigate(ROUTES[parseInt(activeKey, 10)]);
        }}
        tabBarExtraContent={(
          <div>
            <Button
              className={styles.helpButton}
              icon={<QuestionCircleOutlined />}
              onClick={() => navigate('/view/help')}
              type="text"
            />
          </div>
        )}
      />
    </div>
  );
};

export default Header;
