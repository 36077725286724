import { Fragment, useState, useEffect } from 'react';
import {
  Button,
  Input,
  Select,
  DatePicker,
  TimePicker,
  Checkbox,
  InputNumber,
} from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import moment from 'moment';

import { useDebouncedCallback } from '@hooks';

import styles from './FormField.module.scss';

import {
  deepEqual, getByValue, filterOption as defaultFilterOption,
  toStandardDate, truncateIfNeeded,
  dayMonthYear, dayMonthYearHourMinuteSecond, hourMinuteSecond,
  integerFormatter as defaultIntegerFormatter,
  integerParser as defaultIntegerParser,
  floatFormatter as defaultFloatFormatter,
  floatParser as defaultFloatParser,
} from '@utils';

const { TextArea } = Input;

type FormFieldProps = {
  id?: string;
  style?: React.CSSProperties;
  type?: string;
  mode?: "multiple" | "tags";
  tagRender?: any;
  allowClear?: boolean;
  options?: OptionLabelValue[];
  getOptions?: Function;
  dropdownMatchSelectWidth?: boolean;
  onSearch?: (value: string) => void;
  maxLength?: number;
  min?: number;
  max?: number;
  step?: number;
  precision?: number;
  prefix?: string;
  postfix?: string;
  formatter?: () => void;
  parser?: () => void;
  label?: any;
  noLabel?: boolean;
  placeholder?: string;
  size?: any;
  minRows?: number;
  maxRows?: number;
  defaultValue?: any;
  value?: any;
  onChange?: any;
  readOnly?: boolean;
  copyable?: boolean;
  debounce?: number;
  marginBottom?: number;
};

const FormField = ({
  id,
  style = {},
  type,
  mode,
  tagRender,
  allowClear,
  options = [],
  getOptions,
  dropdownMatchSelectWidth = false,
  onSearch,
  maxLength,
  min = 0,
  max = 2147483647,
  step,
  precision,
  prefix,
  postfix,
  formatter,
  parser,
  label,
  noLabel,
  placeholder,
  size,
  minRows,
  maxRows,
  defaultValue,
  value,
  onChange: propsOnChange,
  readOnly,
  copyable,
  debounce = 0,
  marginBottom = 8,
}: FormFieldProps) => {
  let propValue = value;
  if (propValue === undefined) propValue = defaultValue;

  const [localValue, setLocalValue] = useState(propValue);

  useEffect(() => {
    if (!deepEqual(propValue, localValue)) {
      setLocalValue(propValue);
    }
  }, [propValue, localValue]);

  const debouncedOnChange = useDebouncedCallback(
    (...args) => propsOnChange(...args),
    debounce
  );

  // const onChange = readOnly ? () => {} : (debounce ? debouncedOnChange : propsOnChange);
  const onChange = (value: any, ...other: any) => {
    if (!readOnly) {
      setLocalValue(value);
      if (debounce) {
        debouncedOnChange(value, ...other);
      } else {
        propsOnChange(value, ...other);
      }
    }
  }

  options = (getOptions ? getOptions() : options).map((option: OptionLabelValue) => ({
    ...option,
    label: truncateIfNeeded(option.label),
    searchLabel: option.label,
  }));

  const getTextToCopy: any = {
    select: () => {
      if (mode === 'tags') {
        return localValue.join(', ');
      } else {
        let values;
        if (mode === 'multiple') {
          values = localValue;
        } else {
          values = [localValue];
        }
        return values.map((value: string|number) => {
          return getByValue(options, value)?.label;
        }).join(', ');
      }
    },
    date: () => dayMonthYear(localValue),
    time: () => hourMinuteSecond(localValue),
    datetime: () => dayMonthYearHourMinuteSecond(localValue),
    textarea: () => localValue,
    integer: () => integerFormatter(localValue.toString()),
    float: () => floatFormatter(localValue.toString()),
    default: () => localValue,
  };

  copyable = copyable && (type in getTextToCopy || !type);
  const onCopy = () => {
    if (localValue || localValue === 0) {
      const textToCopy = getTextToCopy[type || 'default']();
      navigator.clipboard.writeText(textToCopy);
    } else {
      navigator.clipboard.writeText('');
    }
  }

  const commonProps = {
    id,
    style,
    placeholder,
    value: localValue,
    defaultValue,
  };

  const integerFormatter = formatter ? formatter : defaultIntegerFormatter(postfix);
  const integerParser = parser ? parser : defaultIntegerParser();
  const floatFormatter = formatter ? formatter : defaultFloatFormatter(postfix);
  const floatParser = parser ? parser : defaultFloatParser(postfix);

  return (
    <div className={styles.container} style={{ marginBottom }}>
      {
        !noLabel && (
          <Fragment>
            <span className={styles.label}>{label || placeholder}</span>
            {copyable && (
              <Button
                icon={(
                  <CopyOutlined
                    style={{
                      color: '#999',
                      fontSize: 12,
                      transform: 'translateY(-1px)'
                    }}
                  />
                )}
                type="text"
                shape="circle"
                size="small"
                onClick={() => onCopy()}
                style={{ height: 20 }}
              />
            )}
          </Fragment>
        )
      }
      {
        type === "select" ? (
          <Select
            {...commonProps}
            mode={mode}
            allowClear={readOnly ? false : (typeof allowClear !== "undefined" ? allowClear : !!mode)}
            open={readOnly ? false : undefined}
            className={styles.select}
            onChange={(value, object) => onChange(value, object)}
            filterOption={defaultFilterOption}
            options={getByValue(options, localValue)?.hidden ? (
              options.map((_: any) => ({ ..._, hidden: false }))
            ) : options.filter((_: any) => !_.hidden)}
            tagRender={tagRender}
            showSearch={!readOnly}
            searchValue={readOnly ? null : undefined}
            removeIcon={readOnly ? null : undefined}
            dropdownMatchSelectWidth={dropdownMatchSelectWidth}
            onSearch={onSearch}
          />
        ) : type === "date" ? (
          <DatePicker
            {...commonProps}
            className={styles.date}
            allowClear={allowClear}
            format="DD. MM. YYYY"
            onChange={(value) => onChange(value ? toStandardDate(value) : null)}
            value={localValue ? moment(localValue) : localValue}
            defaultValue={defaultValue ? moment(defaultValue) : defaultValue}
            inputReadOnly={readOnly}
            open={readOnly ? false : undefined}
          />
        ) : type === "time" ? (
          <TimePicker
            {...commonProps}
            className={styles.time}
            allowClear={allowClear}
            // format="DD. MM. YYYY"
            onChange={(value) => onChange(value)}
            value={localValue ? moment(localValue) : localValue}
            defaultValue={defaultValue ? moment(defaultValue) : defaultValue}
            inputReadOnly={readOnly}
            open={readOnly ? false : undefined}
          />
        ) : type === "datetime" ? (
          <DatePicker
            {...commonProps}
            showTime={{ format: "HH:mm:ss" }}
            className={styles.date}
            allowClear={allowClear}
            format={["DD. MM. YYYY HH:mm:ss", "DD. MM. YYYY"]}
            onChange={(value) => onChange(value ? value : null)}
            value={localValue ? moment(localValue) : localValue}
            defaultValue={defaultValue ? moment(defaultValue) : defaultValue}
            inputReadOnly={readOnly}
            open={readOnly ? false : undefined}
          />
        ) : type === "textarea" ? (
          <TextArea
            {...commonProps}
            className={styles.textarea}
            size={size}
            // showCount={maxLength}
            maxLength={maxLength}
            autoSize={{
              minRows: minRows || 6,
              maxRows: maxRows || 10,
            }}
            spellCheck="false"
            onChange={(event) => onChange(event.target.value)}
            readOnly={readOnly}
          />
        ) : type === "checkbox" ? (
          <div>
            <Checkbox
              id={id}
              className={styles.checkbox}
              checked={value}
              // defaultChecked={defaultValue}
              onChange={(event) => onChange(event.target.checked)}
              disabled={readOnly}
            />
          </div>
        ) : type === "integer" ? (
          <InputNumber
            {...commonProps}
            className={styles.number}
            size={size}
            min={min}
            max={max}
            step={step}
            precision={0.01}
            prefix={prefix}
            formatter={integerFormatter}
            parser={integerParser}
            decimalSeparator={'\x0d'}
            spellCheck="false"
            onChange={(value) => onChange(value)}
            readOnly={readOnly}
          />
        ) : type === "float" ? (
          <InputNumber
            {...commonProps}
            className={styles.number}
            size={size}
            min={min}
            max={max}
            step={step}
            precision={precision}
            prefix={prefix}
            formatter={floatFormatter}
            parser={floatParser}
            spellCheck="false"
            onChange={(value) => onChange(value)}
            readOnly={readOnly}
          />
        ) : (
          <Input
            {...commonProps}
            className={styles.input}
            size={size}
            maxLength={maxLength}
            spellCheck="false"
            onChange={(event) => onChange(event.target.value)}
            readOnly={readOnly}
          />
        )
      }
    </div>
  );
};

export default FormField;
