import { Fragment } from 'react';

import { ExportFile } from '@components';

type ExportTableProps = {
  onExport: Function;
  allowExcel?: boolean;
};

const ExportTable = ({ onExport, allowExcel = false }: ExportTableProps) => {
  return (
    <Fragment>
      <ExportFile
        buttonText="Vytvořit CSV soubor"
        onExport={() => onExport('csv')}
      />
      {
        allowExcel && (
          <div style={{ marginTop: 10 }}>
            <ExportFile
              buttonText="Vytvořit XLSX soubor"
              onExport={() => onExport('xlsx')}
            />
          </div>
        )
      }
    </Fragment>
  );
};

export default ExportTable;
