import { useState, useRef, useEffect } from 'react';
import { Typography, Image, Popconfirm, Modal } from 'antd';
import {
  EyeOutlined,
  DownloadOutlined,
  DeleteOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';

import { useEventListener } from '@hooks';

import styles from './FileItem.module.scss';

import { isImage, isPDF, getFileIcon } from '@utils';

const { Text } = Typography;

type FileItemProps = {
  file: RoadTestsFile;
  margin?: string;
  onRemove?: (file: RoadTestsFile) => void;
  getAction?: (file: RoadTestsFile) => React.ReactNode;
  files?: RoadTestsFile[],
};

const FileItem = ({
  file,
  margin,
  onRemove,
  getAction,
  files = [],
}: FileItemProps) => {
  const [hoverVisible, setHoverVisible] = useState(false);
  const [preview, setPreview] = useState(null);
  const [pdfPreview, setPdfPreview] = useState(null);
  const [refVisible, setRefVisible] = useState(false);
  const container = useRef(null);

  // This logic makes sure
  // event listeners work after first render
  useEffect(() => {
    if (!refVisible) {
      return;
    }
  }, [refVisible]);

  const onMouseEnter = () => {
    setHoverVisible(true);
  }

  const onMouseLeave = () => {
    setHoverVisible(false);
  }

  useEventListener("mouseenter", onMouseEnter, container.current);
  useEventListener("mouseleave", onMouseLeave, container.current);

  const { id, url, name } = file;
  const FileIcon = getFileIcon(name);

  const fileURL = url;
  const downloadURL = url;

  const allImages = files.filter(({ url }) => {
    return isImage(url);
  });

  const previewPreviousImage = () => {
    const index = allImages.map(_ => _.id).indexOf(preview.id);
    let target = allImages[allImages.length-1];
    if (index > 0) {
      target = allImages[index - 1];
    }
    setPreview(target);
  }

  const previewNextImage = () => {
    const index = allImages.map(_ => _.id).indexOf(preview.id);
    let target = allImages[0];
    if (index < allImages.length - 1) {
      target = allImages[index + 1];
    }
    setPreview(target);
  }

  useEventListener('keydown', (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (!!preview) {
      if (event.key === "ArrowLeft") {
        previewPreviousImage();
      }
      if (event.key === "ArrowRight") {
        previewNextImage();
      }
    }
  });

  return (
    <div
      className={styles.container}
      style={{ margin }}
    >
      <div
        className={styles.hoverContainer}
        ref={(element) => {
          if (!container.current) {
            container.current = element;
            setRefVisible(!!element);
          }
        }}
      >
        <div
          className={`${styles.hover} ${hoverVisible ? styles.hoverVisible : ""}`}
          onClick={() => {
            if (getAction) return;
            if (isImage(fileURL)) {
              setPreview(file);
            }
            if (isPDF(fileURL)) {
              setPdfPreview(file);
            }
          }}
        >
          {
            getAction ? getAction(file) : (
              (isImage(fileURL) || isPDF(fileURL)) && (
                <div
                  className={styles.preview}
                  title="Preview file"
                >
                  <EyeOutlined className={styles.previewIcon} />
                  Náhled
                </div>
              )
            )
          }
          <a
            href={downloadURL}
            download
            target="_blank"
            // rel="noopener noreferrer"
            title="Stáhnout soubor"
            onClick={(event) => event.stopPropagation()}
          >
            <div className={styles.download}>
              <DownloadOutlined className={styles.downloadIcon} />
              {/* Download */}
            </div>
          </a>
          <div onClick={(event) => event.stopPropagation()}>
            {
              onRemove && (
                <Popconfirm
                  title="Opravdu chcete odstranit tento soubor?"
                  okText="Ano"
                  cancelText="Ne"
                  icon={<QuestionCircleOutlined className={styles.popIcon} />}
                  placement="right"
                  onConfirm={() => onRemove(file)}
                >
                  <div
                    className={styles.remove}
                    title="Odstranit soubor"
                  >
                    <DeleteOutlined className={styles.removeIcon} />
                    {/* Remove */}
                  </div>
                </Popconfirm>
              )
            }
          </div>
        </div>
        {
          isImage(fileURL) ? (
            <div key={id} className={styles.imageItem}>
              <Image
                preview={false}
                className={styles.image}
                src={fileURL}
              />
            </div>
          ) : (
            <div className={styles.fileItem}>
              <FileIcon
                className={styles.fileIcon}
              />
              <span className={styles.fileName}>
                {name}
              </span>
            </div>
          )
        }
      </div>
      <div>
        <Text
          className={styles.note}
          ellipsis={{
            tooltip: file.name,
          }}
        >
          {file.name || '-'}
        </Text>
      </div>
      <Image
        className={styles.previewImage}
        // src={null}
        preview={{
          visible: preview && isImage(preview.url),
          src: preview && preview.url,
          onVisibleChange: (value) => setPreview(value),
        }}
      />
      <Modal
        open={pdfPreview}
        title="Náhled PDF souboru"
        onCancel={() => setPdfPreview(false)}
        footer={null}
        destroyOnClose
        bodyStyle={{ padding: '10px 10px 3px 10px' }}
        width={1020}
      >
        <embed src={file.url} width="1000" height="600" />
      </Modal>
    </div>
  );
};

export default FileItem;
