import { useState } from 'react';
import moment from 'moment';
import { message, Form, Button } from 'antd';

import { FormField } from '@components';

import { getOptions, requiredRule } from '@utils';

const getConstructionTestOptions = (tableOptions: any, entry_type?: string) => {
  let options = [];
  if (entry_type === 'zkouska_v_terenu') {
    options = tableOptions.construction_test;
  } else if (entry_type === 'odber_vzorku') {
    options = tableOptions.sampling_type;
  }
  return getOptions(options);
}

const AddSimpleOrderForm = ({
  initialValues,
  lastOrderFields,
  onFormSubmit,
  tableOptions,
}: any) => {
  const [form] = Form.useForm();

  const [constructionTestOptions, setConstructionTestOptions] = useState([]);

  const onFinish = async () => {
    try {
      await form.validateFields();

      const fieldValues = form.getFieldsValue();

      // Merge realization date and time values
      const realization_date = moment(fieldValues.realization_date);
      const realization_time = moment(fieldValues.realization_time);

      realization_date.set({
        hour: realization_time.hour(),
        minute: realization_time.minute(),
        second: realization_time.second(),
      });

      const payload = {
        ...fieldValues,
        technology_part: fieldValues.technology_part,
        realization_date: realization_date,
        realization_time: realization_date,
      };

      onFormSubmit(payload);
    } catch (error) {
      console.error(error);
      message.error('Opravte prosím chyby ve formuláři');
    }
  }

  const calculateConstructionTestOptions = () => {
    setConstructionTestOptions(
      getConstructionTestOptions(
        tableOptions,
        form.getFieldValue('entry_type'),
      )
    );
  }

  const onLoadLastValues = () => {
    form.setFieldsValue(lastOrderFields);
    calculateConstructionTestOptions();
  }

  return (
    <div style={{ width: 600 }}>
      <Form
        form={form}
        autoComplete="off"
        initialValues={{
          laboratory: tableOptions?.laboratory?.[0]?.id,
          ...initialValues,
        }}
        onValuesChange={(changedFields) => {
          if ('entry_type' in changedFields) {
            form.setFieldsValue({ construction_test: null });
            calculateConstructionTestOptions();
          }
        }}
        onFinish={() => onFinish()}
      >
        <div className="row2">
          <Form.Item
            className="form-item"
            name="object_label"
            rules={[requiredRule]}
          >
            <FormField
              type="select"
              placeholder="* Stavební objekt"
              dropdownMatchSelectWidth={true}
              options={getOptions(tableOptions.object_label)}
            />
          </Form.Item>
          <Form.Item
            className="form-item"
            name="stationing"
          >
            <FormField
              type="text"
              placeholder="Staničení"
            />
          </Form.Item>
        </div>
        <div className="row2">
          <Form.Item
            className="form-item"
            name="entry_type"
          >
            <FormField
              type="select"
              placeholder="Druh činnosti"
              options={[{
                label: 'Zkouška v terénu',
                value: 'zkouska_v_terenu',
              }, {
                label: 'Odběr vzorku',
                value: 'odber_vzorku',
              }]}
            />
          </Form.Item>
          <Form.Item
            className="form-item"
            name="construction_test"
          >
            <FormField
              type="select"
              placeholder="Typ zkoušky"
              options={constructionTestOptions}
            />
          </Form.Item>
        </div>
        <div className="row2">
          <Form.Item
            className="form-item"
            name="realization_date"
            rules={[requiredRule]}
          >
            <FormField
              type="date"
              placeholder="* Datum provedení"
            />
          </Form.Item>
          <Form.Item
            className="form-item"
            name="realization_time"
            rules={[requiredRule]}
          >
            <FormField
              type="time"
              placeholder="* Čas provedení"
            />
          </Form.Item>
        </div>
        <div className="row2">
          <Form.Item
            className="form-item"
            name="note"
          >
            <FormField
              type="text"
              placeholder="Poznámka"
            />
          </Form.Item>
          <Form.Item
            className="form-item"
            name="laboratory"
            rules={[requiredRule]}
          >
            <FormField
              type="select"
              placeholder="* Přidělená laboratoř"
              options={getOptions(tableOptions.laboratory)}
            />
          </Form.Item>
        </div>
        <div className="form-buttons">
          <Button
            shape="round"
            disabled={!lastOrderFields}
            onClick={() => onLoadLastValues()}
          >
            Poslední
          </Button>
          <Button
            type="primary"
            shape="round"
            onClick={() => onFinish()}
          >
            Vytvořit
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default AddSimpleOrderForm;
