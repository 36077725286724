import { message, Form, Button } from 'antd';

import { FormField } from '@components';

import { getOptions, requiredRule } from '@utils';

const KZPPhase1Form = ({
  initialValues,
  onFormSubmit,
  tableOptions,
  readOnly,
}: any) => {
  const [form] = Form.useForm();

  const onFinish = async () => {
    try {
      await form.validateFields();

      const fieldValues = form.getFieldsValue();

      onFormSubmit(fieldValues);
    } catch (error) {
      console.error(error);
      message.error('Opravte prosím chyby ve formuláři');
    }
  }

  return (
    <div style={{ width: 400, margin: '0 auto' }}>
      <Form
        form={form}
        autoComplete="off"
        initialValues={initialValues}
        onFinish={() => onFinish()}
      >
        <Form.Item
          className="form-item"
          name="object_label"
          rules={[requiredRule]}
        >
          <FormField
            type="select"
            placeholder="Stavební objekt"
            dropdownMatchSelectWidth={true}
            options={getOptions(tableOptions.object_label)}
            readOnly={readOnly}
          />
        </Form.Item>
        <Form.Item
          className="form-item"
          name="stationing"
        >
          <FormField
            type="text"
            placeholder="Staničení"
            readOnly={readOnly}
          />
        </Form.Item>
        <Form.Item
          className="form-item"
          name="construction_unit"
          rules={[requiredRule]}
        >
          <FormField
            type="select"
            placeholder="Konstrukční celek"
            dropdownMatchSelectWidth={true}
            options={getOptions(tableOptions.construction_unit)}
            readOnly={readOnly}
          />
        </Form.Item>
        <div className="form-buttons">
          <div />
          <Button
            type="primary"
            shape="round"
            onClick={() => onFinish()}
          >
            Uložit a pokračovat
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default KZPPhase1Form;
