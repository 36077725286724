import { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { Modal } from 'antd';

import { request, isValidId } from '@utils';

export const useConstructions = () => {
  const { isSuccess, data } = useQuery({
    queryKey: ['CONSTRUCTIONS'],
    queryFn: () => {
      return request('/api/constructions?desktop=1');
    },
  });

  return {
    isSuccess,
    constructions: data?.data?.results,
  }
};

export const useParamIdOrRedirect = (param: string = 'id') => {
  const navigate = useNavigate();
  const params = useParams();

  const valid = !params[param] || isValidId(params[param]);

  useEffect(() => {
    if (!valid) {
      navigate('/');
    }
  }, [valid, navigate]);

  return valid ? params[param] : null;
};

export const useModal = (ContentComponent: any, modalProps: any = {}): any => {
  const [open, setOpen] = useState(false);
  const title = useRef('');
  const contentPropsRef = useRef({});

  return [
    (modalTitle: string, contentProps: any) => {
      title.current = modalTitle;
      contentPropsRef.current = contentProps;
      setOpen(true);
    },
    () => setOpen(false),
    () => (
      <Modal
        open={open}
        title={title.current}
        onCancel={() => setOpen(false)}
        footer={null}
        destroyOnClose
        bodyStyle={{ padding: 20 }}
        {...modalProps}
      >
        <ContentComponent {...contentPropsRef.current} />
      </Modal>
    ),
  ];
}
