import { Component, Fragment } from 'react';
import Waypoint from 'react-waypoint';
import moment from 'moment';
import { message, Button, Modal, Input, Card } from 'antd';
import {
  PlusOutlined,
  ExportOutlined,
  EditFilled,
  CheckCircleFilled,
  CloseCircleFilled,
  DeleteFilled,
  ReloadOutlined,
  ToolFilled,
} from '@ant-design/icons';

import { CloseButton, ExportTable, ResizableVirtualTable } from '@components';

import { useModal } from '@hooks';

import {
  mapOptionsToOrders,
  getFilterOptions,
  updateById,
  dayMonthYearHourMinute,
  getFilterQuery,
  highlightTableRow,
} from '@utils';

import getTableColumns from './getTableColumns';
import {
  getOrders,
  getMoreOrders,
  getFilteredOrders,
  exportOrderData,
  addOrder,
  addSimpleOrder,
  editOrder,
  confirmOrder,
  denyOrder,
  closeOrder,
  stornoOrder,
  getOrderOptions,
} from './requests';

import AddOrderForm from './AddOrderForm';
import AddSimpleOrderForm from './AddSimpleOrderForm';
import EditOrderForm from './EditOrderForm';

class OrdersTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tableData: [],
      tableOptions: {},

      loadingTable: false,

      isFiltering: false,
      filteredData: [],

      isAddMode: false,
      isAddSimpleMode: false,
      isEditMode: false,

      orderToConfirm: null,
      orderToDeny: null,
      orderToClose: null,
      orderToStorno: null,

      tableKey: 1,
    };

    this.moreRowsUrl = '';
    this.moreFilterRowsUrl = '';
    this.permittedUserActions = {};
    this.addFormInitialValues = {};
    this.filters = {};
  }

  componentDidMount() {
    this.requestTableData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.constructionId !== this.props.constructionId) {
      this.setFiltersToNull();
      this.setModeToNull();
      this.requestTableData();
    }
  }

  requestTableData() {
    const { constructionId } = this.props;
    this.setState({ loadingTable: true });

    return Promise.all([
      getOrders(constructionId),
      getOrderOptions(constructionId),
    ]).then((responses) => {
      const [tableData, tableOptions] = responses;
      const { results, next, permitted_actions } = tableData;

      this.permittedUserActions = permitted_actions;
      this.moreRowsUrl = next;

      this.setState({
        tableData: mapOptionsToOrders(results, tableOptions),
        tableOptions,
        loadingTable: false,
      });
    });
  }

  loadMoreData = (isFiltered = false) => {
    if (
      (!isFiltered && !this.moreRowsUrl) ||
      (isFiltered && !this.moreFilterRowsUrl) ||
      this.isLoadingMoreData
    ) {
      return;
    }

    this.isLoadingMoreData = true;

    const onOk = ({ next, results }) => {
      this.isLoadingMoreData = false;
      if (!isFiltered) {
        this.moreRowsUrl = next;
        this.setState({
          tableData: this.state.tableData.concat(
            mapOptionsToOrders(results, this.state.tableOptions)
          ),
        });
      } else {
        this.moreFilterRowsUrl = next;
        this.setState({
          filteredData: this.state.filteredData.concat(
            mapOptionsToOrders(results, this.state.tableOptions)
          ),
        });
      }
    };

    const onErr = () => {
      this.isLoadingMoreData = false;
      message.error('Při načítání záznamů došlo k chybě');
    };

    if (!isFiltered) {
      getMoreOrders(this.moreRowsUrl).then(onOk, onErr);
    } else {
      getMoreOrders(this.moreFilterRowsUrl).then(onOk, onErr);
    }
  };

  fetchFilterData = () => {
    const query = getFilterQuery(this.filters);
    getFilteredOrders(this.props.constructionId, query).then(({ next, results }) => {
      const newFilteredData = mapOptionsToOrders(results, this.state.tableOptions);
      this.moreFilterRowsUrl = next;
      this.setState({
        filteredData: newFilteredData,
        isFiltering: true,
      });
    });
  }

  setModeToNull = (callback = () => {}) => {
    this.editingOrder = null;
    this.denialReason = '';
    this.stornoReason = '';
    this.addFormInitialValues = null;

    this.setState({
      isAddMode: false,
      isAddSimpleMode: false,
      isEditMode: false,
      orderToConfirm: null,
      orderToDeny: null,
      orderToClose: null,
      orderToStorno: null,
    }, callback);
  };

  setFiltersToNull = () => {
    this.filters = {};
    this.setState({
      ...this.state,
      tableKey: this.state.tableKey + 1,
      filteredData: [],
      isFiltering: false,
    });
  };

  setEditingOrder = (order) => {
    this.setModeToNull(() => {
      this.editingOrder = order;
      this.setState({ isEditMode: true });
    });
  };

  openExportModal = () => {
    this.props.openExportModal('Uložit report', {
      allowExcel: false,
      onExport: () => {
        const query = getFilterQuery(this.filters);
        return exportOrderData(this.props.constructionId, query);
      },
    });
  };

  updateOrderData = (data, updateEditingOrder = true) => {
    const updatedOrderData = mapOptionsToOrders(
      [data],
      this.state.tableOptions,
    )[0];
    const tableData = updateById(
      this.state.tableData,
      updatedOrderData.id,
      updatedOrderData
    );
    const filteredData = updateById(
      this.state.filteredData,
      updatedOrderData.id,
      updatedOrderData,
    );
    if (updateEditingOrder) {
      this.editingOrder = updatedOrderData;
    }
    this.setState({ tableData, filteredData });
    return updatedOrderData;
  }

  onAddFormSubmit = (fields) => {
    const { constructionId } = this.props;

    return addOrder(constructionId, fields).then(({ data }) => {
      localStorage.setItem('lastOrderFields', JSON.stringify(fields));
      this.requestTableData().then(() => {
        this.setModeToNull();
        message.success('Objednávka zkoušky byla vytvořena');
      });
    }).catch(() => {
      message.error('Při vytváření objednávky došlo k chybě');
    });
  };

  onAddSimpleFormSubmit = (fields) => {
    const { constructionId } = this.props;

    return addSimpleOrder(constructionId, fields).then(({ data }) => {
      localStorage.setItem('lastOrderFields', JSON.stringify(fields));
      this.requestTableData().then(() => {
        this.setModeToNull();
        message.success('Zjednodušená (avizo) objednávka zkoušky byla vytvořena');
      });
    }).catch(() => {
      message.error('Při vytváření objednávky došlo k chybě');
    });;
  }

  onEditFormSubmit = (fields) => {
    return editOrder(
      this.props.constructionId,
      this.editingOrder.id,
      fields,
    ).then(({ data }) => {
      const updatedOrderData = this.updateOrderData(data);
      message.success('Změny byly uloženy');
    }).catch(() => {
      message.error('Při editaci objednávky došlo k chybě');
    });
  };

  onOrderConfirm = () => {
    const { orderToConfirm } = this.state;

    confirmOrder(this.props.constructionId, orderToConfirm)
      .then(({ data }) => {
        this.setModeToNull(() => {
          const updatedOrderData = this.updateOrderData(data, false);
          message.success('Objednávka byla potvrzena');
        });
      }).catch(() => {
        this.setModeToNull();
        message.error('Při potvrzování objednávky došlo k chybě');
      });
  };

  onOrderDeny = () => {
    const {
      denialReason,
      state: { orderToDeny },
    } = this;

    if (!denialReason) {
      message.error('Je nutné uvést důvod zamítnutí');
      return;
    }

    denyOrder(this.props.constructionId, orderToDeny, denialReason)
      .then(({ data }) => {
        this.setModeToNull(() => {
          const updatedOrderData = this.updateOrderData(data, false);
          message.success('Objednávka byla zamítnuta');
        });
      }).catch(() => {
        this.setModeToNull();
        message.error('Při zamítání objednávky došlo k chybě');
      });
  };

  onOrderClose = () => {
    const { orderToClose } = this.state;

    if (orderToClose.simple) {
      return message.error('Objednávka musí mít vyplněná všechna povinná pole');
    }

    closeOrder(this.props.constructionId, orderToClose.id)
      .then(({ data }) => {
        this.setModeToNull(() => {
          const updatedOrderData = this.updateOrderData(data, false);
          message.success('Objednávka byla přebrána');
        });
      }).catch(() => {
        this.setModeToNull();
        message.error('Při přebírání objednávky došlo k chybě');
      });
  };

  onOrderStorno = () => {
    const {
      stornoReason,
      state: { orderToStorno },
    } = this;

    if (!stornoReason) {
      message.error('Je nutné uvést důvod stornování');
      return;
    }

    stornoOrder(this.props.constructionId, orderToStorno, stornoReason)
      .then(({ data }) => {
        this.setModeToNull(() => {
          const updatedOrderData = this.updateOrderData(data, false);
          message.success('Objednávka byla stornována');
        });
      }).catch(() => {
        this.setModeToNull();
        message.error('Při stornování objednávky došlo k chybě');
      });
  };

  onOrderRecreate = (order) => {
    if (
      this.state.isAddMode ||
      this.state.isAddSimpleMode ||
      this.state.isEditMode
    ) {
      return;
    }

    const initialValues = {
      ...order,
      object_label: order.object_label?.id,
      construction_test: order.construction_test?.id,
      construction_part: order.construction_part?.id,
      technology_part: order.technology_part?.id,
      realization_date: order.realization_time,
      laboratory: order.laboratory?.id,
    };

    this.addFormInitialValues = initialValues;
    this.setState({ isAddMode: true });
  }

  renderAddOrderForm() {
    const { tableOptions } = this.state;

    return (
      <AddOrderForm
        tableOptions={tableOptions}
        onFormSubmit={this.onAddFormSubmit}
        lastOrderFields={JSON.parse(localStorage.getItem('lastOrderFields'))}
        initialValues={this.addFormInitialValues}
        setModeToNull={() => this.setModeToNull()}
      />
    );
  }

  renderAddSimpleOrderForm() {
    const { tableOptions } = this.state;

    return (
      <div style={{ padding: "1rem 0", margin: "0 auto", width: "90%" }}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div></div>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <div>
              <p style={{ paddingTop: 5 }}>
                Právě vytváříte novou <b>zjednodušenou (avizo) objednávku zkoušky</b>
              </p>
              <Card>
                <AddSimpleOrderForm
                  tableOptions={tableOptions}
                  onFormSubmit={this.onAddSimpleFormSubmit}
                  lastOrderFields={JSON.parse(localStorage.getItem('lastOrderFields'))}
                />
              </Card>
            </div>
          </div>
          <CloseButton
            tooltip="Zrušit"
            onClick={() => this.setModeToNull()}
          />
        </div>
      </div>
    );
  }

  renderEditOrderForm() {
    if (!this.editingOrder) {
      return this.setModeToNull();
    }

    const { test_number, denied } = this.editingOrder;
    return (
      <div style={{ padding: "1rem 0", margin: "0 auto", width: "90%", maxWidth: 1200 }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <p style={{ paddingTop: 10 }}>
            Právě editujete <b>objednávku {test_number}</b>
          </p>

          <CloseButton
            tooltip="Zavřít editační okno"
            onClick={() => this.setModeToNull()}
          />
        </div>

        {denied &&
          (() => {
            const { denial_time, reason } = this.editingOrder.latest_denial;

            return (
              <div
                style={{
                  backgroundColor: 'rgb(255, 225, 225)',
                  marginBottom: '16px',
                  padding: '12px 14px',
                }}
              >
                Objednávka byla <b>{dayMonthYearHourMinute(denial_time)}</b> zamítnuta z následujícího důvodu: <b>{reason}</b>
              </div>
            );
          })()}

        <Card>
          <div style={{
            display: 'flex',
            justifyContent: 'flex-start',
            flexWrap: 'wrap',
            flexDirection: 'row',
            height: '100%',
            width: '100%',
          }}>
            <EditOrderForm
              tableOptions={this.state.tableOptions}
              onFormSubmit={this.onEditFormSubmit}
              simple={this.editingOrder.simple}
              initialValues={{
                ...this.editingOrder,
                object_label: this.editingOrder.object_label?.id,
                construction_test: this.editingOrder.construction_test?.id,
                construction_part: this.editingOrder.construction_part?.id,
                technology_part: this.editingOrder.technology_part?.id,
                realization_date: this.editingOrder?.realization_time,
                laboratory: this.editingOrder?.laboratory.id,
              }}
            />
          </div>
        </Card>
      </div>
    );
  }

  render() {
    const {
      isAddMode,
      isAddSimpleMode,
      isEditMode,
      orderToConfirm,
      orderToClose,
      orderToDeny,
      denialError,
      orderToStorno,
      stornoError,
      tableData,
      loadingTable,
    } = this.state;

    let backgroundColor = null;

    return (
      <div style={{ width: '100%', overflow: 'hidden' }}>
        <Modal
          title="Opravdu chcete objednávku zkoušky potvrdit?"
          open={!!orderToConfirm}
          cancelText="Ne"
          okText="Ano"
          onCancel={() => this.setModeToNull()}
          onOk={() => this.onOrderConfirm()}
          destroyOnClose
        >
          Po tomto kroku nebudete už moci objednávku zamítnout
        </Modal>

        <Modal
          title="Opravdu chcete objednávku zkoušky zamítnout?"
          open={!!orderToDeny}
          cancelText="Ne"
          okText="Ano"
          onCancel={() => this.setModeToNull()}
          onOk={() => this.onOrderDeny()}
          destroyOnClose
        >
          <p style={{ marginTop: 0, marginBottom: 14 }}>Uveďte prosím důvod zamítnutí</p>
          <Input.TextArea
            id="denialReason"
            rows={3}
            onChange={(event) => this.denialReason = event.target.value}
            style={{ width: '100%' }}
          />
        </Modal>

        <Modal
          title="Opravdu chcete objednávku zkoušky přebrat?"
          open={!!orderToClose}
          cancelText="Ne"
          okText="Ano"
          onCancel={() => this.setModeToNull()}
          onOk={() => this.onOrderClose()}
          destroyOnClose
        >
          Po tomto kroku nebudete už moci objednávku editovat
        </Modal>

        <Modal
          title="Opravdu chcete objednávku zkoušky stornovat?"
          open={!!orderToStorno}
          cancelText="Ne"
          okText="Ano"
          onCancel={() => this.setModeToNull()}
          onOk={() => this.onOrderStorno()}
          destroyOnClose
        >
          <p style={{ marginTop: 0, marginBottom: 14 }}>Uveďte prosím důvod stornování</p>
          <Input.TextArea
            id="stornoReason"
            rows={3}
            onChange={(event) => this.stornoReason = event.target.value}
            style={{ width: '100%' }}
          />
        </Modal>

        {isAddMode && this.renderAddOrderForm()}
        {isAddSimpleMode && this.renderAddSimpleOrderForm()}
        {isEditMode && this.renderEditOrderForm()}

        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          {this.state.isFiltering ? (
            <Button
              type="primary"
              shape="round"
              style={{ marginLeft: 20 }}
              onClick={() => this.setFiltersToNull()}
            >
              Zrušit filtrování
            </Button>
          ) : <div />}
          <div style={{ display: 'flex' }}>
            {(
              this.permittedUserActions.can_create_order &&
              !this.state.isAddMode &&
              !this.state.isAddSimpleMode &&
              !this.state.isEditMode
            ) && (
              <Fragment>
                {this.props.allowSimpleOrders && (
                  <Button
                    shape="circle"
                    size="large"
                    icon={<PlusOutlined />}
                    style={{ margin: 10, height: 54, width: 54 }}
                    onClick={() => this.setState({ isAddSimpleMode: true })}
                    title="Vytvořit avizo objednávku"
                  />
                )}
                <Button
                  type="primary"
                  shape="circle"
                  size="large"
                  icon={<PlusOutlined />}
                  style={{ margin: 10, height: 54, width: 54 }}
                  onClick={() => this.setState({ isAddMode: true })}
                  title="Vytvořit objednávku"
                />
              </Fragment>
            )}
            {this.permittedUserActions.can_export_order && (
              <Button
                type="primary"
                shape="circle"
                size="large"
                icon={<ExportOutlined />}
                style={{ margin: '10px 20px 10px 10px', height: 54, width: 54 }}
                onClick={() => this.openExportModal()}
                title="Uložit report"
              />
            )}
          </div>
        </div>

        {!loadingTable && (
          <ResizableVirtualTable
            key={this.state.tableKey}
            bordered
            pagination={false}
            scroll={{
              x: '100vw',
              y: this.state.isAddMode ? (
                'calc(100vh - 759px)'
              ) : this.state.isAddSimpleMode ? (
                'calc(100vh - 628px)'
              ) : this.state.isEditMode ? (
                'calc(100vh - 527px)'
              ) : 'calc(100vh - 204px)'
            }}
            dataSource={(
              this.state.isFiltering ? this.state.filteredData : this.state.tableData
            ).map((_ => ({ ..._, key: _.id })))}
            onChange={(_, filters: any) => {
              this.filters = filters;
              this.fetchFilterData();
            }}
            rowClassName={(row) => {
              const { id, realization_time, simple, confirmed, denied, closed, stornoed } = row;

              const now = moment();
              const parsed_time = moment(realization_time);
              const old = now.diff(parsed_time, 'days') >= 14;

              let className = `table-row-${id}`;

              if (simple) className += ' table-row-blue';
              else if (closed) className += ' table-row-green';
              else if (stornoed) className += ' table-row-grey';
              else if (old) className += ' table-row-faded';
              else if (confirmed) className += ' table-row-orange';
              else if (denied) className += ' table-row-red';

              return className;
            }}
            onRow={({ id }) => ({ onClick: event => highlightTableRow(event, id) })}
            columns={getTableColumns({
              isFiltering: this.state.isFiltering,
              moreRowsUrl: this.moreRowsUrl,
              moreFilterRowsUrl: this.moreFilterRowsUrl,
              tableOptions: this.state.tableOptions,
              permittedUserActions: this.permittedUserActions,
              isAdmin: this.props.isAdmin,
              tableData: this.state.tableData,
              filteredData: this.state.filteredData,
              loadMoreData: this.loadMoreData,
              setEditingOrder: this.setEditingOrder,
              setOrderToConfirm: id => this.setState({ orderToConfirm: id }),
              setOrderToDeny: id => this.setState({ orderToDeny: id }),
              setOrderToClose: order => this.setState({ orderToClose: order }),
              setOrderToStorno: id => this.setState({ orderToStorno: id }),
              onOrderRecreate: order => this.onOrderRecreate(order),
            })}
          />
        )}

        {this.props.renderExportModal()}
      </div>
    );
  }
}

export default (props) => {
  const [
    openExportModal,
    closeExportModal,
    renderExportModal,
  ] = useModal(
    ExportTable,
    { width: 400 },
  );

  return <OrdersTable
    {...props}
    openExportModal={openExportModal}
    closeExportModal={closeExportModal}
    renderExportModal={renderExportModal}
  />;
}
