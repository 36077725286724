import moment from 'moment';

import { dayMonthYear } from '@utils';

export const requiredRule = {
  required: true,
  message: 'Toto pole je povinné',
};

export const maxRule = (value: string|number, type: string = "string") => {
  const rule: any = {
    type,
    max: value,
    message: `Maximální délka je ${value}`,
  };

  return rule;
};

export const regexRule = (regex: string, example: string) => ({
  pattern: new RegExp(regex),
  message: `Např. ${example}`,
});

export const dateAfterRule = (date: any) => ({
  validator: (_: any, value: any) => {
    if (!date || !value) return Promise.resolve();
    const isBefore = moment(value).isBefore(date, 'days');
    if (isBefore) {
      return Promise.reject();
    } else {
      return Promise.resolve();
    }
  },
  message: `Nesmí být před ${dayMonthYear(date)}`,
});
