import { useRef } from 'react';
import { notification, Upload } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import { FileItem } from '@components';

import { useDebouncedCallback } from '@hooks';

import styles from './FileList.module.scss';

import { request, isImage, sortById } from '@utils';

type FileListProps = {
  actionURL: string;
  getFormData?: (formData: FormData) => FormData;
  files: RoadTestsFile[];
  onUpload?: (files: RoadTestsFile[]) => void;
  onRemove?: (file: RoadTestsFile) => void;
  getAction?: (file: RoadTestsFile) => React.ReactNode;
  label?: string;
  listType?: 'text' | 'picture' | 'picture-card';
  noItemRender?: boolean;
  uploadContent?: React.ReactNode;
  readOnly?: boolean;
};

const FileList = ({
  actionURL,
  getFormData,
  files,
  onUpload,
  onRemove,
  getAction,
  label = "",
  listType,
  noItemRender = false,
  uploadContent,
  readOnly = false,
}: FileListProps) => {
  files = files.map((file) => {
    file.url = file.file;
    return file
  });

  const filesToUpload = useRef([]);

  const performUpload = () => {
    const files = filesToUpload.current;
    if (files.length > 0) {
      const requests: any = [];
      files.forEach((file: string | Blob) => {
        let formData = new FormData();
        formData.append("file", file);
        if (getFormData) formData = getFormData(formData);
        requests.push(
          request(actionURL, {
            method: 'POST',
            data: formData,
          })
        );
      });
      Promise.all(requests).then((responses) => {
        const newFiles = responses.map(response => response.data);
        onUpload(newFiles);
        filesToUpload.current = [];
      }).catch(() => {
        notification.error({
          message: "An error has occurred while uploading experiment files",
          duration: 3,
        });
        filesToUpload.current = [];
      });
    }
  }

  // Need to use 'useDebouncedCallback' to handle multiple file uploads
  const performDebouncedUpload = useDebouncedCallback(() => {
    performUpload();
  }, 100);

  const uploadFile = (file: RoadTestsFile) => {
    filesToUpload.current = [...filesToUpload.current, file];
    performDebouncedUpload();
  }

  return (
    <>
      {
        label && (
          <span className={styles.label}>{label}</span>
        )
      }
      <Upload
        className={!noItemRender ? styles.container : ''}
        listType={!listType ? "picture-card" : listType}
        fileList={sortById(files).map((file: RoadTestsFile) => ({
          id: file.id,
          url: file.file,
          uid: file.id.toString(),
          name: file.name,
        }))}
        isImageUrl={(file: any) => isImage(file.url)}
        onRemove={!noItemRender ? undefined : (file: any) => onRemove(file)}
        itemRender={noItemRender ? undefined : (_, file: any) => {
          return (
            <FileItem
              file={file}
              onRemove={(!readOnly && onRemove) ? () => onRemove(file) : null}
              getAction={getAction}
              files={files}
            />
          );
        }}
        multiple
        customRequest={({ file }: any) => uploadFile(file)}
        disabled={readOnly}
      >
        {
          !readOnly && onUpload && (
            uploadContent || (
              <div style={{ width: 120 }}>
                <PlusOutlined />
                <p className={styles.uploadText}>Nahrát</p>
              </div>
            )
          )
        }
      </Upload>
    </>
  );
};

export default FileList;
