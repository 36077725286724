import { request } from '@utils';

export const getMailRecords = (constructionId: string) =>
  request(`/api/mailrecords/${constructionId}/data`).then((res) => res.data);

export const getMoreMailRecords = (url: string) => request(url).then((res) => res.data);

export const getFilteredMailRecords = (constructionId: string, query: string) =>
  request(`/api/mailrecords/${constructionId}/data?${query}`).then((res) => res.data);

export const addMailRecord = (constructionId: string, data: any) => {
  return request(`/api/mailrecords/${constructionId}/add/`, {
    data,
    method: 'post',
  }).then((res) => res.data);
};

export const editMailRecord = (constructionId: string, mailrecordId: string, data: any) => {
  return request(`/api/mailrecords/${constructionId}/edit/${mailrecordId}`, {
    data,
    method: 'post',
  }).then((res) => res.data);
};

export const commentMailRecord = (constructionId: string, mailrecordId: string, data: any) => {
  return request(`/api/mailrecords/${constructionId}/comment/${mailrecordId}`, {
    data,
    method: 'post',
  }).then((res) => res.data);
};

export const handoverMailRecord = (constructionId: string, mailrecordId: string, data: any) => {
  return request(`/api/mailrecords/${constructionId}/handover/${mailrecordId}`, {
    data,
    method: 'post',
  }).then((res) => res.data);
};

export const closeMailRecord = (constructionId: string, mailrecordId: string) => {
  return request(`/api/mailrecords/${constructionId}/close/${mailrecordId}`, {
    method: 'post',
  }).then((res) => res.data);
};

export const getMailRecordOptions = (constructionId: string) =>
  request(`/api/mailrecords/${constructionId}/tableoptions`).then((res) => res.data);
