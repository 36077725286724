import moment from 'moment';

export const toStandardDate = (date: any) => {
  return moment(date).format('YYYY-MM-DD');
};

export const toStandartDatetime = (date: any) => {
  return new Date(date).toISOString();
};

export const dayMonth = (date: any) => {
  if (!date) return '';
  return moment(date).format('DD. MM.');
};

export const dayMonthYear = (date: any) => {
  if (!date) return '';
  return moment(date).format('DD. MM. YYYY');
};

export const dayMonthYearHourMinute = (date: any) => {
  if (!date) return '';
  return moment(date).format('DD. MM. YYYY - HH:mm');
};

export const dayMonthYearHourMinuteSecond = (date: any) => {
  if (!date) return '';
  return moment(date).format('DD. MM. YYYY HH:mm:ss');
};

export const hourMinuteSecond = (date: any) => {
  if (!date) return '';
  return moment(date).format('HH:mm:ss');
};
