export const getOptions = (
  options: any = [],
  labelProp: string = "name",
  valueProp: string = "id",
) => {
  return options.map((option: any) => ({
    ...option,
    label: option[labelProp],
    title: option[labelProp],
    value: option[valueProp],
    used: undefined,
    valid: undefined,
  }));
};

export const getFilterOptions = (
  options: any = [],
  textProp: string = "name",
  valueProp: string = "id",
) => {
  return options.map((option: any) => ({
    ...option,
    text: option[textProp],
    title: option[textProp],
    value: option[valueProp],
    used: undefined,
    valid: undefined,
  }));
};

export const filterOption = (inputValue: any, option: any) => {
  const text = (option.searchLabel || option.label).toString().toLowerCase();
  return text.indexOf(inputValue.toLowerCase()) > -1;
};
