import { Component } from 'react';
import { message, Button, Modal, Card, Collapse } from 'antd';
import { PlusOutlined, ExportOutlined } from '@ant-design/icons';

import { CloseButton, ExportFile, ExportTable, ResizableVirtualTable } from '@components';

import { useModal } from '@hooks';

import {
  mapOptionsToData,
  getFilterOptions,
  updateById,
  updateByKZPId,
  dayMonthYearHourMinute,
  getFilterQuery,
  highlightTableRow,
  deepEqual,
} from '@utils';

import getTableColumns from './getTableColumns';
import {
  getKZPData,
  getMoreKZPData,
  getFilteredKZPData,
  exportKZPData,
  fetchKZPSectionRowData,
  fetchOlderKZPVersion,
  addKZP,
  editKZP,
  getOrCreateSectionRow,
  confirmKZP,
  closeKZP,
  exportKZP,
  exportKZPRecords,
  addSectionRowRecord,
  getKZPOptions,
} from './requests';

import KZPPhase1Form from './KZPPhase1Form';
import KZPPhase2Form from './KZPPhase2Form';
import KZPPhase3Form from './KZPPhase3Form';
import KZPAddRecordForm from './KZPAddRecordForm';

class KZPTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tableData: [],
      tableOptions: {},

      loadingTable: false,

      isFiltering: false,
      filteredData: [],

      isAddMode: false,
      isEditMode: false,
      isRecordMode: false,

      tableKey: 1,

      KZPState: null,
      KZPVersion: null,
      isKZPDialogOpen: false,
      cachedOlderKZPVersions: {},
    };

    this.moreRowsUrl = '';
    this.moreFilterRowsUrl = '';
    this.permittedUserActions = {};
    this.filters = {};
  }

  componentDidMount() {
    this.requestTableData();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.constructionId !== this.props.constructionId) {
      this.setFiltersToNull();
      this.setModeToNull();
      this.requestTableData();
    }

    if (prevState.KZPState !== this.state.KZPState) {
      window.scrollTo(0, 0);
    }
  }

  requestTableData(adding = false) {
    const { constructionId } = this.props;
    this.setState({ loadingTable: true });

    const promises = [getKZPData(constructionId)];
    if (!adding) promises.push(getKZPOptions(constructionId));

    return Promise.all(promises).then((responses) => {
      let [tableData, tableOptions] = responses;
      const { results, next, permitted_actions } = tableData;
      if (!tableOptions) tableOptions = this.state.tableOptions;

      this.permittedUserActions = permitted_actions;
      this.moreRowsUrl = next;

      const modifiedTableOptions = {
        ...tableOptions,
        section_row: tableOptions.section
          .map((_) => _.rows)
          .reduce((arr, val) => arr.concat(val, [])),
      };
      const modifiedData = mapOptionsToData(results, modifiedTableOptions);

      this.setState({
        tableData: modifiedData,
        tableOptions: modifiedTableOptions,
        loadingTable: false,
      });
    });
  }

  loadMoreData = (isFiltered = false) => {
    if (
      (!isFiltered && !this.moreRowsUrl) ||
      (isFiltered && !this.moreFilterRowsUrl) ||
      this.isLoadingMoreData
    ) {
      return;
    }

    this.isLoadingMoreData = true;

    const onOk = ({ next, results }) => {
      this.isLoadingMoreData = false;
      if (!isFiltered) {
        this.moreRowsUrl = next;
        this.setState({
          tableData: this.state.tableData.concat(
            mapOptionsToData(results, this.state.tableOptions)
          ),
        });
      } else {
        this.moreFilterRowsUrl = next;
        this.setState({
          filteredData: this.state.filteredData.concat(
            mapOptionsToData(results, this.state.tableOptions)
          ),
        });
      }
    };

    const onErr = () => {
      this.isLoadingMoreData = false;
      message.error('Při načítání záznamů došlo k chybě');
    };

    if (!isFiltered) {
      getMoreKZPData(this.moreRowsUrl).then(onOk, onErr);
    } else {
      getMoreKZPData(this.moreFilterRowsUrl).then(onOk, onErr);
    }
  };

  fetchFilterData = () => {
    const query = getFilterQuery(this.filters);
    getFilteredKZPData(this.props.constructionId, query)
      .then(({ next, results }) => {
        const newFilteredData = mapOptionsToData(results, this.state.tableOptions);
        this.moreFilterRowsUrl = next;
        this.setState({
          filteredData: newFilteredData,
          isFiltering: true,
        });
      });
  };

  setModeToNull = (callback = () => {}) => {
    this.editingKZP = {};
    this.setState(
      {
        isAddMode: false,
        isEditMode: false,
        isRecordMode: false,
        KZPState: null,
        KZPVersion: null,
      },
      callback
    );
  };

  setFiltersToNull = () => {
    this.filters = {};
    this.setState({
      ...this.state,
      tableKey: this.state.tableKey + 1,
      filteredData: [],
      isFiltering: false,
    });
  };

  fetchKZPSectionRowData(kzp) {
    const { id } = kzp;

    fetchKZPSectionRowData(id).then(({ data }) => {
      const withOptions = mapOptionsToData([data], this.state.tableOptions)[0];
      const kzp = this.prepareKZPForEdit(withOptions);
      const { id, section_row_data } = kzp;
      this.editingKZP.section_row_data = section_row_data;
      const tableData = updateById(this.state.tableData, id, kzp);
      const filteredData = updateById(this.state.tableData, id, kzp);
      this.setState({ tableData, filteredData });
    });
  }

  fetchOlderKZPVersion = (version_number) => {
    const { kzp_id } = this.editingKZP;

    fetchOlderKZPVersion(kzp_id, version_number).then(({ data }) => {
      const { cachedOlderKZPVersions, tableOptions } = this.state;

      if (!(kzp_id in cachedOlderKZPVersions)) {
        cachedOlderKZPVersions[kzp_id] = {};
      }

      cachedOlderKZPVersions[kzp_id][version_number] = mapOptionsToData(
        [data],
        tableOptions
      )[0];
      this.setState({ cachedOlderKZPVersions });
    });
  };

  getSectionRowById(id) {
    const { section_row } = this.state.tableOptions;
    return section_row.filter((r) => r.id === id)[0] || {};
  }

  getSelectedSectionsFor = (kzp) => {
    if (!kzp) {
      return [];
    }

    const { section_row_data } = kzp;
    // remove duplicates
    return [...new Set(section_row_data.map((_) => _.section_row.section.id))].sort();
  };

  prepareKZPForEdit(kzp) {
    if (!kzp.section_row_data) {
      this.fetchKZPSectionRowData(kzp);
      return { ...kzp, section_row_data: null };
    }

    return this.formatKZPForEdit(kzp);
  }

  formatKZPForEdit(kzp) {
    return {
      ...kzp,
      section_row_data: kzp.section_row_data.map((section_row_data) => {
        if (typeof section_row_data.section_row !== 'object') {
          return {
            ...section_row_data,
            section_row: this.getSectionRowById(section_row_data.section_row),
          };
        } else {
          return section_row_data;
        }
      }),
    };
  }

  setKZPLinkToPDF = (kzp, link) => {
    const tableData = updateById(
      this.state.tableData,
      kzp.id,
      { ...kzp, link_to_pdf: link },
    );

    this.setState({ tableData });
  };

  setKZPLinkToRecordsPDF = (kzp, link) => {
    const tableData = updateById(
      this.state.tableData,
      kzp.id,
      { ...kzp, link_to_records_pdf: link },
    );

    this.setState({ tableData });
  };

  setEditingKZP = (kzp, state = null) => {
    this.setModeToNull(() => {
      this.editingKZP = this.prepareKZPForEdit(kzp);

      const { has_any_section_row_data, version_number } = kzp;
      this.setState({
        isEditMode: true,
        KZPState: has_any_section_row_data ? (state || 3) : (state || 2),
        KZPVersion: version_number,
      });
    });
  };

  setKZPVersionToView(version_number, isActual) {
    const { cachedOlderKZPVersions } = this.state;
    const { kzp_id } = this.editingKZP;

    if (!version_number) {
      this.setState({ KZPVersion: null });
      return;
    }

    if (
      !isActual &&
      (!cachedOlderKZPVersions[kzp_id] || !cachedOlderKZPVersions[kzp_id][version_number])
    ) {
      this.fetchOlderKZPVersion(version_number);
    }

    this.setState({ KZPVersion: version_number });
  }

  setModeToAddRecords = (kzp) => {
    this.setModeToNull(() => {
      this.editingKZP = this.prepareKZPForEdit(kzp);
      this.setState({ isRecordMode: true });
    });
  };

  openExportModal = () => {
    this.props.openExportModal('Uložit report', {
      allowExcel: false,
      onExport: () => {
        const query = this.getFilterQuery();
        return exportKZPData(this.props.constructionId, query);
      },
    });
  };

  openPrintModal = (kzp) => {
    this.props.openPrintModal('Exportovat jako PDF', {
      buttonText: 'Vytvořit PDF soubor',
      onExport: () => {
        return exportKZP(kzp.id).then((data) => {
          this.setKZPLinkToPDF(kzp, data.link);
          return data;
        });
      },
      defaultExportLink: kzp.link_to_pdf,
    });
  };

  openPrintRecordsModal = () => {
    const kzp = this.editingKZP;
    this.props.openPrintRecordsModal('Exportovat jako PDF', {
      buttonText: 'Vytvořit PDF soubor',
      onExport: () => {
        return exportKZPRecords(kzp.id).then((data) => {
          this.setKZPLinkToRecordsPDF(kzp, data.link);
          return data;
        });
      },
      defaultExportLink: kzp.link_to_records_pdf,
    });
  };

  updateKZPData = (data, updateEditingKZP = true, callback) => {
    const updatedKZPData = mapOptionsToData(
      [data],
      this.state.tableOptions,
    )[0];
    const tableData = updateByKZPId(
      this.state.tableData,
      updatedKZPData.kzp_id,
      updatedKZPData
    );
    const filteredData = updateByKZPId(
      this.state.filteredData,
      updatedKZPData.kzp_id,
      updatedKZPData,
    );
    if (updateEditingKZP) {
      this.editingKZP = updatedKZPData;
    }
    this.setState({ tableData, filteredData }, callback);
    return updatedKZPData;
  }

  showDialog = () => {
    this.setState({ isKZPDialogOpen: true });
  };

  hideDialog = () => {
    this.setState({ isKZPDialogOpen: false });
  };

  onKZPPhase1FormSubmit = (fields) => {
    if (this.state.isAddMode) {
      return addKZP({
        construction: this.props.constructionId,
        ...fields,
      }).then(({ data }) => {
        this.requestTableData(true).then(() => {
          message.success('Kontrolně zkušební plán vytvořen');
          this.setEditingKZP(mapOptionsToData([data], this.state.tableOptions)[0], 2);
        });
      });
    } else {
      const {
        id,
        stationing,
        object_label,
        construction_unit,
        confirmed,
      } = this.editingKZP;

      const hasChanged =
        fields.stationing !== stationing ||
        fields.object_label !== object_label?.id ||
        fields.construction_unit !== construction_unit?.id;

      if (hasChanged) {
        const functionToBeExecuted = () => {
          return editKZP(
            id,
            {
              construction: this.props.constructionId,
              ...fields,
            },
            this.state.KZPState,
          ).then(({ data }) => {
            this.updateKZPData(data, true, () => {
              this.setState({ KZPState: 2 });
              message.success('Úspěšně uloženo');
            });
          });
        };

        if (!confirmed) {
          return functionToBeExecuted();
        } else {
          this.showDialog();
          // wait for dialog action
          this.editKZPFunction = functionToBeExecuted;
        }
      } else {
        this.setState({ KZPState: 2 });
      }
    }
  };

  onKZPPhase2FormSubmit = (sectionRowData) => {
    if (sectionRowData.length === 0) {
      message.error('Musíte mít vybranou alespoň jednu oblast se zkouškami');
      return;
    }

    const data1 = this.editingKZP.section_row_data.map(_ => _.section_row.id).sort();
    const data2 = sectionRowData.map(_ => _.section_row).sort();
    const hasChanged = !deepEqual(data1, data2);

    if (hasChanged) {
      const { id, confirmed } = this.editingKZP;

      const functionToBeExecuted = () => {
        return editKZP(id, { section_row_data: sectionRowData }, this.state.KZPState)
          .then(({ data }) => {
            this.updateKZPData(data, true, () => {
              this.setState({ KZPState: 3 });
              message.success('Úspěšně uloženo');
            });
          })
          .catch((error) => {
            console.error(error)
            message.error('Musíte mít vybranou alespoň jednu oblast se zkouškami')
          });
      };

      if (!confirmed) {
        return functionToBeExecuted();
      } else {
        this.showDialog();
        // wait for dialog action
        this.editKZPFunction = functionToBeExecuted;
      }
    } else {
      this.setState({ KZPState: 3 });
    }
  };

  onKZPPhase3FormSubmit = (sectionRowData, showStatus, checkForChange = true) => {
    // compare only the relevant fields
    const data1 = this.editingKZP.section_row_data.map(
      ({ id, selected_unit_specification, amount_restriction, amount, required_parameter, by }) => ({
        id,
        selected_unit_specification,
        amount_restriction,
        amount,
        required_parameter,
        by,
      })
    );
    const data2 = sectionRowData;

    const hasChanged = !deepEqual(data1, data2);

    if (hasChanged || !checkForChange) {
      const { id, confirmed } = this.editingKZP;
      let hasError = false;

      if (sectionRowData.length === 0) {
        hasError = true;
      } else {
        sectionRowData.forEach(({ amount }) => {
          if (!isNaN(parseInt(amount, 10))) {
            if (amount <= 0) {
              hasError = true;
            }
          }
        });
      }

      if (hasError) {
        message.error('Počet měrných jednotek musí být kladný');
        return new Promise((resolve, reject) => reject('Počet měrných jednotek musí být kladný'));
      } else {
        const functionToBeExecuted = () => {
          return editKZP(id, { section_row_data: sectionRowData }, this.state.KZPState)
            .then(({ data }) => {
              const newKZPData = this.updateKZPData(data, true, () => {
                if (showStatus) {
                  message.success('Úspěšně uloženo');
                }
              });
              return this.formatKZPForEdit(newKZPData);
            });
        };

        if (!confirmed) {
          return functionToBeExecuted();
        } else {
          this.showDialog();
          // wait for dialog action
          this.editKZPFunction = functionToBeExecuted;
        }
      }
    }
  };

  onDialogConfirm = () => {
    this.editKZPFunction().then(() => {
      this.hideDialog();
      this.setState({ KZPVersion: this.state.KZPVersion + 1 });
    });
    this.editKZPFunction = null;
  };

  onDialogDecline = () => {
    this.hideDialog();
    this.editKZPFunction = null;
  };

  onKZPConfirm = (sectionRowData) => {
    // Save before trying to confirm
    this.onKZPPhase3FormSubmit(sectionRowData, false, false).then(() => {
      let hasError = false;

      // simple client check
      const { id, section_row_data } = this.editingKZP;
      if (section_row_data.length === 0) {
        hasError = true;
      } else {
        section_row_data.forEach(({ amount }) => {
          if (!amount) {
            hasError = true;
          }
        });
      }

      if (hasError) {
        message.error('Zkontrolujte, že je u všech zkoušek vyplněno celkové množství');
      } else {
        return confirmKZP(id)
          .then(({ data }) => {
            this.updateKZPData(data, false, () => {
              this.setModeToNull(() => {
                message.success('KZP bylo úspěšně potvrzeno');
              });
            });
          })
          .catch((error) => {
            console.error(error);
            message.error('Zkontrolujte, že je u všech zkoušek vyplněno celkové množství');
          });
      }
    }).catch((err) => {});
  };

  onKZPClose = () => {
    let hasError = false;

    // simple client check
    const { id, section_row_data } = this.editingKZP;
    section_row_data.forEach(({ records }) => {
      if (records.length === 0) {
        hasError = true;
      }
    });

    if (hasError) {
      message.error('Zkontrolujte, zda je u všech zkoušek přidán alespoň jeden záznam');
    } else {
      return closeKZP(id)
        .then(({ data }) => {
          this.updateKZPData(data, false, () => {
            this.setModeToNull(() => {
              message.success('KZP bylo úspěšně vyhodnoceno');
            });
          });
        })
        .catch((error) => {
          console.error(error);
          message.error('Zkontrolujte, zda je u všech zkoušek přidán alespoň jeden záznam');
        });
    }
  };

  onKZPGoBack = () => {
    const { KZPState } = this.state;

    if (KZPState === 2) {
      this.setState({ KZPState: 1 });
    } else if (KZPState === 3) {
      this.setState({ KZPState: 2 });
    }
  };

  onAddNewRecord = (sectionRowDataId, amount) => {
    const data = {
      section_row_data: sectionRowDataId,
      current_amount: amount,
    };

    return addSectionRowRecord(this.editingKZP.id, data).then(({ data }) => {
      const updatedKZPData = mapOptionsToData(
        [data],
        this.state.tableOptions,
      )[0];
      const tableData = updateByKZPId(
        this.state.tableData,
        updatedKZPData.kzp_id,
        updatedKZPData
      );
      const filteredData = updateByKZPId(
        this.state.filteredData,
        updatedKZPData.kzp_id,
        updatedKZPData,
      );

      this.editingKZP = this.prepareKZPForEdit(updatedKZPData);
      this.setState({ tableData, filteredData }, () => {
        message.success('Záznam byl úspěšně přidán');
      });
    });
  };

  renderAddOrEditKZPFormContent(version_number, isActual = true) {
    const { isAddMode, isEditMode, KZPState, tableOptions, cachedOlderKZPVersions } = this.state;
    let data = this.editingKZP || {};
    const { kzp_id, confirmed, closed } = data;

    if (!isActual && !isAddMode) {
      if (cachedOlderKZPVersions[kzp_id] && cachedOlderKZPVersions[kzp_id][version_number]) {
        data = cachedOlderKZPVersions[kzp_id][version_number];
      } else {
        return <div></div>;
      }
    }

    const { can_edit_kzp, can_edit_confirmed_kzp } = this.permittedUserActions;
    const isReadOnly = closed || !isActual || (confirmed ? !can_edit_confirmed_kzp : !can_edit_kzp);

    if (isAddMode || (isEditMode && KZPState === 1)) {
      const onSubmit = isActual ? this.onKZPPhase1FormSubmit : () => this.setState({ KZPState: 2 });

      return (
        <Card>
          <KZPPhase1Form
            initialValues={{
              stationing: data?.stationing,
              object_label: data?.object_label?.id,
              construction_unit: data?.construction_unit?.id,
            }}
            onFormSubmit={onSubmit}
            tableOptions={tableOptions}
            readOnly={isReadOnly}
          />
        </Card>
      );
    }

    if (!data.section_row_data) {
      return <div></div>;
    }

    data = this.formatKZPForEdit(data);

    if (isEditMode && KZPState === 2) {
      const onSubmit = isActual ? this.onKZPPhase2FormSubmit : () => this.setState({ KZPState: 3 });
      return (
        <KZPPhase2Form
          id={data.id}
          kzp={data}
          constructionUnit={data.construction_unit}
          selectedSections={this.getSelectedSectionsFor(data)}
          series={tableOptions.series}
          sections={tableOptions.section}
          constructionTests={tableOptions.construction_test}
          getOrCreateSectionRow={getOrCreateSectionRow}
          onSubmit={onSubmit}
          onGoBack={this.onKZPGoBack}
          readOnly={isReadOnly}
        />
      );
    }

    if (isEditMode && KZPState === 3) {
      const onSubmit = isActual ? this.onKZPPhase3FormSubmit : () => {};
      const onConfirm = isActual ? this.onKZPConfirm : () => {};
      return (
        <KZPPhase3Form
          can_confirm_kzp={this.permittedUserActions.can_confirm_kzp}
          kzp={data}
          selectedSections={this.getSelectedSectionsFor(data)}
          sections={tableOptions.section}
          authors={tableOptions.author}
          onSubmit={onSubmit}
          onConfirm={onConfirm}
          onGoBack={this.onKZPGoBack}
          showConfirmButton={!data.confirmed}
          readOnly={isReadOnly}
        />
      );
    }
  }

  renderAddOrEditKZPForm() {
    const { isAddMode, KZPVersion } = this.state;
    if (!isAddMode && !this.editingKZP) this.setModeToNull();

    const { version_number, kzp_id, closed } = this.editingKZP || {};

    const versions = [];
    for (let i = 0; i < version_number; i++) {
      // we want the latest version to be first
      versions.push(version_number - i);
    }

    return (
      <div style={{ padding: "1rem 0", margin: "0 auto", width: "90%" }}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div />
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            width: !isAddMode ? '100%' : null,
          }}>
            {isAddMode ? (
              <p>Právě vytváříte nové KZP</p>
            ) : (
              <p>
                Právě {closed ? 'si prohlížíte' : 'editujete'} <b>KZP {kzp_id}</b>
              </p>
            )}
            {isAddMode
              ? this.renderAddOrEditKZPFormContent(version_number)
              : (
                <Collapse activeKey={KZPVersion}>
                  {
                    versions.map((version_number) => {
                      const isActual = version_number === versions.length;

                      return (
                        <Collapse.Panel
                          key={version_number}
                          header={(isActual
                            ? 'Verze ' + version_number + ' (aktuální)'
                            : 'Verze ' + version_number
                          )}
                          onClick={() => this.setKZPVersionToView(version_number, isActual)}
                        >
                          {this.renderAddOrEditKZPFormContent(version_number, isActual)}
                        </Collapse.Panel>
                      );
                    })
                  }
                </Collapse>
              )}
          </div>
          <CloseButton
            tooltip="Zrušit"
            onClick={() => this.setModeToNull()}
          />
        </div>
      </div>
    );
  }

  renderAddRecordsKZPForm() {
    if (!this.editingKZP) return;

    const { kzp_id, version_number, confirmed, closed, section_row_data } = this.editingKZP;

    if (!section_row_data) {
      return <div></div>;
    }

    if (!confirmed || section_row_data.length === 0) {
      return this.setModeToNull();
    }

    const sectionsData = {};
    this.getSelectedSectionsFor(this.editingKZP).forEach((sectionId) => {
      sectionsData[sectionId] = section_row_data.filter(
        ({ section_row: { section } }) => section.id === sectionId
      );
    });

    return (
      <div style={{ padding: "1rem 0", margin: "0 auto", width: "90%" }}>
        <p style={{ textAlign: 'center' }}>
          Právě {closed ? 'si prohlížíte' : 'provádíte'} kontrolu plnění plánu pro{' '}
          <b>KZP {kzp_id}</b>, verzi číslo {version_number}
        </p>
        <div style={{ display: 'flex' }}>
          <KZPAddRecordForm
            sectionsData={sectionsData}
            can_close_kzp={this.permittedUserActions.can_close_kzp}
            can_print_kzp={this.permittedUserActions.can_print_kzp}
            onSubmit={this.onAddNewRecord}
            onClose={this.onKZPClose}
            onPrintKZPRecords={() => this.openPrintRecordsModal()}
            isClosed={closed}
          />
          <div style={{ width: 20 }} />
          <CloseButton
            tooltip="Zrušit"
            onClick={() => this.setModeToNull()}
          />
        </div>
      </div>
    );
  }

  render() {
    const { isKZPDialogOpen, tableData, loadingTable } = this.state;

    return (
      <div style={{ width: '100%', overflow: 'hidden' }}>
        <Modal
          title="Opravdu chcete provést změny?"
          open={isKZPDialogOpen}
          cancelText="Ne"
          okText="Ano"
          onCancel={() => this.onDialogDecline()}
          onOk={() => this.onDialogConfirm()}
          destroyOnClose
        >
          Po tomto kroku bude původní KZP navždy zamraženo a vznikne nové nepotvrzené KZP
        </Modal>

        {(this.state.isAddMode || this.state.isEditMode) && this.renderAddOrEditKZPForm()}
        {this.state.isRecordMode && this.renderAddRecordsKZPForm()}

        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          {this.state.isFiltering ? (
            <Button
              type="primary"
              shape="round"
              style={{ marginLeft: 20 }}
              onClick={() => this.setFiltersToNull()}
            >
              Zrušit filtrování
            </Button>
          ) : <div />}
          <div style={{ display: 'flex' }}>
            {(
              this.permittedUserActions.can_create_kzp &&
              !this.state.isAddMode &&
              !this.state.isEditMode &&
              !this.state.isRecordMode
            ) && (
              <Button
                type="primary"
                shape="circle"
                size="large"
                icon={<PlusOutlined />}
                style={{ margin: 10, height: 54, width: 54 }}
                onClick={() => this.setState({ isAddMode: true })}
                title="Přidat"
              />
            )}
            {this.permittedUserActions.can_export_kzp && (
              <Button
                type="primary"
                shape="circle"
                size="large"
                icon={<ExportOutlined />}
                style={{ margin: '10px 20px 10px 10px', height: 54, width: 54 }}
                onClick={() => this.openExportModal()}
                title="Uložit report"
              />
            )}
          </div>
        </div>

        {!loadingTable && (
          <ResizableVirtualTable
            key={this.state.tableKey}
            bordered
            pagination={false}
            scroll={{
              x: '100vw',
              y: 'calc(100vh - 204px)',
            }}
            dataSource={(
              this.state.isFiltering ? this.state.filteredData : this.state.tableData
            ).map((_ => ({ ..._, key: _.id })))}
            onChange={(_, filters: any) => {
              this.filters = filters;
              this.fetchFilterData();
            }}
            rowClassName={(row) => {
              const { id, closed, has_passed } = row;

              let className = `table-row-${id}`;

              if (closed) {
                if (has_passed === true) {
                  className += ' table-row-green';
                } else if (has_passed === false) {
                  className += ' table-row-red';
                }
              }

              return className;
            }}
            onRow={({ id }) => ({ onClick: event => highlightTableRow(event, id) })}
            columns={getTableColumns({
              isFiltering: this.state.isFiltering,
              moreRowsUrl: this.moreRowsUrl,
              moreFilterRowsUrl: this.moreFilterRowsUrl,
              tableOptions: this.state.tableOptions,
              permittedUserActions: this.permittedUserActions,
              isAdmin: this.props.isAdmin,
              tableData: this.state.tableData,
              filteredData: this.state.filteredData,
              loadMoreData: this.loadMoreData,
              setEditingKZP: this.setEditingKZP,
              setModeToAddRecords: this.setModeToAddRecords,
              openPrintModal: this.openPrintModal,
            })}
          />
        )}

        {this.props.renderExportModal()}
        {this.props.renderPrintModal()}
        {this.props.renderPrintRecordsModal()}
      </div>
    );
  }
}

export default (props) => {
  const [
    openExportModal,
    closeExportModal,
    renderExportModal,
  ] = useModal(
    ExportTable,
    { width: 400 },
  );

  const [
    openPrintModal,
    closePrintModal,
    renderPrintModal,
  ] = useModal(
    ExportFile,
    { width: 400 },
  );

  const [
    openPrintRecordsModal,
    closePrintRecordsModal,
    renderPrintRecordsModal,
  ] = useModal(
    ExportFile,
    { width: 400 },
  );

  return (
    <KZPTable
      {...props}

      openExportModal={openExportModal}
      closeExportModal={closeExportModal}
      renderExportModal={renderExportModal}

      openPrintModal={openPrintModal}
      closePrintModal={closePrintModal}
      renderPrintModal={renderPrintModal}

      openPrintRecordsModal={openPrintRecordsModal}
      closePrintRecordsModal={closePrintRecordsModal}
      renderPrintRecordsModal={renderPrintRecordsModal}
    />
  );
};
