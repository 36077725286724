import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useUserState } from '@hooks';

const IndexPage = (): JSX.Element => {
  const { hasFetched, loggedIn, user } = useUserState();
  const navigate = useNavigate();

  useEffect(() => {
    if (hasFetched) {
      if (!loggedIn) {
        window.location.replace("/admin/login/");
      } else {
        if (user.has_roadtests_access) {
          navigate('/view/roads', { replace: true });
        } else if (user.is_superuser && user.has_kzp_access) {
          navigate('/view/kzp', { replace: true });
        } else if (user.has_orders_access) {
          navigate('/view/orders', { replace: true });
        } else if (user.has_mailrecords_access) {
          navigate('/view/mailrecords', { replace: false });
        }
      }
    }
  });

  return null;
};

export default IndexPage;
