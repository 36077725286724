import { FileList } from '@components';

import { request } from '@utils';

type RoadTestsFileListProps = {
  files: RoadTestsFile[];
  onChange: (files: RoadTestsFile[]) => void;
  actionURL?: string;
  getFormData?: (formData: FormData) => FormData;
  onUpload?: (files: RoadTestsFile[]) => void;
  onRemove?: (file: RoadTestsFile) => void;
  getAction?: (file: RoadTestsFile) => React.ReactNode;
  label?: string;
  readOnly?: boolean;
};

const RoadTestsFileList = (props: RoadTestsFileListProps) => {
  return (
    <FileList
      actionURL="/api/fileuploads"
      onRemove={(fileToDelete: RoadTestsFile) => {
        request('/api/fileuploads/' + fileToDelete.id, {
          method: 'delete',
        }).then(() => {
          props.onChange(props.files.filter((file: RoadTestsFile) => {
            return file.id !== fileToDelete.id;
          }));
        });
      }}
      onUpload={(files: RoadTestsFile[]) => {
        props.onChange([
          ...props.files,
          ...files,
        ]);
      }}
      {...props}
    />
  );
};

export default RoadTestsFileList;
