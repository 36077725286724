import { useState, useEffect } from 'react';
import { Resizable } from 'react-resizable';
import { Table } from 'antd';
import { useVT } from 'virtualizedtableforantd4';

const ResizableTitle = (props: any) => {
  const { onResize, width, ...restProps } = props;

  if (!width) {
    return <th {...restProps} />;
  }

  return (
    <Resizable
      width={width}
      height={0}
      handle={
        <span
          className="react-resizable-handle"
          onClick={e => {
            e.stopPropagation();
          }}
        />
      }
      onResize={onResize}
      draggableOpts={{ enableUserSelectHack: false }}
    >
      <th {...restProps} />
    </Resizable>
  );
};

const ResizableVirtualTable = (props: any) => {
  const [columns, setColumns] = useState(props.columns);
  const [virtualTableComponents] = useVT(() => ({
    scroll: props.scroll,
  }), []);

  useEffect(() => {
    setColumns(props.columns);
  }, [props.columns]);

  const handleResize = (index: number) => {
    return (_: any, { size }: any) => {
      const newColumns = [...columns];
      newColumns[index] = {
        ...newColumns[index],
        width: size.width,
      };
      setColumns(newColumns);
    };
  }

  const resizableColumns = columns.map((col: any, index: number) => ({
    ...col,
    onHeaderCell: (column: any) => ({
      width: column.width,
      onResize: handleResize(index),
    }),
  }));

  return (
    <Table
      {...props}
      columns={resizableColumns}
      components={{
        ...virtualTableComponents,
        header: {
          cell: ResizableTitle,
        },
      }}
    />
  );
};

export default ResizableVirtualTable;
