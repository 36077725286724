export const getKZPSortedRows = (rows) => {
  return rows.sort((a,b) => {
    if (parseInt(a.test.test_order, 10) > parseInt(b.test.test_order, 10)) {
      return 1;
    } else if (parseInt(a.test.test_order, 10) < parseInt(b.test.test_order, 10)) {
      return -1;
    } else {
      return 0;
    }
  })
};

export const getKZPAmountRestriction = (options, selectedUnit) => {
  const target = options.filter(o => o.selected_unit_specification === selectedUnit)[0];
  return (target && target.amount) || '';
};
