const _byIdFinder = (itemId) => (item) => String(item.id) === String(itemId);
const _byKZPIdFinder = (itemId) => (item) => String(item.kzp_id) === String(itemId);

export const updateById = (items, itemId, updatedItem) => {
  const foundIdx = items.findIndex(_byIdFinder(itemId));
  if (foundIdx === -1) {
    return items;
  }

  const updatedItems = [...items.slice(0, foundIdx), updatedItem, ...items.slice(foundIdx + 1)];
  return updatedItems;
}

export const updateByKZPId = (items, itemId, updatedItem) => {
  const foundIdx = items.findIndex(_byKZPIdFinder(itemId));
  if (foundIdx === -1) {
    return items;
  }

  const updatedItems = [...items.slice(0, foundIdx), updatedItem, ...items.slice(foundIdx + 1)];
  return updatedItems;
}

export const getterByProperty = (property: string) => {
  return (list: any[] = [], propertyValue: string|number) => {
    const index = list.map((_: any) => _[property]).indexOf(propertyValue);
    if (index === -1) {
      return null;
    } else {
      return list[index];
    }
  }
}

export const getById = getterByProperty('id');
export const getByValue = getterByProperty('value');

export const getByIds = (list: any[] = [], ids: (string|number)[]) => {
  return list.filter((_: any) => ids.indexOf(_.id) > -1);
};

const changeUsingProperty = (list: any[], data: any, property: string) => {
  const index = list.findIndex((_: any) => _[property] === data[property]);
  if (index > -1) {
    list[index] = {
      ...list[index],
      ...data,
    };
  }
  return list;
};

export const changeUsingId = (list: any[], data: any) => {
  return changeUsingProperty(list, data, 'id');
};

const removeUsingProperty = (list: any[], value: any, property: string) => {
  return list.filter((_: any) => _[property] !== value);
};

export const removeUsingId = (list: any[], id: string|number) => {
  return removeUsingProperty(list, id, 'id');
};
