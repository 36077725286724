import { createContext } from 'react';

const defaultContextValue: any = {};

type UserState = {
  hasFetched: boolean;
  loggedIn: boolean;
  user?: User | {};
};

type UserAction = {
  type: string;
  user?: User | null;
};

type UserContextType = {
  state: UserState;
  dispatch: React.Dispatch<UserAction>;
};

export const UserContext = createContext<UserContextType>(defaultContextValue);

export const initialUserState: UserState = {
  hasFetched: false,
  loggedIn: false,
  user: {},
};

export const userReducer = (state: UserState, action: UserAction): UserState => {
  switch (action.type) {
    case 'SET':
      return {
          ...state,
          hasFetched: true,
          loggedIn: !!action.user,
          user: action.user || {},
        };
    case 'UPDATE':
      return {
        ...state,
        user: {
          ...state.user,
          ...action.user,
        },
      };
    case 'LOGOUT':
      return {
        ...state,
        loggedIn: false,
        user: {},
      };
    default:
      return state;
  }
};

export const setUser = (user?: User) => ({
  type: 'SET',
  user,
});

export const updateUser = (user: User) => ({
  type: 'UPDATE',
  user,
});

export const logoutUser = () => ({
  type: 'LOGOUT',
});
