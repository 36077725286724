import { Button, Tooltip } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

type CloseButtonProps = {
  tooltip?: string;
  onClick: Function;
};

const CloseButton = ({ tooltip, onClick }: CloseButtonProps) => {
  return (
    <Tooltip title={tooltip}>
      <Button
        icon={<CloseOutlined />}
        onClick={() => onClick()}
        shape="circle"
      />
    </Tooltip>
  );
};

export default CloseButton;
