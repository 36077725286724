import React from 'react';

import { useUser } from '@hooks';

import { request } from '@utils';

class FetchMobileReleases extends React.Component<{ render: (Object) => React.Node }, *> {
  state = { data: null };

  componentDidMount() {
    this.onFetch();
  }

  onFetch = () => {
    return request('/api/application/mobileapp/info/')
      .then((response) => {
        const { data } = response;
        if (data) {
          this.setState({ data });
        }
      })
      .catch(this.onError);
  };

  onError = (e) => {
    console.error(e);
  };

  render() {
    const { data } = this.state;
    if (!data) {
      return null;
    }
    return this.props.render({ data, refetch: this.onFetch });
  }
}

// type LocalProps = {|
//   mobileReleases: {
//     latest_release: string,
//     latest_downloaded: boolean,
//     download_link: string,
//   },
//   refetchMobileReleases: Function,
// |};

// type LocalState = *;

class HelpPage extends React.Component {
  onMobileAppDownload = () => {
    // should be just fine(TM)
    setTimeout(this.props.refetchMobileReleases, 500);
  };

  render() {
    const { user, mobileReleases } = this.props;
    const { display_name, is_superuser } = user;

    return (
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        alignItems: 'center',
        backgroundColor: '#dbeeda',
      }}>
        <div style={{ margin: '14px 0' }} />
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          minWidth: '400px',
          padding: '1em',
          backgroundColor: '#fff',
          border: '1px solid #b1deae',
        }}>
          <h2>Manuál</h2>
          <p>Nevíte si rady?</p>
          <a download href="/static/assets/help/kvalitasilnic_cz_manual.pdf">
            Zobrazit manuál v PDF
          </a>

          <div style={{ margin: '14px 0' }} />
          <h2>Mobilní aplikace</h2>
          <div>
            Poslední verze je <strong>{mobileReleases.latest_release}</strong>.
          </div>
          <div>
            {mobileReleases.latest_downloaded ? (
              'Tuto verzi jste již stáhli.'
            ) : (
              <a download href={mobileReleases.download_link} onClick={this.onMobileAppDownload}>
                Stáhnout poslední verzi
              </a>
            )}
          </div>

          {/* <div style={{ margin: '14px 0' }} />
          <h2>Seznamy i číselníky</h2>
          <a download href="/static/assets/help/kvalita_silnic_-_seznam-zkousek-manual.pdf">
            Seznam zkoušek v PDF
          </a> */}
          {/* <br />
          <a download href="/static/assets/help/seznam-so.pdf">
            Seznam stavebních objektů v PDF
          </a>
          <br />
          <a download href="/static/assets/help/seznam-kc.pdf">
            Seznam konstrukčních částí v PDF
          </a> */}

          <div style={{ margin: '14px 0' }} />
          <h2>Opravný formulář</h2>
          <a download href="/static/assets/help/kvalita_silnic_-_opravny_formular.xlsx">
            Stáhnout ve formátu xlsx
          </a>

          <div style={{ margin: '14px 0' }} />
          {is_superuser && (
            <div>
              <h2>Admin</h2>
              <p>Máte přístup do administračního rozhraní.</p>
              <a href="/admin/">Zobrazit administraci</a>
              <div style={{ margin: '14px 0' }} />
            </div>
          )}
          <h2>Odhlášení</h2>
          <div>
            {display_name ? (
              <p>
                Jste přihlášeni jako <strong>{display_name}</strong>.
              </p>
            ) : null}

            <div>
              <a href="/admin/logout/">Odhlásit se ze systému</a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default () => {
  const user = useUser();

  return (
    <FetchMobileReleases
      render={(mobileReleases) => (
        <HelpPage
          user={user}
          mobileReleases={mobileReleases.data}
          refetchMobileReleases={mobileReleases.refetch}
        />
      )}
    />
  );
};
