import { Select } from 'antd';

import styles from './ConstructionSelect.module.scss';

import { getOptions, filterOption } from '@utils';

const ConstructionSelect = ({ value, options, onChange }: any) => {
  return (
    <div className={styles.container}>
      <span>Stavba</span>
      <Select
        className={styles.select}
        dropdownMatchSelectWidth={false}
        value={value}
        onChange={onChange}
        options={getOptions(options)}
        placeholder="Stavba"
        bordered={false}
        listHeight={500}
        showSearch
        filterOption={filterOption}
      />
    </div>
  )
}

export default ConstructionSelect;
