import React, { Component, Fragment } from 'react';
import { message, Modal, Select, Card, Timeline, Button } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

import { FileList, RoadTestsFileList } from '@components';

import { useModal } from '@hooks';

import { getOptions, dayMonthYearHourMinute } from '@utils';

import { commentMailRecord, handoverMailRecord, closeMailRecord } from './requests';

class MailRecordsTimeline extends Component {
  constructor(props) {
    super(props);
    this.state = {
      commentText: '',
      commentAttachments: [],
      tryingToHandover: false,
      handoverRecipient: null,
      tryingToClose: false,
    };
  }

  openAttachmentModal = (attachment) => {
    this.props.openAttachmentModal(attachment.name, {
      files: [{
        ...attachment,
        url: attachment.file,
      }],
      readOnly: true,
    });
  };

  clearComment = () => {
    this.setState({
      commentText: '',
      commentAttachments: [],
    });
  };

  onSubmitComment = () => {
    const { commentText, commentAttachments } = this.state;

    if (!commentText) return;

    const { constructionId, mailrecord } = this.props;
    const data = {
      text: commentText,
      comment_attachment_ids: commentAttachments.map(({ id }) => id),
    };

    commentMailRecord(constructionId, mailrecord.id, data)
      .then(({ data }) => {
        this.clearComment();
        this.props.onCommentMailRecord(data);
      })
      .catch((error) => this.clearComment());
  };

  clearHandover = () => {
    this.setState({
      tryingToHandover: false,
      handoverRecipient: null,
    });
  };

  chooseHandoverRecipient = (handoverRecipient) => {
    this.setState({ handoverRecipient });
  };

  onSubmitHandover = () => {
    const { handoverRecipient } = this.state;

    if (!handoverRecipient) {
      message.error('Vyberte prosím nového příjemce');
    } else {
      const { constructionId, mailrecord } = this.props;
      const data = { new_receiver: handoverRecipient };

      handoverMailRecord(constructionId, mailrecord.id, data)
        .then(({ data }) => {
          this.clearHandover();
          this.props.onHandoverMailRecord(data);
        })
        .catch((error) => this.clearHandover());
    }
  };

  clearClosure = () => {
    this.setState({
      tryingToClose: false,
    });
  };

  onSubmitClosure = () => {
    const { constructionId, mailrecord } = this.props;

    if (!mailrecord.final_document) {
      this.clearClosure();
      message.error('Před uzavřením záznamu je potřeba nahrát Finální dokument');
    } else if (!mailrecord.attachments.length) {
      this.clearClosure();
      message.error('Záznam musí mít alespoň 1 přílohu');
    } else {
      closeMailRecord(constructionId, mailrecord.id)
        .then(({ data }) => {
          this.clearClosure();
          this.props.onCloseMailRecord(data);
        })
        .catch((error) => this.clearClosure());
    }
  };

  render() {
    const { mailrecord, permittedUserActions, tableOptions } = this.props;
    const {
      commentText,
      tryingToHandover,
      handoverRecipient,
      tryingToClose,
    } = this.state;

    const { comments, handovers, closed } = mailrecord;
    // Oldest item has index 0
    const items = [
      ...comments.map((_) => ({ ..._, comment: true })),
      ...handovers.map((_) => ({ ..._, handover: true })),
    ].sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
    if (closed)
      items.push({
        closure: true,
        closed_by: mailrecord.closed_by,
        closure_date: mailrecord.closure_date,
      });
    // Also add item about record creation at the beginning using 'unshift'
    items.unshift({
      creation: true,
      created_by: mailrecord.created_by,
      created_at: mailrecord.created_at,
    });

    const can_comment = permittedUserActions.can_comment_mailrecord;
    const can_handover = permittedUserActions.can_handover_mailrecord;
    const can_close = permittedUserActions.can_close_mailrecord;

    return (
      <Fragment>
        <Modal
          title="Vyberte dalšího příjemce"
          open={tryingToHandover}
          cancelText="Zrušit"
          okText="Potvrdit"
          onCancel={() => this.clearHandover()}
          onOk={() => this.onSubmitHandover()}
          destroyOnClose
        >
          <Select
            placeholder="Příjemce"
            options={getOptions(tableOptions.recipients.filter(
              (recipient) => mailrecord.recipients.map(_ => _.id).indexOf(recipient.id) === -1
            ))}
            value={handoverRecipient}
            onChange={(value) => this.chooseHandoverRecipient(value)}
            style={{ width: '100%' }}
          />
        </Modal>

        <Modal
          title="Opravdu chcete záznam uzavřít?"
          open={tryingToClose}
          cancelText="Ne"
          okText="Ano"
          onCancel={() => this.clearClosure()}
          onOk={() => this.onSubmitClosure()}
          destroyOnClose
        >
          Po tomto kroku nebudete už moci záznam editovat
        </Modal>

        <div>
          {this.props.permittedUserActions.can_edit && (
            <p style={{ margin: '30px 0 15px 0' }}>
              <b>Historie záznamu {this.props.mailrecord.test_number}</b>
            </p>
          )}
          <Card size="small">
            <div style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}>
              <div
                style={{
                  display: 'inline-block',
                  paddingTop: closed ? 30 : 24,
                  paddingRight: 16,
                  paddingBottom: 12,
                  paddingLeft: 16,
                  width: closed ? '60%' : '40%',
                }}
              >
                <Timeline>
                  {items.map(
                    (
                      {
                        creation,
                        closure,
                        closed_by,
                        closure_date,
                        comment,
                        created_by,
                        created_at,
                        new_receiver,
                        text,
                        attachments,
                      },
                      index
                    ) => {
                      const last = index === items.length - 1;

                      const style = {};
                      if (last) style['padding'] = 0;
                      if (last && closure) style['height'] = 24;

                      return (
                        <Timeline.Item
                          key={index}
                          color={creation || closure ? '#ab6100' : comment ? 'blue' : 'green'}
                          style={style}
                        >
                          {creation ? (
                            <Fragment>
                              <p style={{ margin: 0 }}>
                                Uživatel <b>{created_by}</b> záznam založil{' '}
                                <span style={{ color: '#aaa' }}>
                                  ({dayMonthYearHourMinute(created_at)})
                                </span>
                              </p>
                            </Fragment>
                          ) : closure ? (
                            <Fragment>
                              <p style={{ margin: 0 }}>
                                Uživatel <b>{closed_by}</b> záznam uzavřel{' '}
                                <span style={{ color: '#aaa' }}>
                                  ({dayMonthYearHourMinute(closure_date)})
                                </span>
                              </p>
                            </Fragment>
                          ) : comment ? (
                            <Fragment>
                              <p style={{ margin: 0 }}>
                                Uživatel <b>{created_by}</b> přidal komentář
                                {attachments.length > 0 ? ' s přílohami:' : ':'}{' '}
                                <span style={{ color: '#aaa' }}>
                                  ({dayMonthYearHourMinute(created_at)})
                                </span>
                              </p>
                              <p style={{ color: '#666', margin: 0, maxWidth: 570 }}>{text}</p>
                              {attachments.length > 0 && (
                                <Fragment>
                                  <p style={{ fontWeight: 600, marginBottom: 4 }}>Přílohy:</p>
                                  {attachments.map((attachment) => (
                                    <div
                                      key={attachment.id}
                                      className="mailrecords__attachment"
                                      onClick={() => this.openAttachmentModal(attachment)}
                                    >
                                      {attachment.name}
                                    </div>
                                  ))}
                                </Fragment>
                              )}
                            </Fragment>
                          ) : (
                            <Fragment>
                              <p style={{ margin: 0 }}>
                                {/* Uživatel <b>{created_by}</b> předal záznam na uživatele{' '} */}
                                Uživatel <b>{created_by}</b> přidal příjemce <b>{new_receiver}</b>{' '}
                                <span style={{ color: '#aaa' }}>
                                  ({dayMonthYearHourMinute(created_at)})
                                </span>
                              </p>
                            </Fragment>
                          )}
                        </Timeline.Item>
                      );
                    }
                  )}
                </Timeline>
              </div>
              {
                mailrecord.closed && (
                  <div
                    style={{
                      display: 'inline-block',
                      padding: '12px 16px',
                      width: '40%',
                    }}
                  >
                    <p
                      key="0"
                      style={{
                        backgroundColor: 'transparent',
                        fontWeight: 600,
                        margin: '6px 0 16px auto',
                        maxWidth: 320,
                      }}
                    >
                      Příjemci
                    </p>
                    {mailrecord.recipients.map((recipient) => {
                      const hasCommented = !!mailrecord.comments.filter(
                        _ => _.created_by_id === recipient.id
                      ).length;

                      return (
                        <p
                          key={recipient.id}
                          style={{
                            backgroundColor: hasCommented ? '#e1ffe9' : '#ffe1e1',
                            borderRadius: 10,
                            margin: '6px 0 6px auto',
                            padding: 10,
                            maxWidth: 320,
                          }}
                        >
                          {recipient.name}
                        </p>
                      );
                    })}
                  </div>
                )
              }
              {!mailrecord.closed && (can_comment || can_handover || can_close) && (
                <div
                  style={{
                    boxSizing: 'border-box',
                    padding: '0 16px 16px 16px',
                    width: '60%',
                  }}
                >
                  {can_comment && (
                    <Fragment>
                      <div className="mailrecords__comment">
                        <p>Napsat komentář</p>
                        <textarea
                          placeholder="Text komentáře"
                          value={commentText}
                          onChange={(event) => this.setState({ commentText: event.target.value })}
                        />
                        <RoadTestsFileList
                          listType="picture"
                          noItemRender
                          uploadContent={<Button icon={<UploadOutlined />}>Přidat přílohu</Button>}
                          files={this.state.commentAttachments}
                          onChange={(files: RoadTestsFile[]) => {
                            this.setState({ commentAttachments: files });
                          }}
                        />
                      </div>
                      <button
                        className={`mailrecords__submit ${
                          !commentText ? 'mailrecords__submit--disabled' : ''
                        }`}
                        disabled={!commentText}
                        onClick={this.onSubmitComment}
                      >
                        Odeslat komentář
                      </button>
                    </Fragment>
                  )}
                  {can_handover && (
                    <button
                      className="mailrecords__handover"
                      onClick={() => this.setState({ tryingToHandover: true })}
                    >
                      {/* Předat na uživatele */}
                      Přidat příjemce
                    </button>
                  )}
                  {can_close && (
                    <button
                      className="mailrecords__close"
                      onClick={() => this.setState({ tryingToClose: true })}
                    >
                      Uzavřít záznam
                    </button>
                  )}
                </div>
              )}
            </div>
          </Card>
          {this.props.renderAttachmentModal()}
        </div>
      </Fragment>
    );
  }
}

export default (props) => {
  const [
    openAttachmentModal,
    closeAttachmentModal,
    renderAttachmentModal,
  ] = useModal(
    FileList,
    { width: 600 },
  );

  return <MailRecordsTimeline
    {...props}

    openAttachmentModal={openAttachmentModal}
    closeAttachmentModal={closeAttachmentModal}
    renderAttachmentModal={renderAttachmentModal}
  />;
}
