import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery, useQueryClient } from '@tanstack/react-query';

import { UserContext, setUser } from '@reducers';

import { request } from '@utils';

export const useUserContext = () => {
  const context = useContext(UserContext);
  return context;
};

export const useUserState = () => {
  const { state } = useContext(UserContext);
  return state;
};

export const useUserDispatch = () => {
  const { dispatch } = useContext(UserContext);
  return dispatch;
};

export const useUser = () => {
  return useUserState().user as User;
};

export const useFetchUserData = () => {
  const dispatch = useUserDispatch();

  useQuery({
    queryKey: ['USER_DATA'],
    queryFn: () => {
      return request('/api/application/user/info');
    },
    onSuccess: ({ data }) => {
      dispatch(setUser(data));
    },
  });
};

export const useRefetchUser = () => {
  const queryClient = useQueryClient();
  return () => {
    queryClient.invalidateQueries({ queryKey: ['USER_DATA'] });
  };
};

export const useRequireAuth = (hasAccess: Function = () => true) => {
  const { hasFetched, loggedIn, user } = useUserState();
  const navigate = useNavigate();

  useEffect(() => {
    if (hasFetched && (!loggedIn || !hasAccess(user))) {
      navigate("/");
    }
  }, [hasFetched, loggedIn, user, navigate]);

  return hasFetched && loggedIn && hasAccess(user);
};

export const useRequireNoAuth = () => {
  const { hasFetched, loggedIn } = useUserState();
  const navigate = useNavigate();

  useEffect(() => {
    if (hasFetched && loggedIn) {
      navigate("/");
    }
  }, [hasFetched, loggedIn, navigate]);

  return hasFetched && !loggedIn;
};

// export const useLogin = () => {
//   const message = useMessage();
//   const refetchUser = useRefetchUser();
//
//   return ({ email, password }: { email: string; password: string }) => {
//     return request(API.USER_LOGIN, {
//       method: 'POST',
//       data: {
//         email: email,
//         password: password,
//       },
//     })
//       .then(({ data }) => {
//         const { token } = data;
//         setCookie(AUTH_TOKEN, token);
//         refetchUser();
//       })
//       .catch(() => {
//         message.error('You must have entered wrong email or password');
//       });
//   };
// };

// export const useLogout = () => {
//   const message = useMessage();
//   const refetchUser = useRefetchUser();
//
//   return () => {
//     return request(API.USER_LOGOUT, {
//       method: 'POST',
//     })
//       .then(() => {
//         setCookie(AUTH_TOKEN, '');
//         refetchUser();
//       })
//       .catch(() => {
//         message.error('An error has occurred while logging out');
//       });
//   };
// }
